import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import ClientHeader from "../Dashboard/ClientHeader";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import MapTile from "./MapTile";
import { getDate, getDateOnly } from "../Components/Utils";
import Select from "react-select";
import * as api from "../../api/global.api";
import {
  SimplePie,
  SimpleDonut,
  MonochromePie,
  PatternedDonut,
  MultipleRadialbars,
} from "./PieChart";
import { toast } from "react-toastify";
import SurveyForm from "./SurveyForm";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ProjectDashboard = () => {
  const loc = useLocation().state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [surveylist, setSurveylist] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [statusForms, setStatusForms] = useState([]);
  const [surveyForm, setSurveyForm] = useState(null);
  const [isSurveyForm, setIsSurveyForm] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState({
    label: "All Surveys",
    value: 0,
  });

  useEffect(() => {
    if (loc?.totalSurvey > 0) {
      getSurveys();
      getChartsData(selectedSurvey);
    }
  }, []);

  useEffect(() => {
    if (surveylist?.length > 1) {
      api.get(`fetch/survey/${loc?.id}`).then((res) => {
        if (res?.status === "Success") {
          let data = res?.response?.map((e) => {
            let surveyname = surveylist?.find(
              (ele) => ele?.value === e?.surveyId
            );
            return { ...e, surveyName: surveyname?.label };
          });
          setStatusForms(data);
        }
      });
    }
  }, [surveylist]);

  const getSurveys = () => {
    setLoading(true);
    api
      .get(`survey/${loc.id}`)
      .then((res) => {
        if (res?.status === "Success") {
          let resp = res?.response?.map((ele) => {
            return { label: ele?.surveyName, value: ele?.id };
          });
          setSurveylist([{ label: "All Surveys", value: 0 }, ...resp]);
          setLoading(false);
        } else {
          toast.error(res?.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handleSelect = (val) => {
    setSelectedSurvey(val);
    getChartsData(val);
  };

  const getChartsData = (val) => {
    let url =
      val?.value !== 0
        ? `dashboard?projectId=${loc?.id}&surveyId=${val.value}`
        : `dashboard?projectId=${loc?.id}`;
    setLoading(true);
    api
      .get(url)
      .then((res) => {
        if (res?.status === "Success") {
          let rec = res?.response?.filter(
            (ele) =>
              ele?.subProjects?.length > 0 || ele?.subSurveyForms?.length > 0
          );
          let record = rec?.map((ele) => {
            if (ele.hasOwnProperty("subProjects")) {
              return {
                header: ele?.description,
                weightage: ele?.weightage,
                completion: ele?.completion ? ele?.completion : 0,
                data: ele?.subProjects?.map((e) => e.description),
                value: ele?.subProjects?.map((e) =>
                  e.completion ? e.completion : 0
                ),
              };
            }
            return {
              header: ele?.description,
              weightage: ele?.weightage,
              completion: ele?.completion,
              data: ele?.subSurveyForms?.map((e) => e.description),
              value: ele?.subSurveyForms?.map((e) =>
                e.completion ? e.completion : 0
              ),
            };
          });
          setChartData(record);
          setLoading(false);
        } else {
          toast.error(res?.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handleViewForm = (item) => {
    setSurveyForm({
      id: item?.surveyId,
      projectId: loc?.id,
      surveyName: item?.surveyName,
    });
    setIsSurveyForm(true);
  };

  const captureCharts = async () => {
    const input = document.getElementById("charts-container");
    const pdf = new jsPDF("p", "pt", "a4");
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 500;
    const pageHeight = 800;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 10;
    pdf.setFontSize(14);
    pdf.text(
      `Project Name: ${loc?.projectName}                   Survey Name: ${selectedSurvey?.label}`,
      10,
      20
    );

    pdf.addImage(imgData, "PNG", 10, 50, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    let fileName = `${loc.projectName}_${selectedSurvey?.label}`;
    pdf.save(fileName);
  };
  const downloadExcelReport = () => {
    setLoading(true);
    let url =
      selectedSurvey?.value === 0
        ? `download/projectReport?projectId=${loc?.id}&clientId=${loc?.clientId}&clientName=${loc?.client.clientName}&module=Survey`
        : `download/projectReport?projectId=${loc?.id}&surveyId=${selectedSurvey?.value}&clientId=${loc?.clientId}&clientName=${loc?.client.clientName}&module=Survey&surveyName=${selectedSurvey?.label}`;
    api
      .post(url, {})
      .then((res) => {
        if (res?.status === "Success") {
          let fileName = `${loc.projectName}_${selectedSurvey?.label}`;
          const link = document.createElement("a");
          link.href = res?.response;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setLoading(false);
        } else {
          toast.error(res.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <ClientHeader />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <div
        style={{
          background: "#F3F6F9",
          height: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="d-flex align-items-center p-3">
          <i
            className="bx bx-chevron-left fs-2 pointer"
            style={{ color: "#069B80" }}
            onClick={() => navigate("/client-dashboard")}
          ></i>
          <span
            className="fs-18 fw-500 pointer"
            style={{ color: "#069B80" }}
            onClick={() => navigate("/client-dashboard")}
          >
            Projects
          </span>
          <i
            className="bx bx-chevron-left fs-2"
            style={{ color: "#8DA1A3" }}
          ></i>
          <span className="fs-18 fw-500" style={{ color: "#8DA1A3" }}>
            {loc.projectName}
          </span>
        </div>

        <Row className="px-3">
          <Col xl={9}>
            <Row>
              <MapTile coordinate={[78.621857, 17.209671]} />
              <div className="position-absolute">
                <div className="d-flex align-items-center mb-2">
                  <i class="bx bxs-location-plus fs-2 me-2"></i>
                  <div>
                    <div className="fw-500 fs-20">{loc?.location}</div>
                  </div>
                </div>
              </div>
            </Row>

            <Card className="brd-none px-4 py-2">
              <Row className="py-2">
                <Col md={4} lg={3}>
                  <div className="text-muted">Project Name</div>
                  <div className="fs-20 fw-500 text-muted">
                    {loc?.projectName}
                  </div>
                </Col>
                <Col md={4} lg={3}>
                  <div className="text-muted">No. Of Survey</div>
                  <div className="fs-20 fw-500 text-muted">
                    {loc?.totalSurvey}
                  </div>
                </Col>
                <Col md={4} lg={3}>
                  <div className="text-muted">Date</div>
                  <div className="fs-20 fw-500 text-muted">
                    {getDateOnly(new Date(loc.latestSurvey))}
                  </div>
                </Col>
              </Row>
            </Card>
            <Row className="mb-3">
              <Col md={4} lg={3}>
                <Select
                  options={surveylist}
                  value={selectedSurvey}
                  onChange={(val) => handleSelect(val)}
                />
              </Col>
              <Col md={8} lg={9} className="d-flex justify-content-end">
                <UncontrolledDropdown>
                  <DropdownToggle className="downloadbtn me-3">
                    Download
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={downloadExcelReport}>
                      Excel
                    </DropdownItem>
                    <DropdownItem onClick={captureCharts}>Pdf</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <Button
                  className="bg-primary"
                  onClick={() => {
                    navigate("/surveys", { state: loc });
                    sessionStorage?.setItem(
                      "surveyProject",
                      JSON.stringify(loc)
                    );
                    sessionStorage.removeItem("surveyToShow");
                  }}
                >
                  Map View
                </Button>
              </Col>
            </Row>

            {chartData?.length > 0 ? (
              <Row id="charts-container">
                {chartData?.map((ele, idx) => {
                  return (
                    <Col lg={6} xl={4} className="mb-3">
                      <Card className="h-100">
                        <CardHeader style={{ background: "#fff" }}>
                          <h4
                            className="card-title mb-0 p-2"
                            style={{ color: "#085088" }}
                          >
                            {ele?.header}
                          </h4>
                        </CardHeader>

                        <CardBody>
                          <div className="d-flex justify-content-between p-2">
                            <div className="fw-500">
                              Weightage {ele?.weightage}%
                            </div>
                            <div className="fw-500">
                              Completion {ele?.completion}%
                            </div>
                          </div>
                          {idx % 5 === 0 && (
                            <SimplePie
                              dataLabels={ele.data}
                              dataValues={ele.value}
                              dataColors='["#50C3E6", "#6691E7", "#13C56B", "#E8BC52", "#837575"]'
                            />
                          )}
                          {idx % 5 === 1 && (
                            <MonochromePie
                              dataLabels={ele.data}
                              dataValues={ele.value}
                            />
                          )}
                          {idx % 5 === 2 && (
                            <MultipleRadialbars
                              dataLabels={ele.data}
                              dataValues={ele.value}
                              dataColors='["#50C3E6", "#6691E7", "#13C56B", "#E8BC52", "#837575"]'
                            />
                          )}
                          {idx % 5 === 3 && (
                            <SimpleDonut
                              dataValues={ele.value}
                              dataColors='["#50C3E6", "#6691E7", "#13C56B", "#E8BC52", "#837575"]'
                            />
                          )}
                          {idx % 5 === 4 && (
                            <PatternedDonut
                              dataLabels={ele.data}
                              dataValues={ele.value}
                              dataColors='["#50C3E6", "#6691E7", "#13C56B", "#E8BC52", "#837575"]'
                            />
                          )}
                          <div className="p-4">
                            {ele?.data?.map((item, indx) => (
                              <div
                                key={indx}
                                className="d-flex align-items-center fs-14"
                              >
                                <i
                                  class="bx bxs-circle me-2"
                                  style={{
                                    color: [
                                      "#50C3E6",
                                      "#6691E7",
                                      "#13C56B",
                                      "#E8BC52",
                                      "#837575",
                                    ][indx % 5],
                                  }}
                                ></i>
                                {item} -{" "}
                                <span className="fw-500">
                                  {ele?.value[indx]}%
                                </span>
                              </div>
                            ))}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <div className="text-center">No Charts Found</div>
            )}
          </Col>
          <Col xl={3}>
            <Card className="brd-none">
              <CardHeader
                style={{ background: "#fff" }}
                className="px-4 fw-500"
              >
                History Project Status Form
              </CardHeader>
              <CardBody style={{ minHeight: "99vh" }}>
                {statusForms?.length > 0 ? (
                  <div>
                    {statusForms?.map((item, idx) => (
                      <Card className="m-3 px-4 py-2" key={idx}>
                        <div className="d-flex align-items-center mb-2">
                          <i class="bx bxs-location-plus fs-2 me-2"></i>
                          <div className="fw-500" style={{ color: "#069B80" }}>
                            {item?.surveyName}
                          </div>
                        </div>
                        <div className="d-flex align-items-center ms-4 ps-3 mb-2">
                          <i class="bx bx-user fs-4 text-muted me-2"></i>
                          <span>{item?.createdBy}</span>
                        </div>
                        <div className="d-flex align-items-center ms-4 ps-3 mb-3">
                          <i class="bx bx-calendar fs-4 text-muted me-2"></i>
                          <span>{getDate(item?.updatedAt)}</span>
                        </div>
                        <div
                          className="text-center pointer fw-500"
                          style={{
                            color: "#085088",
                            textDecoration: "underline solid #085088",
                          }}
                          onClick={() => handleViewForm(item)}
                        >
                          View Form
                        </div>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <div className="text-center">No Records Found</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {isSurveyForm && (
        <SurveyForm
          isSurveyForm={isSurveyForm}
          setIsSurveyForm={setIsSurveyForm}
          survey={surveyForm}
          view={true}
          project={{ projectName: loc?.projectName }}
        />
      )}
    </React.Fragment>
  );
};

export default ProjectDashboard;
