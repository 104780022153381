import React from "react";

const ImageComponent = (props) => {
  const { src, ...rest } = props;
  let url;
  if (
    src?.includes("http://") ||
    src?.includes("data:") ||
    src?.includes("https://")
  ) {
    url = src;
  } else {
    url = process.env.REACT_APP_API_URL + "view/" + src;
  }

  return <img src={url} alt={props?.alt} {...rest} />;
};

export default ImageComponent;
