import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Input, Label } from "reactstrap";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import Loader from "../../components/Common/Loader";

const AddSiteForm = (props) => {
  const user = useSelector((state) => state.user);
  const [loc, setLoc] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);

  const gotogoogle = () => {
    window.open(
      `https://www.google.com/maps/@${props?.coord?.lat},${props?.coord?.long},15z?entry=ttu`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleClose = () => {
    props?.setIsSiteForm(true);
    props?.onClose(false);
  };

  
  const handleSave = () => {
    let obj = {
      locationName: loc,
      description: desc,
      latitude: props?.coord?.lat?.toString(),
      longitude: props?.coord?.long?.toString(),
      createdBy: user?.email,
      projectId: props?.survey?.projectId,
      surveyId: props?.survey?.id,
    };

    setLoading(true);

    let url;
    if (!!props?.updateSite) {
      obj["id"] = props?.updateSite?.id;
    }
    !!props?.updateSite
      ? (url = "siteupdates/update")
      : (url = "siteupdates/add");
    api
      .post(url, obj)
      .then((res) => {
        if (res?.status === "Success") {
          toast.success(
            !!props?.updateSite
              ? "Site updated successfully"
              : "Site update saved successfully"
          );
          setLoading(false);
          handleClose();
        } else {
          toast.error(res.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const disabeCheck = () => {
    if (loc?.trim() === "" || desc?.trim() === "" || !props?.coord?.lat) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!!props?.updateSite) {
      setLoc(props?.updateSite?.locationName);
      setDesc(props?.updateSite?.description);
    }
  }, []);

  return (
    <div className="add-site">
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <div className="add-site-header">
        <div className="fs-16 fw-500">Add Site Updates</div>
        <i className="bx bx-x fs-2" onClick={handleClose}></i>
      </div>
      <div className="add-site-body">
        {props?.coord?.lat !== "" && (
          <div className="location mb-4" onClick={gotogoogle}>
            {(props?.coord?.lat).toFixed(4)}
            {"\u00B0"} N {(props?.coord?.long).toFixed(4)} {"\u00B0"} E
          </div>
        )}

        <div className="mb-3">
          <Label className="text-muted">Location Name</Label>
          <Input
            type="text"
            value={loc}
            onChange={(e) => setLoc(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <Label className="text-muted">Description</Label>
          <textarea
            className="form-control"
            rows="5"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end p-2 mb-3">
        <Button
          className="me-3 w-sm"
          color="success"
          outline
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="success"
          className="me-3 w-sm"
          onClick={handleSave}
          disabled={disabeCheck()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddSiteForm;
