import React, { useState } from "react";
import Draggable from "react-draggable";
import Lightbox from "react-image-lightbox";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

const CompareMedia = (props) => {
  const [isLightbox, setIsLightbox] = useState(false);
  const [id, setId] = useState(0);
  const [lightboxData, setLightboxData] = useState(props?.compareData?.urls);
  console.log(lightboxData);
  return (
    <div className="">
      {isLightbox && (
        <Lightbox
          enableZoom
          onCloseRequest={() => setIsLightbox(false)}
          mainSrc={lightboxData[id]}
          nextSrc={lightboxData[(id + 1) % 2]}
          prevSrc={lightboxData[(id + (2 - 1)) % 2]}
          onMovePrevRequest={() => setId((id + 2 - 1) % 2)}
          onMoveNextRequest={() => setId((id + 1) % 2)}
        />
      )}
      <Draggable>
        <Modal isOpen={props.isCompare} size="xl">
          <ModalHeader className="bg-light">
            Compare Media{" "}
            <i
              className="bx bx-x fs-2 pointer"
              onClick={() => props?.setIsCompare(false)}
            ></i>
          </ModalHeader>
          <ModalBody>
            {props?.compareData?.fileType === "video" && (
              <Row className="mb-4 mx-0">
                <Col
                  md={12}
                  lg={6}
                  style={{ minHeight: "300px", nimWidth: "550px" }}
                >
                  <div>{props?.compareData?.fileNames[0]}</div>
                  <video width="100%" height="100%" controls>
                    <source
                      src={props?.compareData?.urls[0]}
                      type="video/mp4"
                    />
                  </video>
                </Col>
                <Col
                  md={12}
                  lg={6}
                  style={{ minHeight: "300px", nimWidth: "550px" }}
                >
                  <div>{props?.compareData?.fileNames[1]}</div>
                  <video width="100%" height="100%" controls>
                    <source
                      src={props?.compareData?.urls[1]}
                      type="video/mp4"
                    />
                  </video>
                </Col>
              </Row>
            )}

            {props?.compareData?.fileType === "image" && (
              <Row>
                <Col
                  md={12}
                  lg={6}
                  style={{ minHeight: "300px", nimWidth: "550px" }}
                >
                  <img
                    src={props?.compareData?.urls[0]}
                    className="object-fit-cover"
                    onClick={() => {
                      setIsLightbox(true);
                      setId(0);
                    }}
                  />
                </Col>
                <Col
                  md={12}
                  lg={6}
                  style={{ minHeight: "300px", nimWidth: "550px" }}
                >
                  <img
                    src={props?.compareData?.urls[1]}
                    className="object-fit-cover"
                    onClick={() => {
                      setIsLightbox(true);
                      setId(1);
                    }}
                  />
                </Col>
              </Row>
            )}
          </ModalBody>
        </Modal>
      </Draggable>
    </div>
  );
};

export default CompareMedia;
