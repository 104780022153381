import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Common/BreadCrumb";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { MultiSelect } from "primereact/multiselect";
import Select from "react-select";
import FeatureDataTable from "../../tables/FeatureDataTable";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as api from "../../../api/global.api";
import Loader from "../../CommonComponent/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { alphabets, Capitalize } from "../../commons/functions";
import AddFeatureModel from "./AddFeatureModal";
import "../../../assets/scss/module.css";
import ImageComponent from "../../Common/ImageComponent";

function AddModule() {
  const modulesOption = [
    { label: "Survey", value: "Survey" },
    { label: "Inspection", value: "Inspection" },
  ];

  const surveyModuleOptions = [
    { label: "Land Survey", value: "Land Survey" },
    { label: "Mining Survey", value: "Mining Survey" },
    { label: "Water Board", value: "Water Board" },
  ];

  const inspectionModuleOptions = [
    { label: "Oil and Gas", value: "Oil and Gas" },
    { label: "Transmission Lines", value: "Transmission Lines" },
  ];

  const navigate = useNavigate();
  const date: any = new Date();
  const [isModal, setIsModal] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [mode, setMode] = useState<any>("Add Module");
  const [module, setModule] = useState<any>();
  //dropdown options
  const [subModuleOptions, setSubModuleOptions] = useState<any>([]);
  const [featuresOptions, setFeaturesOptions] = useState<any>([]);
  const [subModule, setSelectedSubModule] = useState<any>();
  const [features, setSelectedFeatures] = useState<any>();
  //newFeature
  const [newFeature, setNewFeature] = useState<any>([]);
  const [editFeature, setEditFeature] = useState<any>(null);
  const [editId, setEditId] = useState<any>();

  const formik = useFormik({
    initialValues: {
      moduleName: "",
      existingSubModule: "",
      subModuleName: "",
      existingFeatures: "",
      coverImage: "",
    },
    validationSchema: Yup.object({
      moduleName: Yup.string()
        .trim("Please enter valid module name")
        .strict(true)
        .matches(alphabets, "Please enter valid module name")
        .required("required"),
      subModuleName: Yup.string()
        .required("required")
        .trim("Please enter valid sub module name")
        .strict(true)
        .matches(alphabets, "Please enter valid sub module name"),
    }),
    onSubmit: (values: any) => {
      // let data = subModule.filter(
      //   (e: any) =>
      //     e.label?.toLowerCase() === values?.subModuleName?.toLowerCase()
      // );
      // if (data.length !== 0) {
      //   values.subModuleName = "";
      // }
      let finalValues = {
        ...values,
        features: { create: tableData },
      };

      mode === "Add Module"
        ? addModuleApi(finalValues)
        : updateModule({ ...finalValues, id: editId });
    },
  });

  const handleModal = (val: any) => {
    setIsModal(val);
  };

  const handleNewFeature = (val: any) => {
    let existingData = [...tableData];
    let idx = existingData?.filter(
      (e: any) =>
        e.featureName.toLowerCase() === val?.featureName?.toLowerCase()
    );
    if (idx?.length === 0) {
      setNewFeature([
        ...newFeature,
        { ...val, createdAt: date, isSelected: true },
      ]);
      setTableData([
        ...tableData,
        { ...val, createdAt: date, isSelected: true },
      ]);
    } else {
      toast.error("Feature name already exist");
    }
  };

  const handleUpdateFeature = (val: any) => {
    let existingData = [...tableData];
    let idxq = existingData?.filter(
      (e: any) =>
        e.featureName.toLowerCase() === val?.featureName?.toLowerCase()
    );
    if (idxq?.length === 0) {
      let arr = newFeature?.map((e: any) => e.featureName);
      let idx = arr.indexOf(editFeature?.featureName);
      let data = [...newFeature];
      data[idx].featureName = val.featureName;
      setNewFeature(data);
      let data2 = [...tableData];
      let arr2 = existingData?.map((e: any) => e.featureName);
      let idx2 = arr2.indexOf(editFeature?.featureName);
      data2[idx2].featureName = val.featureName;
      setTableData(data2);
    } else {
      toast.error("Feature name already exist");
    }
  };

  function handlesubmodule(val: any) {
    setSelectedSubModule(val);
    let data = val?.map((e: any) => e.label).join(",");
    formik.setFieldValue("existingSubModule", data);
    let url = val?.map(
      (e: any) => e.label.toUpperCase().replaceAll(" ", "_") + "_FEATURE"
    );
    url.length > 0 ? getFeatures(url.join(",")) : setFeaturesOptions([]);
  }

  function handlefeatures(val: any) {
    setSelectedFeatures(val.value);
    formik.setFieldValue("existingFeatures", val.value.join(","));
    const newData = val.value?.map((e: any) => {
      return { featureName: e, createdAt: date };
    });
    setTableData([...newData, ...newFeature]);
  }

  const handleDeleteClick = (val: any) => {
    let data = tableData?.filter(
      (e: any) => e.featureName !== val?.featureName
    );
    setTableData(data);
    let newData = features?.filter((e: any) => e.label !== val?.featureName);
    setSelectedFeatures(newData);
    toast.success("Feature deleted successfully");
  };

  const handleEditClick = (val: any) => {
    setEditFeature(val);
  };
  function handleAcceptedFiles(files: any) {
    let fs = files[0]?.path.split(".")[1]?.toLowerCase();
    if (fs === "jpeg" || fs === "png" || fs === "jpg") {
      toast.info("Image uploading");
      files.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        })
      );

      const formData = new FormData();
      formData.append("file", files[0]);

      api.post("localfileUpload", formData).then((res: any) => {
        if (res?.url) {
          formik.setFieldValue("coverImage", res?.url);
        }
        setLoading(false);
      });
    } else {
      toast.error("Please upload .jpeg or .png or .jpg format file only");
    }
  }

  // const getSubModule = (val: any) => {
  //   setLoading(true);
  //   api.get(`getEnums?enumType=${val}`).then((res) => {
  //     let data = res?.appEnum?.map((ele: any) => {
  //       return { label: ele.enumName, value: ele.enumValue };
  //     });
  //     setSubModuleOptions(data);
  //     setLoading(false);
  //   });
  // };

  const getFeatures = (val: any) => {
    setLoading(true);
    api.get(`getEnums?enumType=${val}`).then((res) => {
      let data = res.appEnum.map((ele: any) => {
        return { label: ele.enumValue, value: ele.enumValue };
      });

      setFeaturesOptions(data);
      setLoading(false);
    });
  };
  const updateModule = (val: any) => {
    setLoading(true);
    api
      .put("updateModule", val)
      .then((res) => {
        if (res?.status === "Success") {
          toast.success("Module updated successfully");
          formik.resetForm();
          setSelectedSubModule(null);
          setSelectedFeatures(null);
          setTableData([]);
          navigate("/modules");
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const addModuleApi = (val: any) => {
    setLoading(true);
    api
      .post("addModule", val)
      .then((res: any) => {
        if (res?.status === "Failed") {
          setLoading(false);
          toast.error(res?.errormessage);
        } else {
          toast.success("Module created successfully");
          formik.resetForm();
          setSelectedSubModule(null);
          setSelectedFeatures(null);
          setTableData([]);
          navigate("/modules");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const handleCancel = () => {
    navigate(-1);
    setSelectedSubModule(null);
    setSelectedFeatures(null);
  };

  const handleModule = (val: any) => {
    formik.setFieldValue("moduleName", val.label);
    setModule(val);
    setSelectedSubModule(null);
    if (val.label === "Survey") {
      setSubModuleOptions(surveyModuleOptions);
    } else {
      setSubModuleOptions(inspectionModuleOptions);
    }
  };

  useEffect(() => {
    let sessionEditModule: any = sessionStorage.getItem("editModule");
    // getSubModule("subModule");
    let data: any = sessionEditModule ? JSON.parse(sessionEditModule) : null;

    if (data !== null) {
      formik.setFieldValue("moduleName", data?.moduleName);
      formik.setFieldValue("subModuleName", data?.subModuleName);
      formik.setFieldValue("coverImage", data?.coverImage);
      formik.setFieldValue("existingSubModule", data?.existingSubModule);
      formik.setFieldValue("existingFeatures", data?.existingFeatures);

      setModule({ label: data?.moduleName, value: data?.moduleName });

      data?.existingSubModule
        ? setSelectedSubModule(
            data?.existingSubModule.split(",").map((e: any) => {
              return { label: e, value: e };
            })
          )
        : setSelectedSubModule(null);

      data?.existingFeatures
        ? setSelectedFeatures(data?.existingFeatures.split(","))
        : setSelectedFeatures(null);

      let val = data?.existingSubModule.split(",").map((e: any) => {
        return e.replaceAll(" ", "_").toUpperCase() + "_FEATURE";
      });
      getFeatures(val.join(","));
      setTableData(
        data?.features?.map((e: any) => {
          return { featureName: e.featureName, createdAt: e.createdAt };
        })
      );
      if (data?.moduleName === "Survey") {
        setSubModuleOptions(surveyModuleOptions);
      } else {
        setSubModuleOptions(inspectionModuleOptions);
      }
      setMode("Edit Module");
      setEditId(data?.id);
    }
  }, []);

  return (
    <div className="mt-3">
      <Loader loader={loading} />
      <ToastContainer />

      {!loading && (
        <Container fluid>
          <BreadCrumb
            pageTitle={"Module Management"}
            title={mode}
            pageLink={"/modules"}
          />
          <Form
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit();
              return;
            }}
          >
            <Card className="add-module">
              <CardBody>
                <Row>
                  <Col lg={4} md={6}>
                    <div className="mb-3">
                      <Label htmlFor="moduleName" className="form-label fs-16">
                        Module Name
                      </Label>
                      <Select
                        className="fs-14"
                        value={module}
                        isMulti={false}
                        onChange={(val) => {
                          handleModule(val);
                        }}
                        options={modulesOption}
                        closeMenuOnSelect={true}
                      />
                      {/* <Input
                        type="text"
                        className="form-control fs-14"
                        id="moduleName"
                        name="moduleName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={Capitalize(formik.values.moduleName) || ""}
                        invalid={
                          formik.touched.moduleName && formik.errors.moduleName
                            ? true
                            : false
                        }
                      />
                      {formik.touched.moduleName && formik.errors.moduleName ? (
                        <FormFeedback type="invalid">
                          {formik.errors.moduleName}
                        </FormFeedback>
                      ) : null} */}
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="mb-3">
                      <Label htmlFor="subModule" className="form-label fs-16">
                        Existing Sub-Module
                      </Label>
                      <Select
                        className="fs-14"
                        value={subModule}
                        isMulti={true}
                        onChange={(val) => {
                          handlesubmodule(val);
                        }}
                        options={subModuleOptions}
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="mb-3">
                      <Label
                        htmlFor="subModuleName"
                        className="form-label fs-16"
                      >
                        Sub Module Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control fs-14"
                        id="subModuleName"
                        name="subModuleName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.subModuleName}
                        invalid={
                          formik.touched.subModuleName &&
                          formik.errors.subModuleName
                            ? true
                            : false
                        }
                      />
                      {formik.touched.subModuleName &&
                      formik.errors.subModuleName ? (
                        <FormFeedback type="invalid">
                          {formik.errors.subModuleName}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={4} md={6}>
                    <div className="mb-3<Button></Button>">
                      <Label htmlFor="features" className="form-label fs-16">
                        Existing Features
                      </Label>
                      <MultiSelect
                        value={features}
                        onChange={(e) => {
                          handlefeatures(e);
                        }}
                        options={featuresOptions}
                        placeholder="Select Features"
                        maxSelectedLabels={1}
                        className="multiselect fs-14"
                      />
                    </div>
                  </Col>

                  <Col lg={4} md={6}>
                    <div className="mb-3">
                      <Label htmlFor="profilePic" className="form-label fs-16">
                        Upload Image
                      </Label>
                      {formik.values.coverImage === "" && (
                        <Dropzone
                          onDrop={(files) => {
                            handleAcceptedFiles(files);
                          }}
                          maxFiles={1}
                          onDropRejected={(files) => {
                            toast.error(files[0]?.errors[0]?.message);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone dz-clickable form-control p-0">
                              <div className="needsclick" {...getRootProps()}>
                                <input
                                  {...getInputProps()}
                                  accept={"image/*"}
                                />
                                <div className="mx-4 d-flex align-items-center">
                                  <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                                  <span className="text-muted fs-14">
                                    Upload Image
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}
                      {formik.values.coverImage !== "" && (
                        <Card>
                          <div className="d-flex align-items-center justify-content-between  m-2">
                            <div>
                              <ImageComponent
                                data-dz-thumbnail=""
                                height="70"
                                className="avatar-sm rounded bg-light"
                                alt={"image"}
                                src={formik.values.coverImage}
                              />
                              <span className="p-3">Uploaded Image</span>
                            </div>

                            <i
                              className="ri-delete-bin-6-line fs-4  pointer red p-2"
                              onClick={() =>
                                formik.setFieldValue("coverImage", "")
                              }
                            ></i>
                          </div>
                        </Card>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="d-flex justify-content-end mb-4 ">
                    <Button
                      color="success"
                      onClick={() => {
                        handleModal(true);
                        setEditFeature(null);
                      }}
                    >
                      <span className="me-2">+</span>
                      Add Features
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    {tableData.length > 0 && (
                      <FeatureDataTable
                        data={tableData}
                        handleDeleteClick={handleDeleteClick}
                        handleModal={handleModal}
                        newFeature={newFeature}
                        handleEditClick={handleEditClick}
                        features={features}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="d-flex justify-content-end transparent">
                <Button
                  className="wd-10 me-3"
                  onClick={() => handleCancel()}
                  color="success"
                  outline
                >
                  Cancel
                </Button>
                {mode === "Add Module" && (
                  <Button
                    type="submit"
                    className="wd-10"
                    color="success"
                    disabled={
                      !(formik.touched && formik.isValid) ||
                      tableData?.length === 0
                    }
                  >
                    Save
                  </Button>
                )}
                {mode === "Edit Module" && (
                  <Button type="submit" className="wd-10" color="success">
                    Update
                  </Button>
                )}
              </CardFooter>
            </Card>
          </Form>
          {isModal && (
            <AddFeatureModel
              isModal={isModal}
              handleModal={handleModal}
              handleNewFeature={handleNewFeature}
              editFeature={editFeature}
              handleUpdateFeature={handleUpdateFeature}
            />
          )}
        </Container>
      )}
    </div>
  );
}

export default AddModule;
