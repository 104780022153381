import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Loader from "../../components/Common/Loader";
import * as api from "../../api/global.api";
import { ModalBody } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import classnames from "classnames";

import {
  convertDateToISO,
  convertToTitleCase,
  getDate,
  getDateOnly,
} from "../Components/Utils";
import { idText } from "typescript";
import { getValueType } from "ol/style/expressions";

const SurveyForm = (props) => {
  const { isSurveyForm, setIsSurveyForm, survey, project } = props;
  const user = useSelector((state) => state.user);
  const username = user.firstName + " " + user.lastName;
  const datetime = convertDateToISO(new Date());
  const [loading, setLoading] = useState(false);
  const [justifyPillsTab, setjustifyPillsTab] = useState("1");

  const [formOrder, setFormOrder] = useState([]);
  const [currentTab, setCurrentTab] = useState("");
  const [fields, setFields] = useState();

  const [error, setError] = useState({});

  const options = [
    { label: "Not Yet Started", value: "Not Yet Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
  ];

  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      setjustifyPillsTab(tab);
    }
  };

  useEffect(() => {
    getSurveyFormOrder();
  }, []);

  useEffect(() => {
    if (currentTab !== "") {
      getFormFields(currentTab);
    }
  }, [currentTab]);

  const getSurveyFormOrder = () => {
    setLoading(true);
    api
      .get(`fetch/projectOrder/${survey?.projectId}`)
      .then((res) => {
        if (res?.status === "Success") {
          setFormOrder(res?.response);
          setCurrentTab(res?.response[0].trim());
          setLoading(false);
        } else {
          toast.error(res?.errorMessage);
        }
      })
      .catch((err) => setLoading(false));
  };

  const getFormFields = (formName) => {
    const pid = survey?.projectId;
    const sid = survey?.id;
    setLoading(true);
    api
      .get(`fetch/surveyForm/${pid}/${sid}/${formName}`)
      .then((res) => {
        if (res) {
          setFields(res?.response);
          setLoading(false);
        } else {
          toast.error(res?.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  const getStatusValue = (ele) => {
    return options?.find((item) => item.value === ele);
  };

  const handleStatusChange = (val, idx, desc) => {
    let data = [...fields?.subProjects];
    data[idx]["status"] = val?.value;
    data[idx]["completed"] = val?.value === "Completed" ? 1 : 0;
    setFields({ ...fields, subProjects: data });
    let err = { ...error };
    if (err[desc]) {
      err[desc]["completed"] = null;
    }
    setError(err);
  };

  const handleInputChange = (e, idx, desc) => {
    const { name, value } = e?.target;
    let data = [...fields?.subProjects];
    data[idx][name] = value;
    let err = { ...error };
    if (err[desc]) {
      err[desc][name] = null;
    }
    setError(err);
    setFields({ ...fields, subProjects: data });
  };

  const handleSubmit = () => {
    let errors = {};
    fields?.subProjects?.forEach((ele) => {
      const {
        description,
        isMandatory,
        totalQty,
        completed,
        startDate,
        endDate,
        status,
        isTotalQtyNull,
      } = ele;
      let day1 = new Date(startDate);
      let day2 = new Date(endDate);
      if (isMandatory?.toLowerCase() === "yes") {
        if (endDate !== null && day1 > day2) {
          errors[description] = {
            ...errors[description],
            endDate: "End date cannot smaller than start date",
          };
        }

        if (
          +totalQty === 1 &&
          status !== "Not Yet Started" &&
          startDate === null
        ) {
          errors[description] = {
            ...errors[description],
            startDate: "Start date is required",
          };
        }

        if (+totalQty !== 1 && startDate === null) {
          errors[description] = {
            ...errors[description],
            startDate: "Start date is required",
          };
        }

        if (totalQty === null || +totalQty < 0) {
          errors[description] = {
            ...errors[description],
            totalQty: "Invalid total quantity",
          };
        }

        if (completed === null || +completed < 0 || +completed > +totalQty) {
          errors[description] = {
            ...errors[description],
            completed: "Invalid completed quantity",
          };
        }
      } else {
        if (endDate !== null && day1 > day2) {
          errors[description] = {
            ...errors[description],
            endDate: "End date cannot smaller than start date",
          };
        }

        if (totalQty && totalQty < 0) {
          errors[description] = {
            ...errors[description],
            totalQty: "Invalid total quantity",
          };
        }

        if (completed && (completed < 0 || completed > totalQty)) {
          errors[description] = {
            ...errors[description],
            completed: "Invalid completed quantity",
          };
        }
      }
    });

    setError(errors);

    if (Object.keys(errors)?.length === 0) {
      submitForm();
    } else {
      toast.warning("Please fill all the mandatory fields");
    }
  };

  const submitForm = () => {
    let obj = {
      ...fields,
      createdBy: user?.email,
      surveyId: survey?.id,
      subProjects: fields?.subProjects?.map((e) => ({
        ...e,
        createdBy: user?.email,
        surveyId: survey?.id,
        completed: e?.completed !== null ? +e?.completed : null,
        totalQty: e?.totalQty !== null ? +e?.totalQty : null,
      })),
    };
    setLoading(true);
    api
      .post("add/surveyForm", obj)
      .then((res) => {
        if (res?.status === "Success") {
          api.get(`calculate/surveyForm/${project?.id}`);
          toast.success("Form submitted successfully");
          setFields(null);
          let id = JSON.stringify((+justifyPillsTab % formOrder?.length) + 1);
          setjustifyPillsTab(id);
          setCurrentTab(formOrder[+justifyPillsTab % formOrder?.length]);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.errorMessage);
        }
      })
      .catch((err) => setLoading(false));
  };

  return (
    <div className="surveyform">
      <ToastContainer />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <Modal isOpen={isSurveyForm} fullscreen="lg" size="xl">
        <ModalHeader style={{ background: "none" }} className="border-bottom">
          <span className="w-100 py-3">
            {project?.projectName} - {survey?.surveyName}
          </span>
          <i
            className="bx bx-x fs-2 pointer"
            onClick={() => setIsSurveyForm(false)}
          ></i>
        </ModalHeader>
        <ModalBody className="modal-body-scroll">
          {formOrder?.length > 0 && (
            <>
              <Nav pills className="nav mb-3">
                {formOrder?.map((ele, id) => {
                  let idx = JSON.stringify(id + 1);
                  return (
                    <React.Fragment key={id}>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                            width: "fit-content",
                            border: "1px solid #000",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                          className={classnames({
                            active: justifyPillsTab === idx,
                          })}
                          onClick={() => {
                            justifyPillsToggle(idx);
                            setCurrentTab(ele);
                            setFields(null);
                          }}
                        >
                          <span className="d-flex align-items-center">
                            <span>{ele}</span>
                          </span>
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  );
                })}
              </Nav>
              <TabContent activeTab={justifyPillsTab} className="text-muted">
                {formOrder?.map((ele, id) => {
                  let idx = JSON.stringify(id + 1);
                  return (
                    <TabPane tabId={idx} id="pill-justified-home">
                      {fields?.createdBy && (
                        <div className="text-end">
                          <div className="fw-500 fs-13">
                            {fields?.createdBy}
                          </div>
                          <div className="fw-500 fs-13">
                            {getDate(fields?.updatedAt)}
                          </div>
                        </div>
                      )}

                      <div
                        style={{
                          fontWeight: "500",
                          color: "#085088",
                          fontSize: "16px",
                          marginBottom: "20px",
                        }}
                      >
                        {ele}
                      </div>
                      <Row>
                        {fields?.subProjects?.map((ele, idn) => {
                          const {
                            description,
                            isMandatory,
                            isTotalQtyNull,
                            totalQty,
                            completed,
                            status,
                            startDate,
                            endDate,
                          } = ele;
                          return (
                            <Col lg={6} key={idn}>
                              <div className="fw-500 mb-3">{description}</div>
                              <Row className="mb-3">
                                {(+totalQty !== 1 || isTotalQtyNull) && (
                                  <Col lg={6}>
                                    <div>
                                      <Label>
                                        Total Quantity{" "}
                                        {isMandatory?.toLowerCase() ===
                                          "yes" && (
                                          <span className="required">*</span>
                                        )}
                                      </Label>
                                      <Input
                                        type="number"
                                        name="totalQty"
                                        value={totalQty}
                                        disabled={
                                          !isTotalQtyNull || props?.view
                                        }
                                        onChange={(e) =>
                                          handleInputChange(e, idn, description)
                                        }
                                      />
                                      {error &&
                                        error[description] &&
                                        error[description]["totalQty"] && (
                                          <div className="required fs-12">
                                            {error[description]["totalQty"]}
                                          </div>
                                        )}
                                    </div>
                                  </Col>
                                )}
                                <Col lg={6}>
                                  {+totalQty !== 1 ? (
                                    <div>
                                      <Label>
                                        Completed Quantity{" "}
                                        {isMandatory?.toLowerCase() ===
                                          "yes" && (
                                          <span className="required">*</span>
                                        )}
                                      </Label>
                                      <Input
                                        type="number"
                                        name="completed"
                                        value={completed}
                                        disabled={props?.view}
                                        onChange={(e) =>
                                          handleInputChange(e, idn, description)
                                        }
                                      />
                                      {error &&
                                        error[description] &&
                                        error[description]["completed"] && (
                                          <div className="required fs-12">
                                            {error[description]["completed"]}
                                          </div>
                                        )}
                                    </div>
                                  ) : (
                                    <div>
                                      <Label>
                                        Status{" "}
                                        {isMandatory?.toLowerCase() ===
                                          "yes" && (
                                          <span className="required">*</span>
                                        )}
                                      </Label>
                                      <Select
                                        name="status"
                                        options={options}
                                        value={status && getStatusValue(status)}
                                        disabled={props?.view}
                                        onChange={(val) =>
                                          handleStatusChange(
                                            val,
                                            idn,
                                            description
                                          )
                                        }
                                      />
                                      {error &&
                                        error[description] &&
                                        error[description]["completed"] && (
                                          <div className="required fs-12">
                                            {error[description]["completed"]}
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mb-5">
                                {(+totalQty !== 1 ||
                                  (status && status !== "Not Yet Started")) && (
                                  <>
                                    <Col lg={6}>
                                      <Label>
                                        Start Date{" "}
                                        {isMandatory?.toLowerCase() ===
                                          "yes" && (
                                          <span className="required">*</span>
                                        )}
                                      </Label>
                                      <Input
                                        type="date"
                                        name="startDate"
                                        value={startDate}
                                        disabled={props?.view}
                                        onChange={(e) =>
                                          handleInputChange(e, idn, description)
                                        }
                                      />
                                      {error &&
                                        error[description] &&
                                        error[description]["startDate"] && (
                                          <div className="required fs-12">
                                            {error[description]["startDate"]}
                                          </div>
                                        )}
                                    </Col>
                                    <Col lg={6}>
                                      <Label>End Date</Label>
                                      <Input
                                        type="date"
                                        name="endDate"
                                        value={endDate}
                                        disabled={props?.view}
                                        onChange={(e) =>
                                          handleInputChange(e, idn, description)
                                        }
                                      />
                                      {error &&
                                        error[description] &&
                                        error[description]["endDate"] && (
                                          <div className="required fs-12">
                                            {error[description]["endDate"]}
                                          </div>
                                        )}
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    </TabPane>
                  );
                })}
              </TabContent>
            </>
          )}

          {formOrder?.length === 0 && (
            <div>Project status form is not uploaded</div>
          )}
        </ModalBody>

        <ModalFooter className="pt-3">
          <Button
            color="success"
            outline
            onClick={() => setIsSurveyForm(false)}
          >
            Close
          </Button>
          {!props?.view && (
            <Button color="success" onClick={() => handleSubmit()}>
              {fields?.completion !== null ? "Update" : "Submit"}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SurveyForm;
