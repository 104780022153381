import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Divider } from "primereact/divider";

import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Capitalize, alphabets } from "../../commons/functions";
import { toast } from "react-toastify";
import * as api from "../../../api/global.api";
import {
  clientLevelOptions,
  projectLevelOptions,
} from "../../../Pages/Components/Constants";

const AddRole = ({
  isModal,
  handleModalClose,
  selectedSubModule,
  editData,
  editRoleData,
  handletableData,
}: any) => {
  const SingleOptions: any = [
    { value: "Client Level", label: "Client Level" },
    { value: "Project Level", label: "Project Level" },
  ];

  const [roleOption, setRoleOption] = useState<any>([]);
  const [selectedRoleType, setSelectedRoleType] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [selectedAddToList, setSelectedAddToList] = useState<any>(false);
  const [roleName, setRoleName] = useState<any>("");
  const [data, setData] = useState<any>(selectedSubModule);
  const [checkArray, setCheckArray] = useState<any>([]);
  const [err, setErr] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  // const [dataRole, setDataRole] = useState<any>([]);

  // const getRoles = (data: any) => {
  //   setLoading(true);
  //   const storageEditUserData: any = sessionStorage.getItem("editUserData");
  //   let id: any;
  //   if (storageEditUserData) {
  //     let newID = JSON.parse(storageEditUserData);
  //     id = newID.id;
  //   } else {
  //     id = 0;
  //   }

  //   api
  //     .get(`roles/distinct?userId=${id}&roleType=${data}`)
  //     .then((res) => {
  //       if (res?.status === "Success") {
  //         let data = res?.response?.map((e: any) => {
  //           return { label: e.roleName, value: e.roleName };
  //         });
  //         setRoleOption([...data]);
  //         setLoading(false);
  //       } else {
  //         setRoleOption([]);
  //         toast.error(res.errormessage);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    if (editData) {
      // getRoles(editData?.roleType);
      let arr = roleOption.map((ele: any) => ele.label);
      if (arr.indexOf(editData?.roleName) === -1) {
        setSelectedRole({
          label: editData?.roleName,
          value: editData?.roleName,
        });
      } else {
        setRoleName(editData?.roleName);
        setSelectedRole({
          label: "Others",
          value: "Others",
        });
      }

      setSelectedAddToList(editData?.addToList);

      setSelectedRoleType({
        label: editData?.roleType,
        value: editData?.roleType,
      });

      let data: any = [];
      editData?.privilliges?.create
        ? editData?.privilliges?.create?.map((ele: any) => {
            if (ele.create) {
              data.push(`${ele.subModule}-create`);
            }
            if (ele.edit) {
              data.push(`${ele.subModule}-edit`);
            }
            if (ele.delete) {
              data.push(`${ele.subModule}-delete`);
            }
            if (ele.view) {
              data.push(`${ele.subModule}-view`);
            }
            if (ele.upload) {
              data.push(`${ele.subModule}-upload`);
            }
            if (ele.reUpload) {
              data.push(`${ele.subModule}-reUpload`);
            }
            if (ele.download) {
              data.push(`${ele.subModule}-download`);
            }
          })
        : editData?.privilliges?.map((ele: any) => {
            if (ele.create) {
              data.push(`${ele.subModule}-create`);
            }
            if (ele.edit) {
              data.push(`${ele.subModule}-edit`);
            }
            if (ele.delete) {
              data.push(`${ele.subModule}-delete`);
            }
            if (ele.view) {
              data.push(`${ele.subModule}-view`);
            }
            if (ele.upload) {
              data.push(`${ele.subModule}-upload`);
            }
            if (ele.reUpload) {
              data.push(`${ele.subModule}-reUpload`);
            }
            if (ele.download) {
              data.push(`${ele.subModule}-download`);
            }
          });
      setCheckArray(data);
    }
  }, []);

  function handleRoleType(val: any) {
    setSelectedRoleType(val);
    setSelectedRole(null);
    setRoleName("");
    val.value === "Project Level"
      ? setRoleOption(projectLevelOptions)
      : setRoleOption(clientLevelOptions);
  }

  function handleRole(val: any) {
    setSelectedRole(val);
    setRoleName("");
  }

  function handleChecklist() {
    setSelectedAddToList(!selectedAddToList);
  }

  const handleRoleAdd = () => {
    let arr: any = [];
    let create: any = [];
    checkArray.map((ele: any) => {
      let id = ele?.split("-");
      let idx = arr?.indexOf(id[0]);
      if (idx === -1) {
        arr.push(id[0]);
        create.push({
          subModule: id[0],
          create: id[1] === "create" ? true : false,
          edit: id[1] === "edit" ? true : false,
          view: id[1] === "view" ? true : false,
          delete: id[1] === "delete" ? true : false,
          upload: id[1] === "upload" ? true : false,
          reUpload: id[1] === "reUpload" ? true : false,
          download: id[1] === "download" ? true : false,
        });
      } else {
        switch (id[1]) {
          case "create":
            return (create[idx].create = true);
          case "edit":
            return (create[idx].edit = true);
          case "view":
            return (create[idx].view = true);
          case "delete":
            return (create[idx].delete = true);
          case "upload":
            return (create[idx].upload = true);
          case "reUpload":
            return (create[idx].reUpload = true);
          case "download":
            return (create[idx].download = true);
        }
      }
    });

    let value: any = {
      roleName: roleName === "" ? selectedRole?.label : roleName,
      roleType: selectedRoleType?.label,
      roleStatus:
        selectedRole.label !== "Others" ? "Default" : "User Configured",
      roleCode:
        roleName === ""
          ? selectedRole?.label.toUpperCase()
          : roleName.toUpperCase(),
      addToList:
        selectedRole.label === "Others" && selectedAddToList ? true : false,
      isSelected: true,
      privilliges: {
        create: create?.map((e: any) => ({
          ...e,
          moduleType: selectedSubModule?.find(
            (ele: any) => ele.label === e.subModule
          )?.type,
        })),
      },
    };

    handletableData(value);
    clearForm();
  };

  const handleRoleUpdate = () => {
    let arr: any = [];
    let create: any = [];
    checkArray.map((ele: any) => {
      let id = ele?.split("-");
      let idx = arr?.indexOf(id[0]);
      if (idx === -1) {
        arr.push(id[0]);
        create.push({
          subModule: id[0],
          create: id[1] === "create" ? true : false,
          edit: id[1] === "edit" ? true : false,
          view: id[1] === "view" ? true : false,
          delete: id[1] === "delete" ? true : false,
          upload: id[1] === "upload" ? true : false,
          reUpload: id[1] === "reUpload" ? true : false,
          download: id[1] === "download" ? true : false,
        });
      } else {
        switch (id[1]) {
          case "create":
            return (create[idx].create = true);
          case "edit":
            return (create[idx].edit = true);
          case "view":
            return (create[idx].view = true);
          case "delete":
            return (create[idx].delete = true);
          case "upload":
            return (create[idx].upload = true);
          case "reUpload":
            return (create[idx].reUpload = true);
          case "download":
            return (create[idx].download = true);
        }
      }
    });
    let value = {
      id: editData?.id,
      roleName: roleName === "" ? selectedRole?.label : roleName,
      roleType: selectedRoleType.label,
      roleStatus:
        selectedRole.label !== "Others" ? "Default" : "User Configured",
      addToList:
        selectedRole.label === "Others" && selectedAddToList ? true : false,
      roleCode:
        roleName === ""
          ? selectedRole?.label.toUpperCase()
          : roleName.toUpperCase(),
      privilliges: {
        create: create?.map((e: any) => ({
          ...e,
          moduleType: selectedSubModule?.find(
            (ele: any) => ele.label === e.subModule
          )?.type,
        })),
      },
      isSelected: true,
    };

    handletableData(value);
    clearForm();
  };

  function clearForm() {
    handleModalClose(false);
    setSelectedRoleType(null);
    setRoleName("");
    setSelectedAddToList(false);
    setSelectedRole(null);
    editRoleData(null);
  }

  const handleCheckArr = (e: any) => {
    let idx = checkArray.indexOf(e.target.value);
    if (idx === -1) {
      setCheckArray([...checkArray, e.target.value]);
    } else {
      let data = [...checkArray];
      data?.splice(idx, 1);
      setCheckArray(data);
    }
  };

  const disableAddRole = () => {
    if (selectedRoleType == null) {
      return true;
    } else if (selectedRole == null) {
      return true;
    } else if (selectedRole.label === "Others" && roleName == "") {
      return true;
    } else if (checkArray.length === 0) {
      return true;
    }
    return false;
  };

  const validateRoleName = () => {
    let res = alphabets.test(roleName);
    setErr(!res);
  };

  return (
    <Modal isOpen={isModal} backdrop="static" className="modal-dialog-top">
      <ModalHeader className="modal-header p-3 ">
        <span className="text-light">
          {editData ? "Edit Role" : "Add Role"}
        </span>
        <i className="bx bx-x fs-2 pointer text-light"></i>
      </ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col lg={4} md={6}>
            <div>
              <Label
                htmlFor="basiInput"
                className="form-label"
                style={{ color: "#9599AD" }}
              >
                Role Type
              </Label>
              <Select
                value={selectedRoleType}
                onChange={(val: any) => {
                  handleRoleType(val);
                }}
                options={SingleOptions}
                className="fs-14"
              />
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div>
              <Label
                htmlFor="basiInput"
                className="form-label"
                style={{ color: "#9599AD" }}
              >
                Role
              </Label>
              <Select
                value={selectedRole}
                onChange={(val: any) => {
                  handleRole(val);
                }}
                options={roleOption}
                className="fs-14"
              />
            </div>
          </Col>
          {selectedRole?.value === "Others" && (
            <Col lg={4} md={6}>
              <div>
                <Label
                  htmlFor="basiInput"
                  className="form-label"
                  style={{ color: "#9599AD" }}
                >
                  Role Name
                </Label>
                <input
                  type="text"
                  className="form-control fs-14"
                  id="basiInput"
                  value={Capitalize(roleName)}
                  onChange={(e) => {
                    setRoleName(e.target.value);
                    validateRoleName();
                  }}
                  onBlur={validateRoleName}
                />
                {err && (
                  <span className="error">Please enter valid role name</span>
                )}
              </div>
            </Col>
          )}
        </Row>
        {selectedRole?.value === "Others" && (
          <Row>
            <Col>
              <div className="form-check mb-2">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id="formCheck1"
                  checked={selectedAddToList}
                  onChange={() => handleChecklist()}
                />
                <Label className="form-check-label fs-15" htmlFor="formCheck1">
                  Add to List
                </Label>
              </div>
            </Col>
          </Row>
        )}
        <Divider align="left">Privileges</Divider>
        <Row>
          {data?.map((ele: any, key: any) => (
            <Col key={key} className="mb-3">
              <Row>
                <Col lg={12} className="mb-3">
                  <div className="fs-16">{ele?.label}</div>
                </Col>
                <Col lg={12}>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={`${ele.label}-view`}
                      checked={checkArray?.includes(`${ele.label}-view`)}
                      onChange={(e: any) => {
                        handleCheckArr(e);
                      }}
                    ></Input>
                    <Label className="text-muted fs-15">View</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={`${ele.label}-create`}
                      checked={checkArray?.includes(`${ele.label}-create`)}
                      onChange={(e: any) => {
                        handleCheckArr(e);
                      }}
                    ></Input>
                    <Label className="text-muted fs-15">Create</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={`${ele.label}-edit`}
                      checked={checkArray?.includes(`${ele.label}-edit`)}
                      onChange={(e) => {
                        handleCheckArr(e);
                      }}
                    ></Input>
                    <Label className="text-muted fs-15">Edit</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={`${ele.label}-delete`}
                      checked={checkArray?.includes(`${ele.label}-delete`)}
                      onChange={(e) => {
                        handleCheckArr(e);
                      }}
                    ></Input>
                    <Label className="text-muted fs-15">Delete</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={`${ele.label}-upload`}
                      checked={checkArray?.includes(`${ele.label}-upload`)}
                      onChange={(e) => {
                        handleCheckArr(e);
                      }}
                    ></Input>
                    <Label className="text-muted fs-15">Upload</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={`${ele.label}-reUpload`}
                      checked={checkArray?.includes(`${ele.label}-reUpload`)}
                      onChange={(e) => {
                        handleCheckArr(e);
                      }}
                    ></Input>
                    <Label className="text-muted fs-15">ReUpload</Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-check mb-2">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={`${ele.label}-download`}
                      checked={checkArray?.includes(`${ele.label}-download`)}
                      onChange={(e) => {
                        handleCheckArr(e);
                      }}
                    ></Input>
                    <Label className="text-muted fs-15">Download</Label>
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="success"
          className="wd-10"
          onClick={() => clearForm()}
          outline
        >
          Cancel
        </Button>
        {editData === null && (
          <Button
            className="wd-10"
            onClick={() => handleRoleAdd()}
            disabled={disableAddRole()}
            color="primary"
          >
            Save
          </Button>
        )}
        {editData && (
          <Button
            className="wd-10"
            onClick={() => handleRoleUpdate()}
            color="primary"
            outline
          >
            Update
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default AddRole;
