import { PanZoom } from "@wokwi/react-easy-panzoom";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Card,
  Row,
  Col,
} from "reactstrap";
import circle from "../../assets/svg-new/circle.svg";
import oval from "../../assets/svg-new/oval.svg";
import square from "../../assets/svg-new/square.svg";
import arrow from "../../assets/svg-new/arrow.svg";
import nextImg from "../../assets/svg-new/next-img.svg";
import prevImg from "../../assets/svg-new/prev-img.svg";
import html2canvas from "html2canvas";
import TxObservation from "./TxObservation";
import * as api from "../../api/rest.api";
import Loader from "../../components/Common/Loader";
import ImageComponent from "../../components/Common/ImageComponent";
import { useLocation } from "react-router-dom";

const MarkImageModal = (props) => {
  const location = useLocation().state;
  console.log(location);
  const { isModal, records, image, setIsModal, getComponents } = props;
  const [curr, setCurr] = useState(null);
  const [currData, setCurrData] = useState(null);
  const [idx, setidx] = useState(0);
  const [actualHeight, setActualHeight] = useState(0);
  const [actualWidth, setActualWidth] = useState(0);
  const [draw, setDraw] = useState(false);
  const [shape, setShape] = useState("pan");
  const [isDraw, setIsDraw] = useState(false);
  const [start, setStart] = useState({});
  const [mouse, setMouse] = useState({});
  const [convImg, setConvImg] = useState(null);
  const [isObs, setIsObs] = useState(false);
  const [drawUrl, setDrawUrl] = useState("");
  const [data, setData] = useState("");
  const [isOffcanvas, setOffcanvas] = useState(false);
  const [offcanvasRecord, setOffcanvasRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("Add");

  const pan = useRef();
  const canvasRef = useRef();

  useEffect(() => {
    setCurrData(image);
    getPreviouslyMarkedImages(image);
    if (records?.length !== 0) {
      let id = records?.findIndex((e) => e.fileName === image.fileName);
      setidx(id);
      sessionStorage.setItem("currentImageIndex", id);
    }
  }, []);

  console.log(currData);

  const getPreviouslyMarkedImages = async (val) => {
    let res = await api.addData("gettowercomponentdetail", {
      defectFile: val.defectFile,
    });
    setOffcanvasRecord(res?.data?.rows);
  };

  const createImage = (url) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.webkitImageSmoothingEnable = false;
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      setActualHeight(height);
      setActualWidth(width);
      setConvImg(img);
      ctx.drawImage(img, 0, 0, width, height);
    };
  };

  useEffect(() => {
    if (curr) {
      createImage(curr);
    }
  }, [curr]);

  useEffect(() => {
    if (currData && records?.length !== 0) {
      let img;
      if (currData?.defectFile?.includes("http")) {
        img = currData.defectFile;
      } else {
        img = process.env.REACT_APP_API_URL + "view/" + currData?.defectFile;
      }

      setCurr(img);
      setMode("Add");
    } else if (currData) {
      let img;
      if (currData?.modifiedFile?.includes("http")) {
        img = currData.modifiedFile;
      } else {
        img = process.env.REACT_APP_API_URL + "view/" + currData?.modifiedFile;
      }

      setCurr(img);
      setIsObs(true);
      setDrawUrl(img);
      setData(currData);
      setMode("Edit");
    }
  }, [currData]);

  const zoomIn = () => {
    pan.current.zoomIn();
  };

  const zoomOut = () => {
    pan.current.zoomOut();
  };

  const changeDraw = (val) => {
    setLoading(true);
    if (val) {
      let path = curr?.split("view/").pop();
      api.addData("pathbase64", { filePath: path }).then((res) => {
        if (res) {
          setCurr(res?.data);
          setLoading(false);
        }
      });
    }
    setDraw(val);
  };

  const handleMouseDown = (e) => {
    if (shape !== "pan") {
      setIsDraw(true);
      setStart({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    }
  };

  const handleMouseUp = async () => {
    setIsDraw(false);
    setShape("pan");
    shape === "square" && drawRectangle();
    shape === "arrow" && drawArrow(start.x, start.y);
    setStart({});
    setMouse({});
    if (start.x !== mouse.x && start.y !== mouse.y) {
      setIsObs(true);
    }
    let ele = document.getElementById("draw-data");
    let imgDraw = await html2canvas(ele);
    setDrawUrl(imgDraw.toDataURL());
  };

  const handleMouseMove = (e) => {
    if (!isDraw || shape === "pan") return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(convImg, 0, 0, canvas.width, canvas.height);
    setMouse({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    const radius =
      Math.sqrt((mouse.x - start.x) ** 2 + (mouse.y - start.y) ** 2) / 2;
    const arcx = (mouse.x - start.x) / 2 + start.x;
    const arcy = (mouse.y - start.y) / 2 + start.y;
    const radiusX = Math.abs(mouse.x - start.x);
    const radiusY = Math.abs(mouse.y - start.y);
    ctx.beginPath();
    shape === "circle" && ctx.arc(arcx, arcy, radius, 0, 2 * Math.PI);
    shape === "oval" &&
      ctx.ellipse(start.x, start.y, radiusX, radiusY, 0, 0, 2 * Math.PI);
    shape === "square" &&
      ctx.rect(start.x, start.y, mouse?.x - start?.x, mouse?.y - start?.y);
    ctx.strokeStyle = "red";
    ctx.lineWidth = 20;
    ctx.stroke();
  };

  const drawRectangle = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.rect(start.x, start.y, mouse.x - start.x, mouse.y - start.y);
    ctx.strokeStyle = "red";
    ctx.lineWidth = 20;
    ctx.stroke();
  };

  const drawArrow = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(convImg, 0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - 120, y + 150);
    ctx.lineTo(x - 120, y + 60);
    ctx.lineTo(x - 300, y + 60);
    ctx.lineTo(x - 300, y - 60);
    ctx.lineTo(x - 120, y - 60);
    ctx.lineTo(x - 120, y - 150);
    ctx.closePath();
    ctx.strokeStyle = "red";
    ctx.lineWidth = 20;
    ctx.stroke();
  };

  const updateImage = (val) => {
    if (val === "next") {
      setCurrData(records[(idx + 1) % records?.length]);
      setidx((idx + 1) % records?.length);
      getPreviouslyMarkedImages(records[(idx + 1) % records?.length]);
      sessionStorage.setItem("currentImageIndex", (idx + 1) % records?.length);
    } else if (val === "prev") {
      setCurrData(records[(idx - 1 + records?.length) % records?.length]);
      setidx((idx - 1 + records?.length) % records?.length);
      getPreviouslyMarkedImages(
        records[(idx - 1 + records?.length) % records?.length]
      );
      sessionStorage.setItem(
        "currentImageIndex",
        (idx - 1 + records?.length) % records?.length
      );
    } else {
      setCurrData(records[idx]);
    }
  };

  return (
    <>
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <Modal
        isOpen={isModal}
        toggle={() => {
          setIsModal(false);
          getComponents();
        }}
        scrollable={false}
        backdrop={false}
        fullscreen={true}
      >
        <ModalHeader
          toggle={() => {
            setIsModal(false);
            getComponents();
          }}
          className="bg-light align-items-center"
        >
          {currData?.fileName}
        </ModalHeader>
        <ModalBody>
          <div className="img-workspace">
            <PanZoom
              ref={pan}
              boundaryRatioVertical={1}
              boundaryRatioHorizontal={1}
              minZoom={0.1}
              max={4}
              zoomSpeed={1}
              disabled={shape !== "pan"}
            >
              <canvas
                id="draw-data"
                ref={canvasRef}
                height={actualHeight}
                width={actualWidth}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              ></canvas>
            </PanZoom>

            <div className="img-zoom">
              <i className="bx bx-plus fs-2 mb-3 pointer" onClick={zoomIn} />
              <i className="bx bx-minus fs-2 pointer" onClick={zoomOut} />
            </div>

            <div className="canvas-draw" onClick={() => changeDraw(!draw)}>
              {draw ? "Stop Marking" : "Start Marking"}
            </div>

            {offcanvasRecord?.length > 0 && (
              <div className="prev-marked">
                <Button color="success" onClick={() => setOffcanvas(true)}>
                  Previously Marked
                </Button>
              </div>
            )}

            {draw && (
              <div className="canvas-tool">
                <img
                  src={circle}
                  alt="circle"
                  className="mb-3 pointer"
                  onClick={() => setShape("circle")}
                />
                <img
                  src={oval}
                  alt="oval"
                  className="mb-3 pointer"
                  onClick={() => setShape("oval")}
                />
                <img
                  src={square}
                  alt="square"
                  className="mb-3 pointer"
                  onClick={() => setShape("square")}
                />
                <img
                  src={arrow}
                  alt="arrow"
                  className="mb-3 pointer"
                  onClick={() => setShape("arrow")}
                />
              </div>
            )}
          </div>
          {!draw && records?.length > 0 && (
            <>
              {idx !== 11 && (
                <div className="canvas-next">
                  <img
                    src={nextImg}
                    className="pointer"
                    onClick={() => updateImage("next")}
                  />
                </div>
              )}
              {idx !== 0 && (
                <div className="canvas-prev">
                  <img
                    src={prevImg}
                    className="pointer"
                    onClick={() => updateImage("prev")}
                  />
                </div>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
      {isObs && (
        <TxObservation
          isOpen={isObs}
          url={drawUrl}
          close={() => {
            setIsObs(false);
            createImage(curr);
            mode === "Edit" && setIsModal(false);
          }}
          setDraw={setDraw}
          image={currData}
          data={data}
          setIsModal={setIsModal}
          getComponents={getComponents}
          mode={mode}
        />
      )}

      {isOffcanvas && (
        <Offcanvas
          isOpen={isOffcanvas}
          toggle={() => {
            setOffcanvas(false);
          }}
          id="offcanvasExample"
        >
          <OffcanvasHeader id="offcanvasExampleLabel" className="border-bottom">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="fs-16 fw-500 text-dark">
                Previously Marked Observation
              </div>
              <i
                className="bx bx-x fs-2 pointer text-dark"
                onClick={() => {
                  setOffcanvas(false);
                }}
              ></i>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody>
            {offcanvasRecord?.map((e) => {
              return (
                <Card>
                  <div className="w-100 border-bottom">
                    <ImageComponent src={e?.modifiedFile} className="obj-fit" />
                  </div>
                  <Row className="mx-1">
                    <Col>
                      <div className="fs-13 color-grayl">Comment:</div>{" "}
                      <div className="fs-15">{e.comments}</div>
                    </Col>
                    <Col>
                      <div className="fs-13 color-grayl">Recommendation :</div>{" "}
                      <div className="fs-15">{e.recommendation}</div>
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </OffcanvasBody>
        </Offcanvas>
      )}
    </>
  );
};

export default MarkImageModal;
