import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import Loader from "../../components/Common/Loader";
import { useSelector } from "react-redux";
import { getDateOnly } from "../Components/Utils";
import DeleteModal from "../../components/Common/DeleteModal";

const SiteUpdateForm = (props) => {
  const email = useSelector((state) => state.user.email);
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [row, setRow] = useState(null);

  const handleAddSite = () => {
    props?.addSite(true);
    props?.onClose(false);
    props.setUpdateSite(null);
    props?.getCoordArray([]);
    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          props.setCoord({
            long: position.coords.longitude,
            lat: position.coords.latitude,
          });
        },
        (error) => {}
      );
    }
  };

  useEffect(() => {
    getSiteUpdates();
  }, []);

  const getSiteUpdates = () => {
    setLoading(true);
    api
      .get(`siteupdates/get?surveyId=${props?.survey?.id}`)
      .then((res) => {
        if (res?.status === "Success") {
          setSites(res?.response?.findSiteUpdate);
          let coordss = res?.response?.findSiteUpdate?.map((ele) => [
            Number(ele?.longitude),
            Number(ele?.latitude),
          ]);
          props?.getCoordArray(coordss);
          setLoading(false);
        } else {
          toast.error(res?.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const editSite = (val) => {
    props?.setUpdateSite(val);
    props?.addSite(true);
    props?.onClose(false);
    props?.setCoord({
      long: +val.longitude,
      lat: +val.latitude,
    });
  };
  const deleteSite = (val) => {
    setIsDeleteModal(true);
    setRow(val);
  };

  const deleteSiteConfirm = () => {
    setLoading(true);
    api
      .deleteApi(`siteupdates/delete/${row.id}`)
      .then((res) => {
        if (res?.status === "Success") {
          toast.success("Site update deleted successfully");
          setIsDeleteModal(false);
          getSiteUpdates();
        } else {
          toast.error(res?.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="siteForm">
      {isDeleteModal && (
        <DeleteModal
          show={isDeleteModal}
          onCloseClick={() => setIsDeleteModal(false)}
          onDeleteClick={deleteSiteConfirm}
        />
      )}
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <div className="site-header">
        <div className="fs-16 fw-500">Site Updates</div>
        <i
          className="bx bx-x fs-2"
          onClick={() => {
            props?.onClose(false);
            props?.getCoordArray([]);
          }}
        ></i>
      </div>
      <div className="site-header">
        <div className="w-100 text-end">
          <Button color="success" onClick={handleAddSite}>
            Add Site Update
          </Button>
        </div>
      </div>
      <div className="site-body">
        {sites?.length > 0 ? (
          <div>
            {sites?.map((ele, idx) => {
              return (
                <div key={idx} className="border-bottom mb-3">
                  <div className="d-flex justify-content-between ">
                    <div className="d-flex align-items-center">
                      <i className="bx bxs-location-plus fs-2"></i>
                      <div>
                        <div className="fw-500">
                          {props?.survey?.surveyName} - {ele?.locationName}
                        </div>
                        <div style={{ color: "#069B80", fontWeight: "400" }}>
                          {(+ele?.latitude)?.toFixed(4)} N &nbsp;
                          {(+ele?.longitude)?.toFixed(4)} E
                        </div>
                      </div>
                    </div>
                    {email === ele?.createdBy && (
                      <div className="d-flex">
                        <i
                          className="bx bx-edit fs-5 pointer me-2 text-success"
                          onClick={() => editSite(ele)}
                        ></i>
                        <i
                          className="bx bx-trash fs-5 pointer me-2 text-danger"
                          onClick={() => deleteSite(ele)}
                        ></i>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">{ele?.description}</div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="text-primary">
                      <i className="bx bx-user fs-5"></i> {ele?.createdBy}
                    </div>
                    <div className="">
                      <i className="bx bx-calendar fs-5"></i>{" "}
                      {getDateOnly(ele?.updatedAt)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center p-3">
             No Record Found
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteUpdateForm;
