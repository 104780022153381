import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Card,
} from "reactstrap";
import { Tag } from "primereact/tag";
import { Badge } from "primereact/badge";
import DataTable from "react-data-table-component";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import Flatpickr from "react-flatpickr";
import DeleteModal from "../../Common/DeleteModal";
import Loader from "../../CommonComponent/Loader";
import { formatDate } from "../../commons/functions";
import { Header } from "../../header/Header";
import * as api from "../../../api/global.api";
import Pagination from "../../commons/pagination";
import uploadIcon from "../../../assets/images/upload.svg";
import appendIcon from "../../../assets/images/append.svg";
import { AppendFile } from "./uploadFileModal";
import { useAppSelector } from "../../../store/hooks";
import { postSegReq } from "../../../api/api";

class Spinner extends React.Component {
  render() {
    return (
      <div className="spinner-box">
        <div className="loading-icon"></div>
        <span>Loading...</span>
      </div>
    );
  }
}

const PilotDataTable = () => {
  const user = useAppSelector((state) => state.user);
  const clientName = useAppSelector(
    (state) => state?.project?.client?.clientName
  );
  const [loader, setLoader] = useState(true);
  const [assetsList, setAssetsList] = useState();
  const siteId = useParams().siteId;
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(true);

  const [selectedTower, setSelectedTower] = useState<any>(null);

  const [modal, setModal] = useState(false);

  const [inspectionDate, setInspectionDate] = useState(new Date());
  const [hasImageAppendDetails, setHasImageAppendDetails] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [uploadedImageFileName, setUploadedImageFileName] = useState<any>();
  const [uploadedThermalFileName, setUploadedThermalFileName] = useState<any>();
  const [uploadedVideoFileName, setUploadedVideoFileName] = useState<any>();

  const [projectId, setProjectId] = useState<any>();

  const [imageComments, setImageComments] = useState<any>();
  const [thermalComments, setThermalComments] = useState<any>();
  const [videoComments, setVideoComments] = useState<any>();

  const [deleteModal, setDeleteModal] = useState(false);
  const [towerImageStatus, setTowerImageStatus] = useState<any>();
  const [towerThermalStatus, setTowerThermalStatus] = useState<any>();
  const [towerVideoStatus, setTowerVideoStatus] = useState<any>();
  const [towerAppendStatus, setTowerAppendStatus] = useState<any>();

  const [imageIsDeleted, setImageIsDeleted] = useState(false);
  const [thermalIsDeleted, setThermalIsDeleted] = useState(false);
  const [videoIsDeleted, setVideoIsDeleted] = useState(false);
  const [appendIsDeleted, setAppendIsDeleted] = useState(false);

  const [uploadModal, setUploadModal] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([]);
  const corridorId = useParams().corridorId;

  const [uploadedImageFile, setUploadedImageFile] = useState<any>(null);
  const [appendComments, setAppendComments] = useState<any>(null);
  const [appendedImageFile, setAppendedImageFile] = useState<any>(null);
  const [imageAppendSuccess, setImageAppendSuccess] = useState<any>(null);

  const [hasImageUploadDetails, setHasImageUploadDetails] = useState(false);
  const [uploadedThermalFile, setUploadedThermalFile] = useState(null);
  const [thermalUploadSuccess, setThermalUploadSuccess] = useState(false);

  const [hasThermalUploadDetails, setHasThermalUploadDetails] = useState(false);
  const [uploadedVideoFile, setUploadedVideoFile] = useState(null);
  const [videoUploadSuccess, setVideoUploadSuccess] = useState(false);
  const [towerInspectionStart, setTowerInspectionStart] = useState(false);

  const [hasVideoUploadDetails, setHasVideoUploadDetails] = useState(false);
  const [fileType, setFileType] = useState<any>(null);
  const [fileUploadLength, setFileUploadLength] = useState<any>(null);
  const [appendedFileName, setAppendedFileName] = useState<any>(null);
  const [isAnyFileDeleted, setIsAnyFileDeleted] = useState(false);

  const [selectedImageDetails, setSelectedImageDetails] = useState<any>();
  const [selectedThermalDetails, setSelectedThermalDetails] = useState<any>();
  const [selectedVideoDetails, setSelectedVideoDetails] = useState<any>();
  const [selectedAppendDetails, setSelectedAppendDetails] = useState<any>();

  const handlePageChange = (values: any) => {
    getTowersList(values);
    setPage(values);
  };

  interface DataItem {
    towerStatus: string;
    // Other properties of the data item
  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Tower No</span>,
      selector: (row: any) => row.towerNo,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Tower Category</span>,
      selector: (row: any) => row.towerCategory,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Tower Type</span>,
      selector: (row: any) => row.towerType,
    },
    {
      name: <span className="font-weight-bold fs-13">Location</span>,
      selector: (row: any) => {
        let lat = row?.location?.split(",")[0];
        let long = row?.location?.split(",")[1];
        return (
          <span>
            {lat} <span className="fs-18">, </span> {long}
          </span>
        );
      },
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Phase</span>,
    //   selector: (row: any) => row.phase,
    // },
    {
      name: <span className="font-weight-bold fs-13">Circuit</span>,
      selector: (row: any) => row.circuit,
    },
    {
      name: <span className="font-weight-bold fs-13">Line Name</span>,
      selector: (row: any) => row.lineName,
    },

    {
      name: (
        <span
          className="font-weight-bold fs-13"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "40px",
            width: "200px",
          }}
        >
          {" "}
          Status
        </span>
      ),
      sortable: true,
      // grow: 2,
      selector: (cell: any) => {
        switch (cell.towerStatus) {
          case "Yet To Start":
            return (
              <span
                className="badge status_text fs-12"
                style={{ color: "#A5ABAC" }}
              >
                {" "}
                {cell.towerStatus}{" "}
              </span>
            );
          case "Data Captured":
            return (
              <span
                className="badge status_text fs-12"
                style={{ color: "#30B9CC" }}
              >
                {" "}
                {cell.towerStatus}{" "}
              </span>
            );
          case "In Progress":
            return (
              <span
                className="badge status_text fs-12"
                style={{ color: "#D6AC20" }}
              >
                {" "}
                {cell.towerStatus}{" "}
              </span>
            );
          case "Inspection Done":
            return (
              <span
                className="badge status_text fs-12"
                style={{ color: "#FF6402" }}
              >
                {" "}
                {cell.towerStatus}{" "}
              </span>
            );
          default:
            return (
              <span
                className="badge status_text fs-12"
                style={{ color: "#13C56B" }}
              >
                {" "}
                {cell.towerStatus}{" "}
              </span>
            );
        }
      },
      //width: 200,
    },
    {
      name: <span className="font-weight-bold fs-13"> Inspection Date</span>,
      selector: (row: any) =>
        row.inspectionDate ? formatDate(row.inspectionDate) : "",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13"> Uploaded Date</span>,
      selector: (row: any) =>
        row.uploadedDate ? formatDate(row.uploadedDate) : "",
      sortable: true,
    },
    {
      name: (
        <span
          className="font-weight-bold fs-13"
          style={{
            display: "flex",
            marginLeft: "50px",
            width: "100px",
          }}
        >
          Actions
        </span>
      ),
      // sortable: true,
      grow: 2,
      selector: (row: any) => row.id,
      cell: (row: any) => {
        switch (row?.towerStatus) {
          case "Yet To Start":
            return (
              <div className="d-flex pointer" onClick={() => toggle(row)}>
                <span>
                  <img src={uploadIcon} alt="" className="upload-icon" /> &nbsp;
                  <label style={{ color: "#30B9CC" }} className="fs-16">
                    Upload
                  </label>
                </span>
              </div>
            );
          case "Completed":
            return (
              <>
                <span
                  className="primaryColor ps-3 pointer"
                  style={{ color: "#30B9CC" }}
                >
                  {" "}
                  {row.uploadDetails.length} files uploaded
                </span>
              </>
            );

          case "Save As Draft":
            return (
              <>
                <span
                  className="primaryColor ps-3 pointer"
                  style={{ color: "#30B9CC" }}
                >
                  {" "}
                  {row.uploadDetails.length} files uploaded
                </span>
              </>
            );

          case "Inspection Done":
            return (
              <>
                <span
                  className="primaryColor ps-3 pointer"
                  style={{ color: "#30B9CC" }}
                  onClick={() => toggle(row)}
                >
                  {" "}
                  {row.uploadDetails.length} files uploaded &nbsp;
                </span>
                &nbsp;
                <div
                  className="d-flex pointer"
                  onClick={() => toggleAppend(row)}
                >
                  <span>
                    <img src={appendIcon} alt="" className="upload-icon" />{" "}
                    &nbsp;
                    <label style={{ color: "#304ECC" }}>AppendFile</label>
                  </span>
                </div>
              </>
            );

          case "Data Captured":
            return (
              <span className="primaryColor ps-3 pointer">
                <img src={uploadIcon} alt="" className="upload-icon" /> &nbsp;
                <label style={{ color: "#39C717" }} className="fs-16">
                  Upload Inprogress
                </label>
              </span>
            );
          case "In Progress":
            return (
              <>
                <span
                  className="primaryColor ps-3 pointer"
                  style={{ color: "#30B9CC" }}
                  onClick={() => toggle(row)}
                >
                  {" "}
                  {row.uploadDetails.length} files uploaded &nbsp;
                </span>
                &nbsp;
                <div
                  className="d-flex pointer"
                  onClick={() => toggleAppend(row)}
                >
                  <span>
                    <img src={appendIcon} alt="" className="upload-icon" />{" "}
                    &nbsp;
                    <label style={{ color: "#304ECC" }}>AppendFile</label>
                  </span>
                </div>
              </>
            );
        }
      },
    },
  ];

  useEffect(() => {
    setLoader(true);
    getTowersList(page);
    setLoader(false);
  }, [page]);

  const getTowersList = async (val: any) => {
    setLoading(true);
    await api
      .get(`getTower/?corridorId=${Number(corridorId)}&page=${val}&limit=12`)
      .then((res) => {
        setProjectId(res.response.rows[0].corridor.sources.project.id);
        setTableData(res.response.rows);
        setCount(res?.response?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const deleteUpload = async (tower: any, fileType: string) => {
    setLoading(true);
    setLoader(true);
    let query = `?id=${tower.id}&type=${fileType}&zipFile=true`;
    await api
      .deleteApi(`deleteTowerUploads/${query}`)
      .then((res) => {
        if (res?.status.toLowerCase() === "failed") {
          setLoader(false);
          setLoading(false);
          toast.error(res?.message);
        } else {
          if (fileType === "image") {
            getTowersList(page);
            setUploadedImageFileName("");
            setImageComments("");
            setTowerImageStatus("");
            setImageIsDeleted(true);
            setIsAnyFileDeleted(true);
            setImageUploadSuccess(false);
          } else if (fileType === "thermal") {
            getTowersList(page);
            setUploadedThermalFileName("");
            setThermalComments("");
            setTowerThermalStatus("");
            setThermalIsDeleted(true);
            setIsAnyFileDeleted(true);
            setThermalUploadSuccess(false);
          } else if (fileType === "video") {
            getTowersList(page);
            setUploadedVideoFileName("");
            setVideoComments("");
            setTowerVideoStatus("");
            setVideoIsDeleted(true);
            setIsAnyFileDeleted(true);
            setVideoUploadSuccess(false);
          } else if (fileType === "extendimage") {
            getTowersList(page);
            setAppendedFileName("");
            setAppendComments("");
            setTowerAppendStatus("");
            setAppendIsDeleted(true);
            setIsAnyFileDeleted(true);
            setImageAppendSuccess(false);
          }

          setLoader(false);
          setLoading(false);
          setDeleteModal(false);
          toast.success("File Is Deleted Successfully.");
        }
      })
      .catch((err: any) => {
        setLoader(false);
        setLoading(false);
        toast.error(err);
      });
  };

  useEffect(() => {
    const storedId = localStorage.getItem("selectedTower");
    if (storedId) {
      try {
        setSelectedTower(JSON.parse(storedId));
      } catch (error) {
        localStorage.removeItem("selectedTower");
      }
    }
  }, []);

  const uploadTowerImageZipFile = async (event: any) => {
    setLoader(true);
    setLoading(true);

    let formData = {
      id: selectedTower.id,
      type: "image",
      clientName: clientName,
      projectName: selectedTower.corridor.sources.project.projectName,
      corridorName: selectedTower.corridor.corridorName,
      towerNo: selectedTower.towerNo,
    };

    setSelectedFile(formData);

    let res = await api.post(`towerlocalupload`, formData);
    if (res?.status.toLowerCase() === "failed") {
      toast.error(res.errormessage);
    } else {
      let details = await api.get(`getTowerFileUpload/${selectedTower.id}`);

      let imageUploadDetail = details.response.find(
        (uploadDetail: any) => uploadDetail.fileType === "image"
      );

      if (imageUploadDetail) {
        setUploadedImageFile(imageUploadDetail.towerZipName);
        setUploadedImageFileName(imageUploadDetail.towerZipName);
        setTowerImageStatus(imageUploadDetail.towerFileStatus || "");
        setImageUploadSuccess(true);
        setHasImageUploadDetails(true);
        setImageIsDeleted(false);
        setIsAnyFileDeleted(false);
        toast.success("File uploaded successfully");
      }
    }

    setLoader(false);
    setLoading(false);
  };

  const AppendTowerZipFile = async (event: any) => {
    setLoader(true);
    setLoading(true);

    let formData = {
      id: selectedTower.id,
      type: "extendimage",
      projectName: selectedTower.corridor.sources.project.projectName,
      corridorName: selectedTower.corridor.corridorName,
      towerNo: selectedTower.towerNo,
    };

    setSelectedFile(formData);

    let res = await api.post(`towerawsuploads`, formData);
    if (res?.status.toLowerCase() === "failed") {
      toast.error(res.errormessage);
    } else {
      let details = await api.get(`getTowerFileUpload/${selectedTower.id}`);

      let appendDetail = details.response.find(
        (uploadDetail: any) => uploadDetail.fileType === "extendimage"
      );

      if (appendDetail) {
        setAppendedImageFile(appendDetail.towerZipName);
        setAppendedFileName(appendDetail.towerZipName);
        setTowerAppendStatus(appendDetail.towerFileStatus || "");
        setImageAppendSuccess(true);
        setHasImageAppendDetails(true);
        setAppendIsDeleted(false);
        setIsAnyFileDeleted(false);
        toast.success("File uploaded successfully");
      }
    }

    setLoader(false);
    setLoading(false);
  };

  const uploadTowerThermalZipFile = async (event: any) => {
    setLoader(true);
    setLoading(true);

    let formData = {
      id: selectedTower.id,
      type: "thermal",
      projectName: selectedTower.corridor.sources.project.projectName,
      corridorName: selectedTower.corridor.corridorName,
      towerNo: selectedTower.towerNo,
    };

    setSelectedFile(formData);

    let res = await api.post(`towerawsuploads`, formData);
    if (res?.status.toLowerCase() === "failed") {
      toast.error(res.errormessage);
    } else {
      let details = await api.get(`getTowerFileUpload/${selectedTower.id}`);

      let thermalUploadDetail = details.response.find(
        (uploadDetail: any) => uploadDetail.fileType === "thermal"
      );

      if (thermalUploadDetail) {
        setUploadedThermalFile(thermalUploadDetail.towerZipName);
        setUploadedThermalFileName(thermalUploadDetail.towerZipName);

        setTowerThermalStatus(thermalUploadDetail.towerFileStatus || "");

        setThermalUploadSuccess(true);
        setHasThermalUploadDetails(true);
        setThermalIsDeleted(false);
        setIsAnyFileDeleted(false);
        toast.success("File uploaded successfully");
      }
    }

    setLoader(false);
    setLoading(false);
  };

  const uploadTowerVideoZipFile = async (event: any) => {
    setLoader(true);
    setLoading(true);

    let formData = {
      id: selectedTower.id,
      type: "video",
      projectName: selectedTower.corridor.sources.project.projectName,
      corridorName: selectedTower.corridor.corridorName,
      towerNo: selectedTower.towerNo,
    };

    setSelectedFile(formData);

    let res = await api.post(`towerlocalupload`, formData);
    if (res?.status.toLowerCase() === "failed") {
      toast.error(res.errormessage);
    } else {
      let details = await api.get(`getTowerFileUpload/${selectedTower.id}`);

      let videoUploadDetail = details.response.find(
        (uploadDetail: any) => uploadDetail.fileType === "video"
      );
      if (videoUploadDetail) {
        setUploadedVideoFile(videoUploadDetail.towerZipName);
        setUploadedVideoFileName(videoUploadDetail.towerZipName);

        setTowerVideoStatus(videoUploadDetail.towerFileStatus || "");

        setVideoUploadSuccess(true);
        setHasVideoUploadDetails(true);
        setVideoIsDeleted(false);
        setIsAnyFileDeleted(false);
        toast.success("File uploaded successfully");
      }
    }

    setLoader(false);
    setLoading(false);
  };

  useEffect(() => {});

  const isFileDeleted = () => {
    return isAnyFileDeleted;
  };

  const checkIsZIPFileUpload = async () => {
    if (isFileDeleted()) {
      setModal(false);
      setUploadModal(false);
    } else {
      setLoader(true);
      setLoading(true);

      if (selectedTower) {
        updateTowerList({
          id: selectedTower.id,
          inspectionDate: inspectionDate,
          pilotTowerUpdate: true,
          uploadedBy: user.firstName + " " + user.lastName,
        });

        let b = await api.get(`getTowerFileUpload/${selectedTower.id}`);

        let imageUploadDetail = b.response.find(
          (uploadDetail: any) => uploadDetail.fileType === "image"
        );

        let thermalUploadDetail = b.response.find(
          (uploadDetail: any) => uploadDetail.fileType === "thermal"
        );

        let videoUploadDetail = b.response.find(
          (uploadDetail: any) => uploadDetail.fileType === "video"
        );

        let appendDetail = b.response.find(
          (uploadDetail: any) => uploadDetail.fileType === "extendimage"
        );

        if (imageUploadDetail) {
          if (imageComments.trim() !== "" && imageUploadDetail.id) {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
              uploadComments: [
                {
                  id: imageUploadDetail.id ? imageUploadDetail.id : "",
                  comments: imageComments,
                },
              ],
            });
          } else {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
            });
          }
        }

        if (thermalUploadDetail) {
          if (thermalComments.trim() !== "" && thermalUploadDetail.id) {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
              uploadComments: [
                {
                  id: thermalUploadDetail.id ? thermalUploadDetail.id : "",
                  comments: thermalComments,
                },
              ],
            });
          } else {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
            });
          }
        }

        if (videoUploadDetail) {
          if (videoComments.trim() !== "" && videoUploadDetail.id) {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
              uploadComments: [
                {
                  id: videoUploadDetail.id ? videoUploadDetail.id : "",
                  comments: videoComments,
                },
              ],
            });
          } else {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
            });
          }
        }

        if (appendDetail) {
          if (appendComments.trim() !== "" && appendDetail.id) {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
              uploadComments: [
                {
                  id: appendDetail.id ? appendDetail.id : "",
                  comments: appendComments,
                },
              ],
            });
          } else {
            updateTowerList({
              id: selectedTower.id,
              inspectionDate: inspectionDate,
              pilotTowerUpdate: true,
              uploadedBy: user.firstName + " " + user.lastName,
            });
          }
        }

        setLoader(false);
        setLoading(false);
      }
      toast.success("Tower data updated successfully");
      // else {
      //   uploadZIPFile(selectedFile);
      // }
      setUploadModal(false);
      setModal(false);
    }
  };

  const uploadZIPFile = async (file: any) => {
    setLoader(true);
    setLoading(true);

    await api
      .post(`towerUpload`, file)
      .then((res) => {
        if (res?.status.toLowerCase() === "failed") {
          toast.error(res.errormessage);
          setLoader(false);
          setLoading(false);
        } else {
          api.get(`getTowerFileUpload/${selectedTower.id}`).then((res) => {
            if (res?.status.toLowerCase() === "failed") {
              toast.error(res.errormessage);
            }
            setFileUploadLength(res.response.length);
          });

          updateTowerList({
            id: selectedTower.id,
            inspectionDate: inspectionDate.toISOString(),
            pilotTowerUpdate: true,
            uploadedDate: new Date().toISOString(),
            uploadedBy: user.firstName + " " + user.lastName,
          });
          setLoader(false);
          setLoading(false);
          toast.success("File Uploaded Successfully.");
        }
      })
      .catch((err: any) => {
        setLoader(false);
        setLoading(false);
        toast.error(err);
      });
  };

  const updateTowerList = async (data: any) => {
    setLoader(true);
    setLoading(true);
    await api
      .put(`updateTower`, data)
      .then((res) => {
        if (res?.status.toLowerCase() === "failed") {
          toast.error(res?.errormessage);
        } else {
          getTowersList(page);
          // toast.success("Tower data updated successfully");
          setModal(false);
          setUploadModal(false);
          setLoader(false);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        toast.error(err);
        toast.error(err);
        setModal(false);
        setLoader(false);
        setLoading(false);
      });
  };

  const deleteConfirmation = (tower: any, fileType: string) => {
    setDeleteModal(true);
    setFileType(fileType);
    setSelectedTower(tower);

    // const uploadDetailToDelete = tower.uploadDetails.find(
    //   (uploadDetail: any) => uploadDetail.type === fileType
    // );

    // if (uploadDetailToDelete) {
    //   switch (fileType) {
    //     case "image":
    //       setUploadedImageFile(null);
    //       setUploadedImageFileName("");
    //       setImageComments("");
    //       setTowerImageStatus("");
    //       setImageUploadSuccess(false);

    //       setIsDeleted(true);
    //       break;
    //     case "thermal":
    //       setUploadedThermalFile(null);
    //       setUploadedThermalFileName("");
    //       setThermalComments("");
    //       setTowerThermalStatus("");
    //       setThermalUploadSuccess(false);
    //       setIsDeleted(true);

    //       break;
    //     case "video":
    //       setUploadedVideoFile(null);
    //       setUploadedVideoFileName("");
    //       setVideoComments("");
    //       setTowerVideoStatus("");
    //       setIsDeleted(true);
    //       break;

    //     case "extendimage":
    //       setAppendedImageFile(null);
    //       setAppendedFileName("");
    //       setAppendComments("");
    //       setTowerAppendStatus("");
    //       setIsDeleted(true);
    //       break;

    //     default:
    //       break;
    //   }
    // }
  };

  const toggle = (data: any) => {
    if (data) {
      setSelectedTower(data);
      localStorage.setItem("selectedTower", data);
      setInspectionDate(new Date());
      setImageComments("");
      setThermalComments("");
      setVideoComments("");
      setSelectedFile("");
      setIsAnyFileDeleted(false);
    }

    if (modal) {
      setModal(false);
      setSelectedTower(null);
      localStorage.removeItem("selectedTower");
    } else {
      setModal(true);
    }
  };

  const toggleAppend = (data: any) => {
    if (data) {
      setSelectedTower(data);
      localStorage.setItem("selectedTower", data);
      setInspectionDate(new Date());
      setAppendComments("");
      setSelectedFile("");
      setIsAnyFileDeleted(false);
    }

    if (uploadModal) {
      setUploadModal(false);
      setSelectedTower(null);
      localStorage.removeItem("selectedTower");
    } else {
      setUploadModal(true);
    }
  };

  useEffect(() => {
    if (selectedTower) {
      if (selectedTower && inspectionDate) {
        setInspectionDate(selectedTower.inspectionDate);
      } else setInspectionDate(new Date());

      setTowerInspectionStart(selectedTower.towerInspectionStart);
      const imageUploadDetail = selectedTower.uploadDetails.find(
        (uploadDetail: any) => uploadDetail.fileType === "image"
      );
      const thermalUploadDetail = selectedTower.uploadDetails.find(
        (uploadDetail: any) => uploadDetail.fileType === "thermal"
      );
      const videoUploadDetail = selectedTower.uploadDetails.find(
        (uploadDetail: any) => uploadDetail.fileType === "video"
      );
      const appendDetail = selectedTower.uploadDetails.find(
        (uploadDetail: any) => uploadDetail.fileType === "extendimage"
      );

      if (imageUploadDetail) {
        //  setSelectedImageDetails(imageUploadDetail);
        setUploadedImageFileName(imageUploadDetail.towerZipName || "");
        setImageComments(imageUploadDetail.uploadComments || "");
        setTowerImageStatus(imageUploadDetail.towerFileStatus || "");
        setInspectionDate(selectedTower.inspectionDate);
        setUploadedImageFile(null);
        setImageUploadSuccess(false);

        fetch(imageUploadDetail.fileURL)
          .then((response) => response.blob())
          .then((fileBlob) => {
            const file: any = new File(
              [fileBlob],
              imageUploadDetail.towerZipName
            );
            setUploadedImageFile(file);
            setImageUploadSuccess(true);
          })
          .catch((error) => {
            console.error("Error fetching the image file:", error);
          });
      } else {
        setSelectedImageDetails({});
        setUploadedImageFileName("");
        setImageComments("");
        setTowerImageStatus("");
        setUploadedImageFile(null);
        setImageUploadSuccess(false);
      }

      if (thermalUploadDetail) {
        // setSelectedThermalDetails(thermalUploadDetail);
        setUploadedThermalFileName(thermalUploadDetail.towerZipName || "");
        setThermalComments(thermalUploadDetail.uploadComments || "");
        setTowerThermalStatus(thermalUploadDetail.towerFileStatus || "");
        setInspectionDate(selectedTower.inspectionDate);
        setUploadedThermalFile(null);
        setThermalUploadSuccess(false);

        fetch(thermalUploadDetail.fileURL)
          .then((response) => response.blob())
          .then((fileBlob) => {
            const file: any = new File(
              [fileBlob],
              thermalUploadDetail.towerZipName
            );
            setUploadedThermalFile(file);
            setThermalUploadSuccess(true);
          })
          .catch((error) => {
            console.error("Error fetching the thermal file:", error);
          });
      } else {
        setSelectedThermalDetails({});
        setUploadedThermalFileName("");
        setThermalComments("");
        setTowerThermalStatus("");
        setUploadedThermalFile(null);
        setThermalUploadSuccess(false);
      }

      if (videoUploadDetail) {
        //setSelectedVideoDetails(videoUploadDetail);
        setUploadedVideoFileName(videoUploadDetail.towerZipName || "");
        setVideoComments(videoUploadDetail.uploadComments || "");
        setTowerVideoStatus(videoUploadDetail.towerFileStatus || "");
        setInspectionDate(selectedTower.inspectionDate);
        setUploadedVideoFile(null);
        setVideoUploadSuccess(false);

        fetch(videoUploadDetail.fileURL)
          .then((response) => response.blob())
          .then((fileBlob) => {
            const file: any = new File(
              [fileBlob],
              videoUploadDetail.towerZipName
            );
            setUploadedVideoFile(file);
            setVideoUploadSuccess(true);
          })
          .catch((error) => {
            console.error("Error fetching the video file:", error);
          });
      } else {
        setSelectedVideoDetails({});
        setUploadedVideoFileName("");
        setVideoComments("");
        setTowerVideoStatus("");
        setUploadedVideoFile(null);
        setVideoUploadSuccess(false);
      }

      if (appendDetail) {
        //setSelectedAppendDetails(appendDetail);
        setAppendedFileName(appendDetail.towerZipName || "");
        setAppendComments(appendDetail.uploadComments || "");
        setTowerAppendStatus(appendDetail.towerFileStatus || "");
        setInspectionDate(selectedTower.inspectionDate);
        setAppendedImageFile(null);
        setImageAppendSuccess(false);

        fetch(appendDetail.fileURL)
          .then((response) => response.blob())
          .then((fileBlob) => {
            const file: any = new File([fileBlob], appendDetail.towerZipName);
            setAppendedImageFile(file);
            setImageAppendSuccess(true);
          })
          .catch((error) => {
            console.error("Error fetching the image file:", error);
          });
      } else {
        setSelectedAppendDetails({});
        setAppendedFileName("");
        setAppendComments("");
        setTowerAppendStatus("");
        setAppendedImageFile(null);
        setImageAppendSuccess(false);
      }
    } else {
      setUploadedImageFileName("");
      setImageComments("");
      setTowerImageStatus("");
      setUploadedImageFile(null);
      setImageUploadSuccess(false);

      setUploadedThermalFileName("");
      setThermalComments("");
      setTowerThermalStatus("");
      setUploadedThermalFile(null);
      setThermalUploadSuccess(false);

      setUploadedVideoFileName("");
      setVideoComments("");
      setTowerVideoStatus("");
      setUploadedVideoFile(null);
      setVideoUploadSuccess(false);

      setAppendedFileName("");
      setAppendComments("");
      setTowerAppendStatus("");
      setAppendedImageFile(null);
      setImageAppendSuccess(false);
    }
  }, [selectedTower]);

  useEffect(() => {
    if (modal) {
      setLoader(true);
    }
  }, [modal]);

  return (
    <React.Fragment>
      <Loader loader={loading} />

      <div className="page-content px-5 pb-0 oy">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => deleteUpload(selectedTower, fileType)}
          onCloseClick={() => setDeleteModal(false)}
        />
        <ToastContainer />
        <div
          onClick={() => navigate(`/pilot/projects/${projectId}`)}
          className="fs-20 py-4 primaryColor pointer"
        >
          <i className=" ri-arrow-left-circle-line fs-22 me-2 align-bottom "></i>
          Back
        </div>
        <DataTable columns={columns} data={tableData} />

        <div>
          <Modal
            isOpen={uploadModal}
            id="event-modal"
            centered
            className="uploadModal"
          >
            <Loader loader={loading} className="spinner-box" />

            <ModalHeader
              toggle={toggleAppend}
              className="p-3 primaryBg  modal-title"
            >
              <span className="text-light">
                {imageAppendSuccess && appendComments !== ""
                  ? "Appended File Details"
                  : "Append File"}
              </span>
            </ModalHeader>
            <Form
              className="needs-validation"
              name="event-form"
              id="form-event"
              onSubmit={(e) => {
                e.preventDefault();
                checkIsZIPFileUpload();
                //  return false;
              }}
            >
              <ModalBody>
                <Row className="event-form">
                  <Col lg={4} md={6}>
                    <div className="mb-3">
                      <Label>Inspection Date</Label>
                      <div className="input-group">
                        <Flatpickr
                          className="form-control"
                          name="defaultDate"
                          placeholder="Select Date"
                          value={inspectionDate || ""}
                          options={{
                            maxDate: new Date(),
                            dateFormat: "d M, Y",
                          }}
                          onChange={(date: any) => {
                            const dateObject = new Date(date);
                            const isoString: any = dateObject.toISOString();
                            setInspectionDate(isoString);
                          }}
                        />
                        <span className="input-group-text">
                          <i className="ri-calendar-event-line"></i>
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="event-form mb-3 d-flex align-items-center">
                  <Col xxl={2} md={2}>
                    <span className="fstatus-success">
                      {" "}
                      {imageAppendSuccess ? towerAppendStatus : ""}
                    </span>
                    <Label className="form-label">Upload</Label>
                  </Col>

                  <Col xxl={6} md={6}>
                    {!imageAppendSuccess ? (
                      <>
                        <div className="dropzone dz-clickable form-control p-0">
                          <div className="needsclick pointer">
                            <input
                              hidden
                              id="file4"
                              type="button"
                              //accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                              placeholder="Browse"
                              onClick={(event) => AppendTowerZipFile(event)}
                            />
                            <Label htmlFor="file4">
                              <div className="mx-4 d-flex align-items-center">
                                <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                                <span className="text-muted">
                                  Upload Zip File
                                </span>
                              </div>
                            </Label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {
                          <div className="col-md-12 pointer table-col-link p-ellipsis">
                            <div className="p-2">
                              <span>
                                {imageAppendSuccess ? appendedFileName : ""}{" "}
                                {
                                  <span
                                    className={`closeIcon fs-20 rounded-pill ${
                                      towerInspectionStart ? "disabled" : ""
                                    }`}
                                    onClick={() => {
                                      //setIsDeleted(true);
                                      deleteConfirmation(
                                        selectedTower,
                                        "extendimage"
                                      );
                                    }}
                                  >
                                    <sup
                                      className={`delete-icon ${
                                        appendIsDeleted ? "is-deleted" : ""
                                      }`}
                                    >
                                      X
                                    </sup>
                                  </span>
                                }
                              </span>
                              {/* <input
                                hidden
                                id="file4"
                                type="file"
                                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                placeholder="Browse"
                                onChange={(event) => AppendTowerZipFile(event)}
                                disabled={!appendIsDeleted}
                              />
                              <Label htmlFor="file4">
                                <div className="mx-4 d-flex align-items-center">
                                  <img
                                    src={uploadIcon}
                                    alt=""
                                    className="upload-icon"
                                  />{" "}
                                  &nbsp;
                                  <label
                                    style={{ color: "#30B9CC" }}
                                    className="fs-16"
                                  >
                                    Reupload
                                  </label>
                                </div>
                              </Label> */}
                            </div>
                          </div>
                        }
                      </>
                    )}
                  </Col>

                  <Col xxl={4} md={4}>
                    <textarea
                      className="form-control d-block"
                      id="event-description"
                      name="description"
                      placeholder="Enter Comments"
                      onChange={(event) => {
                        setAppendComments(event.target.value);
                      }}
                      value={appendComments}
                    ></textarea>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <button
                  className="me-3 wd-10 btn btn-outline-success"
                  type="button"
                  id="btn-save-event"
                  onClick={() => setUploadModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="wd-10 btn btn-primary"
                  color="darkgreen"
                  type="submit"
                  id="btn-save-event"
                  disabled={!inspectionDate}
                >
                  Save
                </button>
              </ModalFooter>
            </Form>
          </Modal>
        </div>

        <Modal isOpen={modal} id="event-modal" centered className="uploadModal">
          <Loader loader={loading} className="spinner-box" />

          <ModalHeader toggle={toggle} className="p-3 primaryBg  modal-title">
            <span className="text-light">
              {imageUploadSuccess &&
              thermalUploadSuccess &&
              videoUploadSuccess &&
              imageComments !== "" &&
              thermalComments !== "" &&
              videoComments !== ""
                ? "Uploaded File Details"
                : "Upload File"}
            </span>
          </ModalHeader>

          <Form
            className="needs-validation"
            name="event-form"
            id="form-event"
            onSubmit={(e) => {
              e.preventDefault();

              checkIsZIPFileUpload();
            }}
          >
            <ModalBody>
              <Row className="event-form">
                <Col lg={4} md={6}>
                  <div className="mb-3">
                    <Label>Inspection Date</Label>

                    <div className="input-group">
                      <Flatpickr
                        className="form-control"
                        name="defaultDate"
                        placeholder="Select Date"
                        value={inspectionDate || ""}
                        options={{
                          maxDate: new Date(),

                          dateFormat: "d M, Y",
                        }}
                        onChange={(date: any) => {
                          const dateObject = new Date(date);

                          const isoString: any = dateObject.toISOString();

                          setInspectionDate(isoString);
                        }}
                      />

                      <span className="input-group-text">
                        <i className="ri-calendar-event-line"></i>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Card
                className={
                  imageUploadSuccess &&
                  thermalUploadSuccess &&
                  videoUploadSuccess &&
                  imageComments !== "" &&
                  thermalComments !== "" &&
                  videoComments !== ""
                    ? ""
                    : "noBorderCard"
                }
              >
                {imageUploadSuccess && (
                  <Tag
                    severity={
                      towerImageStatus === "COMPLETED"
                        ? "success"
                        : towerImageStatus === "FAILED"
                        ? "danger"
                        : "warning"
                    }
                    value={towerImageStatus}
                    style={{ width: "100px", borderRadius: "0px 0px 11px 0px" }}
                  ></Tag>
                )}

                <Row className="event-form mb-3 d-flex align-items-center justify-content-evenly mx-2">
                  <Col xxl={2} md={2}>
                    <Label className="form-label">Image</Label>
                  </Col>

                  {!imageUploadSuccess ? (
                    <Col xxl={6} md={6}>
                      <div className="dropzone dz-clickable form-control p-0">
                        <div className="needsclick pointer">
                          <input
                            hidden
                            id="file1"
                            type="button"
                            //accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            placeholder="Browse"
                            onClick={(event) => {
                              uploadTowerImageZipFile(event);
                            }}
                          />

                          <Label htmlFor="file1">
                            <div className="mx-4 d-flex align-items-center">
                              <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />

                              <span className="text-muted">
                                Upload Zip File
                              </span>
                            </div>
                          </Label>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col xxl={6} md={6}>
                      <Row>
                        <Col xxl={6} md={6}>
                          <span>
                            {imageUploadSuccess ? (
                              <span>
                                {uploadedImageFileName}

                                <span
                                  onClick={() => {
                                    deleteConfirmation(selectedTower, "image");
                                  }}
                                  style={{ position: "relative", top: "-10px" }}
                                >
                                  <Badge
                                    className="ms-1 pointer"
                                    value="X"
                                    severity="info"
                                  ></Badge>
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Col>

                        {/* <Col xxl={6} md={6}>
                          <input
                            hidden
                            id="file1"
                            type="file"
                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            placeholder="Browse"
                            onChange={(event) => uploadTowerImageZipFile(event)}
                            disabled={!imageIsDeleted}
                          />

                          <Label htmlFor="file1">
                            <div className="mx-4 d-flex align-items-center">
                              <img
                                src={uploadIcon}
                                alt=""
                                className="upload-icon"
                              />{" "}
                              &nbsp;
                              <label
                                style={{ color: "#30B9CC" }}
                                className="fs-16"
                              >
                                Reupload
                              </label>
                            </div>
                          </Label>
                        </Col> */}
                      </Row>
                    </Col>
                  )}

                  <Col xxl={4} md={4}>
                    <textarea
                      className="form-control mt-2"
                      id="event-description"
                      name="description"
                      placeholder="Enter Comments"
                      onChange={(event) => {
                        setImageComments(event.target.value);
                      }}
                      value={imageComments}
                    ></textarea>
                  </Col>
                </Row>
              </Card>

              <Card
                className={
                  imageUploadSuccess &&
                  thermalUploadSuccess &&
                  videoUploadSuccess &&
                  imageComments !== "" &&
                  thermalComments !== "" &&
                  videoComments !== ""
                    ? ""
                    : "noBorderCard"
                }
              >
                {thermalUploadSuccess && (
                  <Tag
                    severity={
                      towerThermalStatus === "COMPLETED"
                        ? "success"
                        : towerImageStatus === "FAILED"
                        ? "danger"
                        : "warning"
                    }
                    value={towerThermalStatus}
                    style={{ width: "100px", borderRadius: "0px 0px 11px 0px" }}
                  ></Tag>
                )}

                <Row className="event-form mb-3 d-flex align-items-center justify-content-evenly mx-2">
                  <Col xxl={2} md={2}>
                    <Label className="form-label">Thermal</Label>
                  </Col>

                  <Col xxl={6} md={6}>
                    {!thermalUploadSuccess ? (
                      <div className="dropzone dz-clickable form-control p-0">
                        <div className="needsclick pointer">
                          <input
                            hidden
                            id="file2"
                            type="button"
                            //accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            placeholder="Browse"
                            onClick={(event) =>
                              uploadTowerThermalZipFile(event)
                            }
                          />

                          <Label htmlFor="file2">
                            <div className="mx-4 d-flex align-items-center">
                              <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />

                              <span className="text-muted">
                                Upload Zip File
                              </span>
                            </div>
                          </Label>
                        </div>
                      </div>
                    ) : (
                      <Row>
                        <Col xxl={6}>
                          <span>
                            {thermalUploadSuccess ? (
                              <span className="d-flex">
                                <span>{uploadedThermalFileName}</span>

                                <span
                                  onClick={() =>
                                    deleteConfirmation(selectedTower, "thermal")
                                  }
                                  style={{ position: "relative", top: "-10px" }}
                                >
                                  <Badge
                                    className="ms-1 pointer"
                                    value="X"
                                    severity="info"
                                  ></Badge>
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Col>

                        {/* <Col xxl={6} md={6}>
                          <input
                            hidden
                            id="file2"
                            type="file"
                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            placeholder="Browse"
                            onChange={(ev) => uploadTowerThermalZipFile(ev)}
                            disabled={!thermalIsDeleted}
                          />

                          <Label htmlFor="file2">
                            <div className="mx-4 d-flex align-items-center">
                              <img
                                src={uploadIcon}
                                alt=""
                                className="upload-icon"
                              />{" "}
                              &nbsp;
                              <label
                                style={{ color: "#30B9CC" }}
                                className="fs-16"
                              >
                                Reupload
                              </label>
                            </div>
                          </Label>
                        </Col> */}
                      </Row>
                    )}
                  </Col>

                  <Col xxl={4} md={4}>
                    <textarea
                      className="form-control mt-2"
                      id="event-description"
                      name="description"
                      placeholder="Enter Comments"
                      onChange={(event) => {
                        setThermalComments(event.target.value);
                      }}
                      value={thermalComments || ""}
                    ></textarea>
                  </Col>
                </Row>
              </Card>

              <Card
                className={
                  imageUploadSuccess &&
                  thermalUploadSuccess &&
                  videoUploadSuccess &&
                  imageComments !== "" &&
                  thermalComments !== "" &&
                  videoComments !== ""
                    ? ""
                    : "noBorderCard"
                }
              >
                {videoUploadSuccess && (
                  <Tag
                    severity={
                      towerVideoStatus === "COMPLETED"
                        ? "success"
                        : towerVideoStatus === "FAILED"
                        ? "danger"
                        : "warning"
                    }
                    value={towerVideoStatus}
                    style={{ width: "100px", borderRadius: "0px 0px 11px 0px" }}
                  ></Tag>
                )}

                <Row className="event-form mb-3 d-flex align-items-center justify-content-evenly mx-2">
                  <Col xxl={2} md={2}>
                    <Label className="form-label">Video</Label>
                  </Col>

                  <Col xxl={6} md={6}>
                    {!videoUploadSuccess ? (
                      <>
                        <div className="dropzone dz-clickable form-control p-0">
                          <div className="needsclick pointer">
                            <input
                              hidden
                              id="file3"
                              type="button"
                              //accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                              placeholder="Browse"
                              onClick={(event) =>
                                uploadTowerVideoZipFile(event)
                              }
                            />

                            <Label htmlFor="file3">
                              <div className="mx-4 d-flex align-items-center">
                                <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />

                                <span className="text-muted">
                                  Upload Zip File
                                </span>
                              </div>
                            </Label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Row>
                        <Col xxl={6} md={6}>
                          <span>
                            {videoUploadSuccess ? (
                              <span className="d-flex">
                                <span>{uploadedVideoFileName}</span>

                                <span
                                  onClick={() =>
                                    deleteConfirmation(selectedTower, "video")
                                  }
                                  style={{ position: "relative", top: "-10px" }}
                                >
                                  <Badge
                                    className="ms-1 pointer"
                                    value="X"
                                    severity="info"
                                  ></Badge>
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </Col>

                        {/* <Col xxl={6} md={6}>
                          <input
                            hidden
                            id="file3"
                            type="file"
                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                            placeholder="Browse"
                            onChange={(ev) => uploadTowerVideoZipFile(ev)}
                            disabled={!videoIsDeleted}
                          />

                          <Label htmlFor="file3">
                            <div className="mx-4 d-flex align-items-center">
                              <img
                                src={uploadIcon}
                                alt=""
                                className="upload-icon"
                              />{" "}
                              &nbsp;
                              <label
                                style={{ color: "#30B9CC" }}
                                className="fs-16"
                              >
                                Reupload
                              </label>
                            </div>
                          </Label>
                        </Col> */}
                      </Row>
                    )}
                  </Col>

                  <Col xxl={4} md={4}>
                    <textarea
                      className="form-control mt-2"
                      id="event-description"
                      name="description"
                      placeholder="Enter Comments"
                      onChange={(event) => {
                        setVideoComments(event.target.value);
                      }}
                      value={videoComments || ""}
                    ></textarea>
                  </Col>
                </Row>
              </Card>
            </ModalBody>

            <ModalFooter>
              <button
                className="me-3 wd-10 btn btn-outline-success"
                type="button"
                id="btn-save-event"
                onClick={() => setModal(false)}
              >
                Cancel
              </button>

              <button
                className="wd-10 btn btn-primary"
                color="darkgreen"
                type="submit"
                id="btn-save-event"
                disabled={!inspectionDate}
              >
                {imageUploadSuccess &&
                thermalUploadSuccess &&
                videoUploadSuccess &&
                imageComments !== "" &&
                thermalComments !== "" &&
                videoComments !== ""
                  ? "Update"
                  : "Save"}
              </button>
            </ModalFooter>
          </Form>
        </Modal>

        {count > 12 && (
          <Pagination
            handlePageChange={handlePageChange}
            count={count}
            itemsPerPage={12}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default PilotDataTable;
