import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  CardBody,
  Card,
  Container,
  Label,
  Input,
  Col,
  Row,
  FormFeedback,
  CardFooter,
  Button,
  Form,
} from "reactstrap";
import Select from "react-select";
import * as api from "../../../api/global.api";
import Dropzone from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "../../Common/BreadCrumb";
import { Capitalize, phoneRegex } from "../../commons/functions";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../CommonComponent/Loader";
import AddFeatures from "./AddFeatures";
import ImageComponent from "../../Common/ImageComponent";
function AddClients() {
  const editClientStorage: any = sessionStorage.getItem("editClient");
  const navigate = useNavigate();
  const editClient = JSON.parse(editClientStorage);
  const [pageNumber, setPageNumber] = useState<any>();
  const [totalCount, setTotalCount] = useState<any>();

  const [loading, setLoading] = useState<any>(false);
  const [isModal, setIsModal] = useState<any>(false);

  // <--- W.R.To country and state --->
  const [countries, setCountries] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [industries, setIndustries] = useState<any>([]);
  const [clientAdmins, setClientAdmins] = useState<any>([]);
  const [clientUsers, setClientUsers] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  const [selectedIndusrty, setSelectedIndusrty] = useState<any>();
  const [selectedAdmin, setSelectedAdmin] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [industryName, setIndustryName] = useState<any>("");
  const [mode, setMode] = useState<any>("Add Client");
  const [addtoList, setAddtoList] = useState<any>(true);
  const [moduleData, setModuleData] = useState<any>([]);
  const [selectedModule, setSelectedModule] = useState<any>([]);
  const [rows, setRows] = useState<any>(null);

  const formik = useFormik<any>({
    initialValues: {
      clientName: "",
      contactNumber: "",
      email: "",
      country: "",
      state: "",
      address: "",
      industry: "",
      clientLogo: "",
      admin: [],
      isActive: true,
    },
    validationSchema: Yup.object({
      clientName: Yup.string()
        .trim("Please enter valid client name")
        .strict(true)
        .required("required"),
      contactNumber: Yup.string()
        .matches(phoneRegex, "Please enter valid contact number")
        .required("required"),
      email: Yup.string()
        .email("Please enter valid email address")
        .required("required"),
      country: Yup.string().required("required"),
      state: Yup.string().required("required"),
      address: Yup.string().required("required"),
      industry: Yup.string().required("required"),
      admin: Yup.array().min(1, "required"),
    }),
    onSubmit: (values: any) => {
      let moduleData = selectedModule?.map((ele: any) => {
        return {
          module: ele?.moduleName ? ele?.moduleName : "",
          subModule: ele?.subModuleName ? ele?.subModuleName : [],
          features: {
            create: [
              ...ele?.features?.map((e: any) => {
                return { featureName: e.featureName, isSelected: e.isSelected };
              }),
            ],
          },
        };
      });
      let obj = {
        ...values,
        clientModules: { create: moduleData },
      };
      addtoList &&
        selectedIndusrty?.label?.toLowerCase() === "others" &&
        addEnums();
      if (editClient) {
        editClientApi({ ...obj, id: editClient?.id });
      } else {
        addClient(obj);
      }
    },
  });

  const getContries = (url: any) => {
    setLoading(true);
    api.get(url).then((res) => {
      if (res?.status === "Failed") {
        setLoading(false);
        toast.error(res?.errormessage);
      } else {
        let countryObj = res?.map((country: any) => {
          return {
            label: country.name,
            name: country.name,
            countryCode: country.isoCode,
          };
        });
        localStorage.setItem("countries", JSON.stringify(countryObj));
        setCountries(countryObj);
        setLoading(false);
      }
    });
  };

  const getState = (url: any) => {
    setLoading(true);
    api.get(url).then((res) => {
      if (res?.status === "Failed") {
        setLoading(false);
        toast.error(res?.errormessage);
      } else {
        let stateObj = res?.map((state: any) => {
          return {
            label: state.name,
            name: state.name,
          };
        });
        setStates(stateObj);
        setLoading(false);
      }
    });
  };

  const getIndustry = () => {
    setLoading(true);
    api.get("getEnums?enumType=INDUSTRY").then((res) => {
      let data = res.appEnum.map((ele: any) => {
        return { label: ele.enumName, value: ele.enumValue };
      });
      setIndustries([...data, { label: "Others", value: "Others" }]);
      setLoading(false);
    });
  };

  const getAdmins = () => {
    api.get("user/get/Client Admin").then((res) => {
      if (res.status.toLowerCase() === "success") {
        let arr = res?.response?.map((e: any) => {
          return {
            label:
              e.User.firstName + " " + e.User.lastName + " - " + e.roleName,
            value:
              e.User.firstName + " " + e.User.lastName + " - " + e.roleName,
          };
        });
        localStorage.setItem("clientAdmins", arr);
        setClientAdmins(arr);
      }
    });

    api.get("user/get/Client User").then((res) => {
      if (res.status.toLowerCase() === "success") {
        let arr = res?.response?.map((e: any) => {
          return {
            label:
              e.User.firstName + " " + e.User.lastName + " - " + e.roleName,
            value:
              e.User.firstName + " " + e.User.lastName + " - " + e.roleName,
          };
        });
        localStorage.setItem("clientUsers", arr);
        setClientUsers(arr);
      }
    });
  };

  const handleCountry = (country: any) => {
    setSelectedCountry(country);
    getState(`getStateByCountry?countryCode=${country?.countryCode}`);
    formik.setFieldValue("country", country.label + "_" + country?.countryCode);
  };

  const handleState = (state: any) => {
    setSelectedState(state);
    formik.setFieldValue("state", state.label);
  };

  const handleIndustry = (industry: any) => {
    setSelectedIndusrty(industry);
    formik.setFieldValue("industry", industry.label);
  };

  const handleIndustryName = (e: any) => {
    setIndustryName(e.target.value);
    formik.setFieldValue("industry", e.target.value);
  };

  const handleAddToList = (e: any) => {
    setAddtoList(e.target.checked);
  };

  const handleAdmin = (val: any) => {
    setSelectedAdmin(val);
    formik.setFieldValue("admin", [val.value]);
  };

  const handleUser = (val: any) => {
    setSelectedUser(val);
    let data = val?.map((e: any) => e.value);
    formik.setFieldValue("user", data);
  };

  const handleAcceptedFiles = (files: any) => {
    let fs = files[0]?.path.split(".")[1]?.toLowerCase();
    if (fs === "jpeg" || fs === "png" || fs === "jpg") {
      files.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: file.size,
        })
      );

      const formData = new FormData();
      formData.append("file", files[0]);
      setLoading(true);

      api.post("fileUpload", formData).then((res: any) => {
        if (res?.url) {
          formik.setFieldValue("clientLogo", res?.url);
        }
        setLoading(false);
      });
    } else {
      toast.error("Please upload .jpeg or .png or .jpg format file only");
    }
  };

  const getModules = () => {
    setLoading(true);
    api
      .get(`getModules`)
      .then((res) => {
        if (res?.status === "Success") {
          if (editClient) {
            let data = res?.response?.rows?.map((item: any) => {
              let idCheck = editClient?.clientModules.findIndex(
                (ele: any) => ele?.subModule[0] === item?.subModuleName
              );
              if (idCheck === -1) {
                return item;
              } else {
                let features = editClient?.clientModules[idCheck];
                return {
                  ...item,
                  features: features.features,
                };
              }
            });
            setModuleData(data);
          } else {
            setModuleData(res?.response?.rows);
          }
          setTotalCount(res?.response.totalCount);
          setLoading(false);
        } else {
          toast.error(res?.errormessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const addEnums = () => {
    setLoading(true);
    api
      .post("addEnum", {
        enumName: industryName,
        enumType: "INDUSTRY",
        enumValue: industryName,
      })
      .then((res) => {
        if (res?.status?.toLowerCase() === "success") {
          setLoading(false);
        } else {
          toast.error("Unable to add Industry to List");
        }
      });
  };

  const addClient = (val: any) => {
    setLoading(true);
    api.post(`clients/add`, val).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setLoading(false);
        toast.success("Client created successfully");
        navigate("/clients");
      } else {
        setLoading(false);
        toast.error(res.errormessage);
      }
    });
  };

  const editClientApi = (val: any) => {
    setLoading(true);
    api.post(`client/update`, val).then((res) => {
      if (res.status.toLowerCase() === "success") {
        setLoading(false);
        toast.success("Client details updated successfully");
        navigate("/clients");
      } else {
        setLoading(false);
        toast.error(res.errormessage);
      }
    });
  };

  const handleSelectedModule = (val: any) => {
    let idx = selectedModule?.findIndex(
      (ele: any) => ele.subModuleName === val?.subModuleName
    );
    if (idx === -1) {
      setSelectedModule([...selectedModule, val]);
    } else {
      let data = [...selectedModule];
      data.splice(idx, 1);
      setSelectedModule(data);
    }
  };

  const handleAddFeature = (val: any) => {
    setRows(val);
    setIsModal(true);
  };

  const handleUpdateFeature = (val: any) => {
    let data = [...selectedModule];
    let idx = data.findIndex(
      (ele: any) => ele.subModuleName === val.subModuleName
    );
    data[idx] = val;
    setSelectedModule(data);
  };

  useEffect(() => {
    getContries("getCountries");
    getIndustry();
    getModules();
    getAdmins();

    if (editClient) {
      setMode("Edit Client");
      let iso = editClient?.country?.split("_")[1];
      let country = editClient?.country?.split("_")[0];
      getState(`getStateByCountry?countryCode=${iso}`);
      formik.setFieldValue("clientName", editClient?.clientName);
      formik.setFieldValue("contactNumber", editClient?.contactNumber);
      formik.setFieldValue("email", editClient?.email);
      formik.setFieldValue("country", editClient?.country);
      formik.setFieldValue("state", editClient?.state);
      formik.setFieldValue("address", editClient?.address);
      formik.setFieldValue("industry", editClient?.industry);
      formik.setFieldValue("clientLogo", editClient?.clientLogo);
      formik.setFieldValue("admin", editClient?.admin);
      formik.setFieldValue("user", editClient?.user);
      setSelectedCountry({
        label: country,
        value: country,
      });
      setSelectedState({
        label: editClient?.state,
        value: editClient?.state,
      });
      setSelectedIndusrty({
        label: editClient?.industry,
        value: editClient?.industry,
      });
      setSelectedAdmin(
        editClient?.admin.map((e: any) => {
          return { label: e, value: e };
        })
      );
      setSelectedUser(
        editClient?.user.map((e: any) => {
          return { label: e, value: e };
        })
      );
      setSelectedModule(
        editClient?.clientModules?.map((ele: any) => {
          return {
            ...ele,
            moduleName: ele?.module,
            subModuleName: ele.subModule[0],
          };
        })
      );
    }
  }, []);

  return (
    <div className="ptable mt-3">
      <Loader loader={loading} />
      <ToastContainer />
      <BreadCrumb pageTitle={"Clients"} title={mode} pageLink={"/clients"} />
      <Container fluid>
        <Card className="p-3">
          <CardBody>
            <Form>
              <Row>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="basiInput" className="form-label ">
                      Client Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="clientName"
                      name="clientName"
                      placeholder="Enter Client Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.clientName}
                      invalid={
                        formik.touched.clientName && formik.errors.clientName
                          ? true
                          : false
                      }
                    />
                    {formik.touched.clientName && formik.errors.clientName ? (
                      <FormFeedback type="invalid">
                        {formik.errors.clientName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="basiInput" className="form-label ">
                      Contact Number
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="contactNumber"
                      name="contactNumber"
                      placeholder="Enter Contact Number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contactNumber || ""}
                      invalid={
                        formik.touched.contactNumber &&
                        formik.errors.contactNumber
                          ? true
                          : false
                      }
                    />
                    {formik.touched.contactNumber &&
                    formik.errors.contactNumber ? (
                      <FormFeedback type="invalid">
                        {formik.errors.contactNumber}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="basiInput" className="form-label ">
                      Email ID
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Enter Email id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email || ""}
                      invalid={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <FormFeedback type="invalid">
                        {formik.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="labelInput" className="form-label ">
                      Country
                    </Label>
                    <Select
                      value={selectedCountry}
                      onChange={(country) => {
                        handleCountry(country);
                      }}
                      options={countries}
                    />
                  </div>
                </Col>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="labelInput" className="form-label ">
                      State
                    </Label>
                    <Select
                      value={selectedState}
                      onChange={(state) => handleState(state)}
                      options={states}
                    />
                  </div>
                </Col>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="basiInput" className="form-label ">
                      Address
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      placeholder="Enter Address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={Capitalize(formik.values.address) || ""}
                      invalid={
                        formik.touched.address && formik.errors.address
                          ? true
                          : false
                      }
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <FormFeedback type="invalid">
                        {formik.errors.address}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="labelInput" className="form-label ">
                      Industry
                    </Label>
                    <Select
                      value={selectedIndusrty}
                      onChange={(val) => {
                        handleIndustry(val);
                      }}
                      options={industries}
                    />
                  </div>
                </Col>
                {selectedIndusrty?.label === "Others" && (
                  <Col xxl={4} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="basiInput" className="form-label ">
                        Industry Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="industryName"
                        name="industryName"
                        placeholder="Enter Industry Name"
                        value={Capitalize(industryName) || ""}
                        onChange={(e) => handleIndustryName(e)}
                      />
                    </div>
                  </Col>
                )}
                {selectedIndusrty?.label === "Others" && (
                  <Col
                    lg={4}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <div className="mb-3 d-flex align-items-center justify-content-start3p-2">
                      <Input
                        className="form-check-input me-3"
                        type="checkbox"
                        checked={addtoList}
                        onClick={(e) => handleAddToList(e)}
                      />
                      <Label className="form-check-label">Add to List</Label>
                    </div>
                  </Col>
                )}
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="labelInput" className="form-label ">
                      Assign Client Admin
                    </Label>
                    <Select
                      value={selectedAdmin}
                      onChange={(val) => {
                        handleAdmin(val);
                      }}
                      options={clientAdmins}
                      isMulti={false}
                      closeMenuOnSelect={true}
                    />
                  </div>
                </Col>
                <Col xxl={4} lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="labelInput" className="form-label ">
                      Assign User
                    </Label>
                    <Select
                      value={selectedUser}
                      onChange={(val) => {
                        handleUser(val);
                      }}
                      options={clientUsers}
                      isMulti={true}
                      closeMenuOnSelect={false}
                    />
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="mb-3">
                    <Label htmlFor="profilePic" className="form-label">
                      Upload Client Logo
                    </Label>
                    {formik.values.clientLogo === "" && (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                        // accept={"fileType"}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone dz-clickable form-control p-0">
                            <div className="needsclick" {...getRootProps()}>
                              <input {...getInputProps()} accept="images/*" />
                              <div className="mx-4 d-flex align-items-center">
                                <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                                <span className="text-muted">
                                  Upload Profile Photo
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    )}
                    {formik.values.clientLogo !== "" && (
                      <Card>
                        <div className="d-flex">
                          ?
                          <ImageComponent
                            data-dz-thumbnail=""
                            height="70"
                            className="avatar-sm rounded bg-light"
                            alt={"img"}
                            src={formik.values.clientLogo}
                          />
                          <span className="p-3">Uploaded Image</span>
                          <i
                            className="ri-delete-bin-6-line fs-3  pointer red p-2"
                            onClick={() =>
                              formik.setFieldValue("clientLogo", "")
                            }
                          ></i>
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>

            <Row>
              {moduleData.map((item: any, key: any) => {
                return (
                  <Col md={3} lg={3} key={key}>
                    <Card className="mt-4 ">
                      <img
                        className="card-img-top img-fluid"
                        src={item?.coverImage ? item?.coverImage : ""}
                        alt=""
                        style={{ height: "200px" }}
                      />

                      <div
                        className="form-check position-absolute m-2"
                        style={{ right: 0, top: 0, fontSize: "20px" }}
                      >
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          checked={
                            selectedModule?.findIndex(
                              (ele: any) =>
                                ele?.subModuleName === item?.subModuleName
                            ) !== -1
                          }
                          onChange={() => handleSelectedModule(item)}
                        />
                        <Label className="form-check-label"></Label>
                      </div>
                      <CardBody className="position-relative">
                        <span
                          className="position-absolute bg-primary p-2 w-50 fs-13"
                          style={{
                            left: 0,
                            top: "-10px",
                            width: "fit-content",
                          }}
                        >
                          {item?.subModuleName}
                        </span>

                        <Button
                          className=" mb-2 mt-4 mx-5 text-success pointer"
                          style={{
                            textAlign: "center",
                            border: "none",
                            background: "none",
                          }}
                          onClick={() => {
                            handleAddFeature(item);
                          }}
                          disabled={
                            selectedModule?.findIndex(
                              (ele: any) =>
                                ele?.subModuleName === item?.subModuleName
                            ) === -1
                          }
                        >
                          <i className="ri-menu-add-fill f-14 me-2"></i>
                          Select Features
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-end border-0">
            <Button
              color="success"
              outline
              className="me-3 wd-10"
              onClick={() => navigate("/clients")}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="wd-10"
              onClick={() => formik.handleSubmit()}
            >
              {!editClient ? "Save" : "Update"}
            </Button>
          </CardFooter>
        </Card>
      </Container>
      {isModal && (
        <AddFeatures
          isModal={isModal}
          setIsModal={setIsModal}
          data={rows}
          updateModule={handleUpdateFeature}
        />
      )}
    </div>
  );
}

export default AddClients;
