import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientDetails: {},
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    client: (state, action) => {
        state.clientDetails = action.payload;
      },
  },
});

export const { client } = clientSlice.actions;
export default clientSlice.reducer;