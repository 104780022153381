import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Input, Row } from "reactstrap";
import { createLogo } from "../Components/Utils";
import Select from "react-select";
import addComp from "../../assets/svg-new/addComp.svg";
import * as api from "../../api/rest.api";
import * as gapi from "../../api/global.api";
import ImageCard from "./ImageCard";
import Loader from "../../components/Common/Loader";
import VideoCard from "./VideoCard";
import ReportCard from "./ReportCard";
import { ToastContainer, toast } from "react-toastify";
import ComponentCard from "./ComponentCard";
import PaginationComponent from "../Components/PaginationComponent";
import { severityOptions } from "../Components/Constants";
import MessageModal from "../Components/MessageModal";
import ImageComponent from "../../components/Common/ImageComponent";

const TxComponent = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  let currentImage = JSON.parse(sessionStorage.getItem("sme-current-image"));
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [active, setActive] = useState("image");
  const [records, setRecords] = useState([]);
  const [components, setComponents] = useState([]);
  const [filterComp, setFilterComp] = useState([]);
  const [page, setPage] = useState(0);
  const [towerStatus, setTowerStatus] = useState(null);
  const [status, setStatus] = useState(null);
  const [towerReport, setTowerReport] = useState(null);
  const [isSave, setIsSave] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [shared, setShared] = useState([]);
  const [total, setTotal] = useState(0);
  const [p, setP] = useState(0);

  const getComponents = async () => {
    setLoading(true);
    let id = location?.data?.id;
    let url = "groupByTowerComponent/" + id;
    let res = await api.getData(url);
    if (res?.data) {
      setComponents(Object.entries(res?.data));
      let filterData = Object.entries(res?.data).slice(
        page * 10,
        page * 10 + 10
      );
      setFilterComp(filterData);
      setLoading(false);
    } else {
      toast.error(res?.errorMessage);
      setLoading(false);
    }
  };

  const getnextset = async () => {
    setLoading(true);
    let type;
    let id = location?.data?.id;
    active === "sharedimage" ? (type = "image") : (type = active);
    let res = await api.getData(
      `getTowerUploads?type=${type}&towerId=${id}&page=${p + 1}&limit=12`
    );
    setLoading(false);
    console.log(res);
    setRecords(res?.data?.data?.contents);
  };

  useEffect(() => {
    if (p > -1 && total !== 0) {
      let debounce = setTimeout(() => {
        setRecords([]);
        getnextset();
        sessionStorage.removeItem("currentImageIndex");
      }, 500);
      return () => clearTimeout(debounce);
    }
  }, [p]);

  const getRecords = async () => {
    if (active !== null) {
      let type;
      setLoading2(true);
      let id = location?.data?.id;
      active === "sharedimage" ? (type = "image") : (type = active);
      let res = await api.getData(
        `getTowerUploads?type=${type}&towerId=${id}&page=${p + 1}&limit=12`
      );

      console.log(res);
      if (res?.data?.data?.contents) {
        setRecords(res.data.data?.contents);
        setTotal(res?.data?.count);
        setLoading2(false);
      } else {
        setRecords([]);
        setLoading2(false);
      }
      let resp = await api.getData(`getTower?id=${id}`);
      if (resp?.data) {
        setShared(resp?.data?.rows[0]?.shared || []);
      }
    }
  };

  const getThermal = async () => {
    setLoading(true);
    let id = location?.data?.id;
    let res = await api.getData(
      `getThermalReportFiles?fileType=${active}&id=${id}`
    );
    if (res.data) {
      setRecords(res.data);
      setLoading(false);
    } else {
      setRecords([]);
      setLoading(false);
    }
  };

  const handleActive = (val) => {
    setActive(val);
    sessionStorage.setItem("sme-tab", JSON.stringify(val));
  };

  const updateTower = async (val) => {
    setLoading(true);

    let obj = {
      id: location?.data?.id,
      severityStatus: towerStatus?.value,
      towerStatus: val,
    };
    let res = await api.updateData("updateTower", obj);
    if (res?.data) {
      setIsComplete(false);
      setIsSave(false);
      setStatus(res?.data?.towerStatus);
      val === "Completed"
        ? toast.success("Tower component submitted successfully")
        : toast.success("Tower component saved successfully");
      setLoading(false);
    } else {
      toast.error(res?.errorMessage);
      setLoading(false);
    }
  };
console.log(location)
  const generateReport = async () => {
    const {data, corridor} = location;
    const {project} = corridor

    setLoading(true);
    let id = location?.data?.id;
    let mergeUrl = `mergedpdf?towerId=${id}&type=report&clientId=${project?.clientId}&clientName=${project?.client?.clientName}&projectId=${project?.id}&projectName=${project?.projectName}&sourceId=${corridor?.data?.sourcesId}&sourceName=${corridor?.data?.sources?.sourceName}&corridorId=${corridor?.data?.id}&corridorName=${corridor?.data?.corridorName}&towerName=${data?.lineName}&module=Inspection`
    let data1 = await api.getData(`towerReportGeneration/${id}`);
    if (data1?.data?.status === "SUCCESS") {
      let mergePdf = await api.addData(mergeUrl);
      if (mergePdf?.data?.status === "Success") {
        let res = await api.getData(`getTower/${id}`);
        toast.success("Report generated succesfully");
        setTowerReport(res?.data);
        setLoading(false);
      } else {
        toast.error(res?.errorMessage);
        setLoading(false);
      }
    } else {
      toast.error(res?.errorMessage);
      setLoading(false);
    }
  };

  const handleShareImage = (file) => {
    let check = shared?.findIndex((e) => e?.url === file);
    if (check === -1) {
      setShared([...shared, { url: file, fileName: file?.split("/").pop() }]);
    } else {
      let files = shared?.filter((e) => e?.url !== file);
      setShared(files);
    }
  };

  const shareImage = async () => {
    const {data, corridor} = location;
    const {project} = corridor
    console.log("shared..")
    setLoading(true);
    let obj = {
      id: location?.data?.id,
      sharedImages: shared,
      clientId: project?.clientId,
      clientName: project?.client?.clientName,
      projectId: project?.id,
      projectName: project?.projectName,
      module: "Inspection",
      sourceId: corridor?.data?.sourcesId,
      sourceName: corridor?.data?.sources?.sourceName,
      corridorId: corridor?.data?.id,
      corridorName: corridor?.data?.corridorName,
      towerName: data?.lineName
    };
    let res = await api.updateData("updatetower", obj);
    if (res?.data) {
      setLoading(false);
      toast.success("Images shared successfully");
    } else {
      toast.error(res?.errorMessage);
      setLoading(false);
    }
  };

  const checkincludes = (val) => {
    let isPresent = shared?.findIndex((e) => e.url === val);
    if (isPresent > -1) return true;
    else return false;
  };

  const handleInputChange = (e, val) => {
    let idx = shared?.findIndex((e) => e?.url === val);
    let data = [...shared];
    data[idx]["fileName"] = e.target.value;
    setShared(data);
  };

  const getValue = (val) => {
    let obj = shared?.find((e) => e?.url === val);
    return obj?.fileName;
  };

  useEffect(() => {
    if (active === "report") {
      getThermal();
    } else {
      getRecords();
    }
  }, [active]);

  useEffect(() => {
    let value = JSON.parse(sessionStorage.getItem("sme-tab"));
    if (value) {
      setRecords([]);
      setActive(value);
      location?.data?.severityStatus
        ? setTowerStatus({
            label: location?.data?.severityStatus,
            value: location?.data?.severityStatus,
          })
        : setTowerStatus(null);
      setStatus(location?.data?.towerStatus);
      setTowerReport(location?.data);
    } else {
      setRecords([]);
      setActive("image");
      location?.data?.severityStatus
        ? setTowerStatus({
            label: location?.data?.severityStatus,
            value: location?.data?.severityStatus,
          })
        : setTowerStatus(null);
      setStatus(location?.data?.towerStatus);
      setTowerReport(location?.data);
    }
    setShared(location?.data?.shared || []);
    getComponents();
  }, []);

  useEffect(() => {
    let filterData = components.slice(page * 10, page * 10 + 10);
    setFilterComp(filterData);
  }, [page]);

  return (
    <div className="content">
      {(loading || loading2) && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <ToastContainer />
      <Row className="mb-3 mx-3">
        <Col>
          <div className="client-logo-sme">
            {location?.corridor?.project?.client?.clientLogo ? (
              <img src={location?.corridor?.project?.client?.clientLogo} />
            ) : (
              <span>
                {createLogo(location?.corridor?.project?.client?.clientName)}
              </span>
            )}
          </div>
          <div className="client-logo-sme-name">
            {location?.corridor?.data?.corridorName}
          </div>
        </Col>
        <Col className="text-end me-2 mt-2">
          <span
            className="bread-last"
            onClick={() => navigate("/sme-dashboard")}
          >
            Dashboard
          </span>
          {" > "}
          <span
            className="bread-last "
            onClick={() =>
              navigate("/sme-corridor", { state: location?.corridor?.project })
            }
          >
            {location?.corridor?.project?.projectName}
          </span>
          {" > "}

          <span
            className="bread-last"
            onClick={() =>
              navigate("/sme-tower", { state: location?.corridor })
            }
          >
            {location?.corridor?.data?.corridorName}
          </span>
          {" > "}
          <span className="fs-14">
            {location?.data?.towerNo} {location?.data?.towerType}
          </span>
        </Col>
      </Row>
      <Row className="mx-3 mb-0 row-height">
        <Col>
          <Card className="brd-none p-3 data-left-1">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <div className="fs-16 me-3 color-grayl">
                  Tower Number :{" "}
                  <span className="color-lblue">
                    {location?.data?.towerNo} {location?.data?.towerType}
                  </span>
                </div>
                <div className="fs-16 color-grayl">
                  Line Name :{" "}
                  <span className="color-lblue">
                    {location?.data?.lineName}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="color-grayl me-2">Tower Status</span>
                <Select
                  className="w-sm"
                  options={severityOptions}
                  value={towerStatus}
                  isDisabled={status === "Completed"}
                  onChange={(val) => setTowerStatus(val)}
                />
              </div>
            </div>
          </Card>
          <Card className="brd-none data-left mb-0">
            {components.length > 0 ? (
              <>
                {filterComp.map((item, id) => (
                  <ComponentCard
                    data={item}
                    getComponents={getComponents}
                    setLoading={setLoading}
                    status={status}
                    did={id}
                  />
                ))}
              </>
            ) : (
              <div>
                <div className="add-comp">
                  <img src={addComp} />
                </div>
                <span className="add-comp-span">
                  Please select the image to create component
                </span>
              </div>
            )}
          </Card>
        </Col>
        <Col>
          <Card className="brd-none data-right p-3 mb-0">
            <Row className="mb-3">
              <div className="d-flex color-grayl ms-3">
                <div
                  className={`${
                    active === "image" && "data-right-active"
                  } me-5 pointer`}
                  onClick={() => handleActive("image")}
                >
                  Images
                </div>
                <div
                  className={`${
                    active === "video" && "data-right-active"
                  } me-5 pointer`}
                  onClick={() => handleActive("video")}
                >
                  Videos
                </div>
                <div
                  className={`${
                    active === "screenshot" && "data-right-active"
                  } me-5 pointer`}
                  onClick={() => handleActive("screenshot")}
                >
                  Screenshots
                </div>
                <div
                  className={`${
                    active === "report" && "data-right-active"
                  } me-5 pointer`}
                  onClick={() => handleActive("report")}
                >
                  Reports
                </div>
                <div
                  className={`${
                    active === "sharedimage" && "data-right-active"
                  } me-5 pointer`}
                  onClick={() => handleActive("sharedimage")}
                >
                  Shared Images
                </div>
              </div>
            </Row>
            {records?.length > 0 && active !== "report" ? (
              <Row className="m-3">
                {records?.map((ele, id) => {
                  return (
                    <Col md={4} key={id}>
                      {(active === "image" || active == "screenshot") && (
                        <ImageCard
                          data={ele}
                          records={records}
                          status={status}
                          currentImage={currentImage}
                          getComponents={getComponents}
                          index={id}
                        />
                      )}
                      {active === "video" && (
                        <VideoCard
                          data={ele}
                          records={records}
                          status={status}
                        />
                      )}
                      {active === "sharedimage" && (
                        <Card className="img-card-div">
                          <Input
                            type="checkbox"
                            className="img-card-full1"
                            checked={checkincludes(ele?.defectFile)}
                            onClick={() => handleShareImage(ele?.defectFile)}
                          />
                          <ImageComponent
                            className="pointer"
                            src={ele.defectFile}
                          />
                          {checkincludes(ele?.defectFile) ? (
                            <Input
                              type="text"
                              value={getValue(ele?.defectFile) || ""}
                              onChange={(e) => {
                                handleInputChange(e, ele?.defectFile);
                              }}
                            />
                          ) : (
                            <span className="p-2 text-center">
                              {ele?.fileName}
                            </span>
                          )}
                        </Card>
                      )}
                    </Col>
                  );
                })}
                {active === "sharedimage" && (
                  <Col xs={12} className="text-center">
                    <Button
                      className="w-sm me-2 br-1 bg-primary"
                      disabled={shared?.length === 0}
                      onClick={shareImage}
                    >
                      Share
                    </Button>
                  </Col>
                )}
                <Card className="border-none">
                  <PaginationComponent
                    page={p}
                    limit={12}
                    total={total}
                    onPageChange={(val) => {
                      console.log(val);
                      setP(val);
                    }}
                  />
                </Card>
              </Row>
            ) : active === "report" ? (
              <Row>
                <ReportCard
                  records={records}
                  getThermal={getThermal}
                  setLoading={setLoading}
                  status={status}
                  towerReport={towerReport}
                />
              </Row>
            ) : (
              <Row className="m-3">
                {active === "screenshot" && (
                  <Col className="text-center color-grayl">
                    No screenshot avilable fot tower
                  </Col>
                )}
                {active === "image" && (
                  <Col className="text-center color-grayl">
                    No image avilable fot tower
                  </Col>
                )}
                {active === "video" && (
                  <Col className="text-center color-grayl">
                    No video avilable fot tower
                  </Col>
                )}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      <Row className="mb-2 mx-3 mt-0 p-2">
        {components?.length > 0 && (
          <Col>
            <Card className="mb-4 p-2 py-3">
              <PaginationComponent
                page={page}
                limit={10}
                total={components?.length}
                onPageChange={(val) => setPage(val)}
              />
            </Card>
          </Col>
        )}
        <Col>
          <Card className="mb-4 p-2 py-3">
            <div className="text-end">
              {status !== "Completed" && (
                <Button
                  className="w-sm me-2 brd-none underline"
                  outline
                  color="success"
                  disabled={towerStatus === null || components?.length === 0}
                  onClick={() => setIsSave(true)}
                >
                  Save as Draft
                </Button>
              )}
              <Button
                className="w-sm me-2 br-1 bg-primary"
                disabled={
                  towerStatus === null ||
                  components?.length === 0 ||
                  status === "In Progress"
                }
                onClick={generateReport}
              >
                Generate Report
              </Button>
              {status !== "Completed" && (
                <Button
                  className="w-sm me-2 br-1"
                  color="success"
                  disabled={towerStatus === null || components?.length === 0}
                  onClick={() => setIsComplete(true)}
                >
                  Final Submit
                </Button>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      {isComplete && (
        <MessageModal
          isOpen={isComplete}
          onCloseClick={() => setIsComplete(false)}
          onConfirmClick={() => updateTower("Completed")}
          message="Do you want to final submit this tower records?"
        />
      )}
      {isSave && (
        <MessageModal
          isOpen={isSave}
          onCloseClick={() => setIsSave(false)}
          onConfirmClick={() => updateTower("Save As Draft")}
          message="Do you want to share this report to Client?"
        />
      )}
    </div>
  );
};

export default TxComponent;
