import React, { useEffect, useState } from "react";
import ClientHeader from "./ClientHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import * as api from "../../api/rest.api";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import ProjectCardTx from "../ClientTx/ProjectCard";
import ProjectCardOil from "../ClientOil/ProjectCard";
import ProjectCardSurvey from "../ClientSurvey/ProjectCard";
import { projectsArrTxn } from "../../store/projectsArrayReducer";
import { Valid } from "../../store/reducer";

const ClientDashboard = () => {
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRecords = async () => {
    setLoading(true);
    let url = `projects/get?module=${user.selectedModule}&roleCode=${user.selectedRole}&userID=${user.id}`;
    let res = await api.getData(url);
    if (res?.data) {
      let features = res?.data?.featuresData?.map((ele) => ele?.featureName);
      dispatch(Valid({...user, features}))
      setRecords(res?.data?.projectData);
      dispatch(projectsArrTxn(res?.data?.projectData));
      setLoading(false);
    } else {
      setRecords([]);
      toast.error(res.errorMessage);
      setLoading(false);
    }
  };
  useEffect(() => {
    getRecords();
  }, []);
  return (
    <React.Fragment>
      <ClientHeader />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <div className="content">
        <Row className="m-3">
          <div className="fw-500 fs-20">Projects</div>
        </Row>
        {records?.length > 0 ? (
          <Row className="mx-3">
            {records?.map((item, id) => (
              <Col md={4} key={id}>
                {item?.totalCorridors > 0 ? (
                  <ProjectCardTx data={item} />
                ) : item?.totalAssets > 0 ? (
                  <ProjectCardOil data={item} />
                ) : (
                  <ProjectCardSurvey data={item} />
                )}
              </Col>
            ))}
          </Row>
        ) : (
          <div className="text-center">No Projects avilable to display </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ClientDashboard;
