import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";
import AddFeatureModel from "../admin/Module/AddFeatureModal";
import { formatDate } from "../commons/functions";
import { Tooltip } from "primereact/tooltip";

const ClientDataTable = ({
  data,
  handleEdit,
  handleActive,
  handleProject,
  handleDelete,
}) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: <span className="font-weight-bold column-title">Client ID</span>,
      selector: (row) => row.clientRef,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Client Name</span>,
      selector: (row) => row.clientName,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold column-title">Contact Number</span>
      ),
      selector: (row) => row.contactNumber,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Email ID</span>,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Client Admin</span>,
      selector: (row) => {
        const singleAdmin = row?.admin.map((single) => single);
        return singleAdmin.join(",");
      },
      sortable: true,
    },
    // {
    //   name: <span className="font-weight-bold column-title">Project Manager</span>,
    //   selector: (row) => {
    //     return row?.projectManagers?.map((manager) => manager);
    //   },
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold column-title">Industry</span>,
      selector: (row) => row.industry,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">AWS Storage</span>,
      selector: (row) => row.awsStorage,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold column-title">
          GEO Server Storage
        </span>
      ),
      selector: (row) => row.geoServerStorage,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Created Date</span>,
      selector: (row) => {
        return formatDate(row.createdAt);
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold column-title">Actions</span>,
      sortable: true,
      grow: 1.5,

      cell: (row) => {
        return (
          <span>
            <div className="flex-shrik-0 form-check form-switch form-switch-right form-switch-md pt-1 me-1">
              <Input
                className="form-check-input code-switcher "
                type="checkbox"
                checked={row.isActive}
                onChange={(e) => handleActive(e, row)}
              />
            </div>
            <i
              className="bx bx-edit fs-4 me-2 pointer"
              style={{ color: "green" }}
              onClick={() => handleEdit(row)}
            ></i>
            {/* <i
              className="ri-delete-bin-6-line fs-4 me-2 pointer"
              style={{ color: "red" }}
              onClick={() => handleDelete(row)}
            ></i> */}

            <Tooltip target=".project-icon" />
            <span
              className="project-icon"
              data-pr-tooltip={"Project"}
              data-pr-position="top"
            >
              <i
                class="bx bxs-file-plus fs-4 pointer me-3"
                style={{ color: "green" }}
                onClick={() => {
                  // sessionStorage.setItem(
                  //   "prevRoute",
                  //   JSON.stringify({
                  //     name: row.clientName,
                  //     link: "/clients",
                  //   })
                  // );
                  // navigate(`/delivers/${row.id}`, { state: row });
                  handleProject(row);
                }}
              ></i>
            </span>
            <i
              className="ri-delete-bin-6-line fs-4 me-3 pointer"
              style={{ color: "red" }}
              onClick={() => handleDelete(row)}
            ></i>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <DataTable columns={columns} data={data} striped highlightOnHover />
    </>
  );
};

export default ClientDataTable;
