import React, { useEffect, useRef, useState } from "react";
import DeleteModal from "../../components/Common/DeleteModal";
import Loader from "../../components/Common/Loader";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { getDate } from "../Components/Utils";
import { useSelector } from "react-redux";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import videoImg from "../../assets/svg-new/video.svg";
import Draggle from "react-draggable";

const Comments = (props) => {
  const user = useSelector((state) => state.user);
  const fullName = user?.firstName + " " + user?.lastName;
  const commentsContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [dropdownOpen2, setDropdownOpen2] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [images, setImages] = useState([]);
  const [comment, setComment] = useState("");
  const [row, setRow] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [replydata, setReplydata] = useState(null);
  const [showReply, setShowReply] = useState([]);
  const [showAttachment, setShowAttachment] = useState([]);
  const [show, setShow] = useState("");
  const [isLightbox, setIsLightbox] = useState(false);
  const [lightboxData, setLightboxData] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [props.commentData]);

  const toggle = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = !newDropdownOpen[index];
      return newDropdownOpen;
    });
  };

  const toggle2 = (index, id) => {
    dropdownOpen2 === `${index}_${id}`
      ? setDropdownOpen2(null)
      : setDropdownOpen2(`${index}_${id}`);
  };

  const onClose = () => {
    props?.setIsMedia(true);
    props?.setIsComment(false);
  };

  const handleFiles = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 1) {
      toast.warn("Please upload one file only");
    } else {
      let ext = files[0]?.name?.split(".").pop();
      if (ext?.toLowerCase() === "mp4") {
        setFileList(files);
      } else if (
        ext?.toLowerCase() === "png" ||
        ext?.toLowerCase() === "jpeg" ||
        ext?.toLowerCase() === "jpg"
      ) {
        const imageUrls = files.map((file) => URL.createObjectURL(file));
        setFileList(files);
        setImages((prevImages) => [...prevImages, ...imageUrls]);
      } else {
        toast.warn("Please upload image or video file");
      }
    }
  };

  const removeFile = (file) => {
    setFileList(fileList?.filter((e) => e?.name !== file?.name));
  };

  const saveComment = () => {
    setLoading(true);
    const formData = new FormData();
    let obj = {
      comment: comment,
      createdBy: fullName,
      userLogo: user?.imageUrl,
      mediaToolId: props?.commentData?.id,
    };

    fileList.forEach((file, index) => {
      formData.append(`files`, file);
      formData.append("clientId", props?.project?.clientId),
      formData.append("clientName", props?.project?.client.clientName),
      formData.append("projectId", props?.project?.id),
      formData.append("projectName", props?.project?.projectName),
      formData.append("surveyId", props?.survey.surveyId),
      formData.append("surveyName", props?.survey.surveyName),
      formData.append("module", "Survey" )
    });

    if (fileList?.length > 0) {
      api.post("upload/mediafiles", formData).then((res) => {
        if (res?.status === "Success") {
          obj["fileUrls"] = res?.urls;
          api.post("add/comment", obj).then((res) => {
            if (res?.status === "Success") {
              toast.success("Comment added successfully");
              props.setIsComment(false);
              props?.setIsMedia(true);
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
          toast.error(res?.errorMessage);
        }
      });
    } else {
      api.post("add/comment", obj).then((res) => {
        if (res?.status === "Success") {
          toast.success("Comment added successfully");
          props.setIsComment(false);
          props?.setIsMedia(true);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res?.errorMessage);
        }
      });
    }
  };

  const deleteComment = (val) => {
    setRow(val);
    setIsDeleteModal(true);
  };

  const deleteCommentConfirm = () => {
    setLoading(true);
    if (row?.fileUrls?.length !== 0) {
      api.post("delete/mediaFile", { fileUrl: row?.fileUrls });
    }
    api.deleteApi(`delete/comment/${row?.id}`).then((res) => {
      if (res?.status === "Success") {
        toast.success("Comment deleted successfully");
        setLoading(false);
        props.setIsComment(false);
        props?.setIsMedia(true);
      }
    });
  };

  const handleReply = (val) => {
    setReplydata(val);
    setIsReply(true);
  };

  const saveReply = () => {
    setLoading(true);
    let obj = {
      comment: comment,
      createdBy: fullName,
      userLogo: user?.imageUrl,
      mediaToolId: replydata?.mediaToolId,
      parentCommentId: replydata?.id,
    };
    api.post(`add/reply`, obj).then((res) => {
      if (res?.status === "Success") {
        toast.success("Replied to comment successfully");
        setLoading(false);
        props?.setIsComment(false);
        props?.setIsMedia(true);
      }
    });
  };

  const handleShowReply = (id) => {
    setShow("reply");
    let filter = showReply?.filter((ele) => ele !== id);
    if (filter?.length < showReply?.length) {
      setShowReply(filter);
    } else {
      setShowReply([...showReply, id]);
    }
  };

  const handleAttachments = (id) => {
    setShow("attachment");
    let filter = showAttachment?.filter((ele) => ele !== id);
    if (filter?.length < showAttachment?.length) {
      setShowAttachment(filter);
    } else {
      setShowAttachment([...showAttachment, id]);
    }
  };

  const handleShowAttachments = (item) => {
    let ext = item?.split(".")?.pop()?.toLowerCase();
    if (ext !== "mp4") {
      setLightboxData(item);
      setIsLightbox(true);
    } else {
      setLightboxData(item);
      setIsVideo(true);
    }
  };
  return (
    <React.Fragment>
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}

      {isLightbox && (
        <Lightbox
          mainSrc={lightboxData}
          imageCaption={lightboxData?.split("_").pop()}
          onCloseRequest={() => setIsLightbox(false)}
        />
      )}

      <div className="siteForm">
        {isDeleteModal && (
          <DeleteModal
            show={isDeleteModal}
            onCloseClick={() => setIsDeleteModal(false)}
            onDeleteClick={deleteCommentConfirm}
          />
        )}

        <div className="site-header">
          <div className="fs-16 fw-500">Commnents</div>
          <i className="bx bx-x fs-2" onClick={onClose}></i>
        </div>
        <div
          className="site-body"
          style={{ height: "67vh" }}
          ref={commentsContainerRef}
        >
          {props?.commentData?.data?.length > 0 ? (
            <div>
              {props?.commentData?.data?.map((comment, id) => (
                <Row key={id} className="mb-2">
                  <Col md={2}>
                    <img
                      src={comment?.userLogo}
                      height={"60px"}
                      width={"60px"}
                      style={{ borderRadius: "50%" }}
                    />
                  </Col>
                  <Col md={10}>
                    <div style={{ background: "#eee" }} className="mb-3 p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="fw-500 fs-18">{comment?.createdBy}</div>
                        <div>
                          <Dropdown
                            isOpen={dropdownOpen[id] || false}
                            toggle={() => toggle(id)}
                            {...props}
                          >
                            <DropdownToggle
                              style={{ background: "none", border: "none" }}
                            >
                              <i className="bx bx-dots-horizontal-rounded fs-3 pointer text-dark"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => handleReply(comment)}
                              >
                                Reply
                              </DropdownItem>
                              {comment?.createdBy === fullName && (
                                <DropdownItem
                                  onClick={() => deleteComment(comment)}
                                >
                                  Delete
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="mb-2">{getDate(comment?.createdAt)}</div>
                      <div className="p-2">{comment?.comment}</div>
                      <div className="p-2 d-flex justify-content-between align-items-center">
                        {comment?.replies?.length > 0 && (
                          <div
                            className="text-primary pointer"
                            onClick={() => handleShowReply(id)}
                          >
                            {comment?.replies?.length} Replies
                          </div>
                        )}
                        {comment?.fileUrls?.length > 0 && (
                          <div
                            className="text-primary pointer"
                            onClick={() => handleAttachments(id)}
                          >
                            {comment?.fileUrls?.length} Attachments
                          </div>
                        )}
                      </div>
                    </div>
                    {comment?.replies?.length > 0 &&
                      showReply.includes(id) &&
                      show === "reply" && (
                        <div>
                          {comment?.replies?.map((item, idx) => (
                            <Row>
                              <Col md={2}>
                                <img
                                  src={item?.userLogo}
                                  height={"40px"}
                                  width={"40px"}
                                  style={{ borderRadius: "50%" }}
                                />
                              </Col>
                              <Col md={10}>
                                <div
                                  style={{ background: "#eee" }}
                                  className="mb-3 p-2"
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="fw-500 fs-18">
                                      {item?.createdBy}
                                    </div>
                                    <div>
                                      <Dropdown
                                        isOpen={
                                          dropdownOpen2 === `${id}_${idx}`
                                        }
                                        toggle={() => toggle2(id, idx)}
                                        {...props}
                                      >
                                        <DropdownToggle
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                        >
                                          <i className="bx bx-dots-horizontal-rounded fs-3 pointer text-dark"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {/* <DropdownItem
                                        onClick={() => handleReply(comment)}
                                      >
                                        Reply
                                      </DropdownItem> */}
                                          {item?.createdBy === fullName && (
                                            <DropdownItem
                                              onClick={() =>
                                                deleteComment(item)
                                              }
                                            >
                                              Delete
                                            </DropdownItem>
                                          )}
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    {getDate(item?.createdAt)}
                                  </div>
                                  <div className="p-2">{item?.comment}</div>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      )}

                    {comment?.fileUrls?.length > 0 &&
                      showAttachment.includes(id) &&
                      show === "attachment" && (
                        <div>
                          {comment?.fileUrls?.map((item, idx) => (
                            <Row key={idx} className="mb-2">
                              <Col
                                md={2}
                                className="pointer"
                                onClick={() => handleShowAttachments(item)}
                              >
                                <img
                                  src={
                                    item?.split(".").pop()?.toLowerCase() !==
                                    "mp4"
                                      ? item
                                      : videoImg
                                  }
                                  className="object-fit-cover"
                                />
                              </Col>
                              <Col md={10}>{item?.split("_")?.pop()}</Col>
                            </Row>
                          ))}
                        </div>
                      )}
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            <div className="p-3 text-center">No Comment Found</div>
          )}

          {fileList?.length > 0 && (
            <div className="uploaded-img p-2">
              <div>
                {fileList?.map((e, id) => {
                  return (
                    <div
                      key={id}
                      className="mb-2 mx-3 d-flex justify-content-between p-2"
                      style={{ border: "1px solid #eee" }}
                    >
                      <div>
                        <img
                          src={images[id] || videoImg}
                          alt=""
                          width="50px"
                          height="50px"
                          className="me-3"
                        />
                        <span>{e?.name}</span>
                      </div>

                      <div className="text-end">
                        <i
                          className="bx bx-x fs-2 text-danger pointer"
                          onClick={() => removeFile(e)}
                        ></i>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="site-footer px-1">
          {!isReply ? (
            <InputGroup>
              <textarea
                rows="5"
                className="form-control"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <InputGroupText className="text-center">
                <label htmlFor="fileinput" className="mb-0">
                  <i className="bx bx-link-alt fs-3 pointer"></i>
                </label>
                <input
                  id="fileinput"
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFiles}
                />
                <div className="text-primary">
                  {fileList?.length > 0 && fileList?.length}
                </div>
              </InputGroupText>
              <InputGroupText>
                <Button
                  style={{ background: "none", border: "none" }}
                  disabled={!comment && fileList?.length === 0}
                  onClick={saveComment}
                >
                  <i className="bx bxs-send fs-3 text-dark"></i>
                </Button>
              </InputGroupText>
            </InputGroup>
          ) : (
            <>
              <Label className="fw-500">
                Reply to {replydata?.createdBy} :
              </Label>

              <InputGroup>
                <textarea
                  className="form-control"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <InputGroupText>
                  <Button
                    style={{ background: "none", border: "none" }}
                    disabled={!comment}
                    onClick={saveReply}
                  >
                    <i className="bx bxs-send fs-3 text-dark"></i>
                  </Button>
                </InputGroupText>
              </InputGroup>
            </>
          )}
        </div>
      </div>

      {isVideo && (
        <Draggle>
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "20%",
              zIndex: 201,
              height: "400px",
              width: "800px",
            }}
          >
            <div className="video-pos">
              <i
                className="bx bx-x fs-2 pointer pos-end"
                onClick={() => setIsVideo(false)}
              ></i>
              <video width="100%" height="100%" controls>
                <source src={lightboxData} type="video/mp4" />
              </video>
            </div>
          </div>
        </Draggle>
      )}
    </React.Fragment>
  );
};

export default Comments;
