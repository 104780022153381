import React, { useEffect, useState } from "react";
import DeleteModal from "../../components/Common/DeleteModal";
import Loader from "../../components/Common/Loader";
import { Button, Card, Col, Input, Row } from "reactstrap";
import Select from "react-select";
import surveyvids from "../../assets/svg-new/surveyvids.png";
import compareimg from "../../assets/svg-new/compare.svg";
import { useSelector } from "react-redux";
import { getDate, getDateOnly } from "../Components/Utils";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";

const MediaPanal = (props) => {
  const email = useSelector((state) => state.user.email);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Video");
  const [adminUploads, setAdminUploads] = useState([]);
  const [clientUploads, setClientUploads] = useState([]);
  const [clientImages, setClientImages] = useState([]);
  const [multiMedia, setMultiMedia] = useState([]);
  const [locOptions, setLocOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [loc, setLoc] = useState(null);
  const [date, setDate] = useState(null);
  const [isLightbox, setIsLightbox] = useState(false);
  const [lightboxData, setlightboxData] = useState();
  const [filterData, setFilterData] = useState([]);

  useEffect(async () => {
    let typ = JSON.parse(sessionStorage.getItem("setclientmediatype"));
    !!typ && setType(typ);
    setLoading(true);
    let arr = await props?.getMedia();
    setAdminUploads(arr);

    let alldata = await props?.getUploadedMedia();
    let imgs = alldata?.filter((ele) => ele.fileType === "image");
    let vids = alldata?.filter((ele) => ele.fileType === "video");
    setClientUploads(vids);
    setClientImages(imgs);
    setLoading(false);
  }, [props?.isMedia]);

  useEffect(() => {
    if (type === "File") {
      setFilterData(adminUploads?.data);
      setDateOptions(adminUploads?.dateOpt);
      setLocOptions(adminUploads?.locOpt);
      setDate(null);
      setLoc(null);
      setMultiMedia([]);
      props?.getCoordArray([]);
    } else if (type === "Video") {
      setFilterData(clientUploads);
      let locs = clientUploads?.map((e) => ({
        label: e.location,
        value: e.location,
      }));
      let dats = clientUploads?.map((e) => ({
        label: getDateOnly(e.updatedAt),
        value: e.updatedAt,
      }));
      let coordss = clientUploads?.map((ele) => [
        Number(ele?.longitude),
        Number(ele?.latitude),
      ]);
      props?.getCoordArray(coordss);
      setDateOptions(dats);
      setLocOptions(locs);
      setDate(null);
      setLoc(null);
      setMultiMedia([]);
    } else {
      setFilterData(clientImages);
      let locs = clientImages?.map((e) => ({
        label: e.location,
        value: e.location,
      }));
      let dats = clientImages?.map((e) => ({
        label: getDateOnly(e.updatedAt),
        value: e.updatedAt,
      }));
      setDateOptions(dats);
      setLocOptions(locs);
      setDate(null);
      setLoc(null);
      let coordss = clientImages?.map((ele) => [
        Number(ele?.longitude),
        Number(ele?.latitude),
      ]);
      props?.getCoordArray(coordss);
      setMultiMedia([]);
    }
  }, [type, adminUploads, clientUploads, clientImages]);

  const handleClose = () => {
    props?.setIsMedia(false);
    props?.getCoordArray([]);
  };

  const deleteConfirm = () => {
    setLoading(true);
    api
      .post("delete/mediaFile", { fileUrl: deleteData?.fileLink })
      .then((res) => {
        if (res.status === "Success") {
          api
            .deleteApi(`delete/media/${deleteData?.id}`)
            .then(async (res) => {
              if (res?.status === "Success") {
                let alldata = await props?.getUploadedMedia();
                let imgs = alldata?.filter((ele) => ele.fileType === "image");
                let vids = alldata?.filter((ele) => ele.fileType === "video");
                setClientUploads(vids);
                setClientImages(imgs);
                setLoading(false);
                toast.success("Media deleted successfully");
                setIsDeleteModal(false);
                setDeleteData(null);
              } else {
                setLoading(false);
                toast.error(res?.errorMessage);
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        } else {
          toast.error(res?.errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleScreenshots = (data) => {
    props?.setIsScreenshot(true);
    props?.setIsMedia(false);
    props?.setScreenshotData(data);
  };

  const handleMultiClick = (val) => {
    if (multiMedia?.length < 2) {
      setMultiMedia([...multiMedia, val]);
    }
  };

  const handleRemoveMedia = (val) => {
    let filteredMedia = multiMedia?.filter(
      (ele) => ele?.fileName !== val.fileName
    );
    setMultiMedia(filteredMedia);
  };

  const handleVideoClick = (val) => {
    props?.onVideoClick(val);
    props?.setIsMedia(false);
  };

  const uploadMedia = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          props.setCoord({
            long: position.coords.longitude,
            lat: position.coords.latitude,
          });
        },
        (error) => {}
      );
    }
    props.setUploadData(null);
    props?.setIsUpload(true);
    props?.setIsMedia(false);
    props.getCoordArray([]);
  };

  const tabChange = (val) => {
    setType(val);
    sessionStorage.setItem("setclientmediatype", JSON.stringify(val));
  };

  const handleLocChange = (val) => {
    setLoc(val);
    let data;
    type === "File"
      ? (data = adminUploads?.data)
      : type === "Video"
      ? (data = clientUploads)
      : (data = clientImages);
    if (!date && val) {
      let final = data?.filter((ele) => ele?.location === val?.value);
      let opt = final?.map((ele) => ({
        label: getDateOnly(ele.updatedAt),
        value: ele?.updatedAt,
      }));
      setFilterData(final);
      setDateOptions(opt);
    } else if (val && date) {
      let final = data?.filter(
        (ele) => ele?.location === val?.value && ele?.updatedAt === date?.value
      );
      setFilterData(final);
    } else if (!val && date) {
      let final = data?.filter((ele) => ele?.updatedAt === date?.value);
      setFilterData(final);
      let opt = data?.map((ele) => ({
        label: getDateOnly(ele.updatedAt),
        value: ele?.updatedAt,
      }));
      setDateOptions(opt);
    } else {
      let opt = data?.map((ele) => ({
        label: getDateOnly(ele.updatedAt),
        value: ele?.updatedAt,
      }));
      setFilterData(data);
      setDateOptions(opt);
    }
  };

  const handleDateChange = (val) => {
    setDate(val);
    let data;
    type === "File"
      ? (data = adminUploads?.data)
      : type === "Video"
      ? (data = clientUploads)
      : (data = clientImages);
    if (!loc && val) {
      let final = data?.filter((ele) => ele?.updatedAt === val?.value);
      let opt = final?.map((ele) => ({
        label: ele.location,
        value: ele?.location,
      }));
      setFilterData(final);
      setLocOptions(opt);
    } else if (val && loc) {
      let final = data?.filter(
        (ele) => ele?.location === loc?.value && ele?.updatedAt === val?.value
      );
      setFilterData(final);
    } else if (!val && loc) {
      let final = data?.filter((ele) => ele?.location === loc?.value);
      setFilterData(final);
      let opt = data?.map((ele) => ({
        label: ele?.location,
        value: ele?.location,
      }));
      setLocOptions(opt);
    } else {
      let opt = data?.map((ele) => ({
        label: ele.location,
        value: ele?.location,
      }));
      setFilterData(data);
      setLocOptions(opt);
    }
  };

  const handleComment = (val) => {
    props?.setIsComment(true);
    props.setIsMedia(false);
    props?.setCommentData({ id: val?.id, data: val?.comments });
  };

  const handleCompare = () => {
    if (multiMedia[0]?.fileType === "image") {
      props?.setCompareData({
        fileType: "image",
        urls: [multiMedia[0]?.fileLink, multiMedia[1]?.fileLink],
        fileNames: [multiMedia[0]?.fileName, multiMedia[1]?.fileName],
      });
    } else if (multiMedia[0]?.fileType === "video") {
      props?.setCompareData({
        fileType: "video",
        urls: [multiMedia[0]?.fileLink, multiMedia[1]?.fileLink],
        fileNames: [multiMedia[0]?.fileName, multiMedia[1]?.fileName],
      });
    } else {
      props?.setCompareData({
        fileType: "video",
        urls: [multiMedia[0]?.videolink, multiMedia[1]?.videolink],
        fileNames: [multiMedia[0]?.fileName, multiMedia[1]?.fileName],
      });
    }
    props?.setIsCompare(true);
  };

  const handleDelete = (val) => {
    setIsDeleteModal(true);
    setDeleteData(val);
  };

  const handleEdit = (val) => {
    props.setIsUpload(true);
    props.setIsMedia(false);
    props.setUploadData(val);
    props.getCorrd([+val?.longitude, +val?.latitude]);
    props.getCoordArray([]);
  };

  return (
    <>
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}

      <div className="siteForm">
        {isDeleteModal && (
          <DeleteModal
            show={isDeleteModal}
            onCloseClick={() => setIsDeleteModal(false)}
            onDeleteClick={deleteConfirm}
          />
        )}

        <div className="site-header">
          <div className="fs-16 fw-500">Media</div>
          <i className="bx bx-x fs-2" onClick={handleClose}></i>
        </div>
        <div className="pt-2 px-4">
          <div className="w-100 d-flex mb-3">
            <Select
              className="w-6 me-5"
              placeholder="Location"
              options={locOptions}
              value={loc}
              onChange={(val) => handleLocChange(val)}
              isClearable={true}
            />
            <Select
              className="w-6"
              placeholder="Select Date"
              options={dateOptions}
              value={date}
              onChange={(val) => handleDateChange(val)}
              isClearable={true}
            />
          </div>
          <div className="d-flex align-item-center justify-content-end mb-1">
            <i
              className="bx bx-folder fs-2 pointer p-1"
              onClick={() => tabChange("File")}
              style={{
                borderRadius: "4px",
                background: type === "File" ? "#0B57B0" : "",
                color: type === "File" ? "#fff" : "#000",
              }}
            ></i>
            <i
              className="bx bx-video fs-2 pointer p-1"
              onClick={() => tabChange("Video")}
              style={{
                borderRadius: "4px",
                background: type === "Video" ? "#0B57B0" : "",
                color: type === "Video" ? "#fff" : "#000",
              }}
            ></i>
            <i
              className="bx bx-image-alt fs-2 pointer p-1"
              onClick={() => tabChange("Image")}
              style={{
                borderRadius: "4px",
                background: type === "Image" ? "#0B57B0" : "",
                color: type === "Image" ? "#fff" : "#000",
              }}
            ></i>
          </div>

          <div
            className="site-body"
            style={{ height: multiMedia?.length > 0 ? "43vh" : "63vh" }}
          >
            {filterData?.length > 0 ? (
              <div>
                {filterData?.map((ele, idx) => {
                  return (
                    <Card key={idx} className="p-2">
                      <Row>
                        <Col
                          xs={1}
                          className="d-flex align-items-center p-0 m-0 ps-2"
                        >
                          <Input
                            type="checkbox"
                            size="md"
                            onClick={() => handleMultiClick(ele)}
                            disabled={multiMedia?.length === 2}
                            checked={multiMedia?.find(
                              (item) => item?.fileName === ele.fileName
                            )}
                          />
                        </Col>
                        <Col xs={11}>
                          <Row>
                            <Col lg={4} className="">
                              {type !== "Image" ? (
                                <img
                                  src={surveyvids}
                                  alt=""
                                  className="object-fit-cover pointer"
                                  style={{ maxHeight: "100px" }}
                                  onClick={() => handleVideoClick(ele)}
                                />
                              ) : (
                                <img
                                  src={ele?.fileLink}
                                  alt=""
                                  className="object-fit-cover pointer"
                                  style={{ maxHeight: "100px" }}
                                  onClick={() => {
                                    setIsLightbox(true);
                                    setlightboxData(ele?.fileLink);
                                  }}
                                />
                              )}
                            </Col>
                            <Col lg={8}>
                              <div className="px-2 fw-500">
                                {ele?.fileName?.split("_").pop()}
                              </div>
                              {!!ele?.latitude && (
                                <div
                                  className="px-2 fw-500"
                                  style={{ color: "#069B80" }}
                                >
                                  {Number(ele?.latitude)?.toFixed(4)}N{" "}
                                  {Number(ele?.longitude)?.toFixed(4)}E
                                </div>
                              )}
                              <div className="fs-14">
                                <i className="bx bxs-map fs-6 me-1"></i>
                                {ele?.location}
                              </div>
                              {!!ele?.uplodedBy && (
                                <div className="fs-14">
                                  <i className="bx bx-user fs-6 me-1"></i>
                                  {ele?.uplodedBy}
                                </div>
                              )}
                              <div className="fs-14">
                                <i className="bx bx-calendar fs-6 me-1"></i>
                                {getDate(ele?.updatedAt)}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={1}></Col>
                        <Col xs={11}>
                          <Row>
                            <Col lg={4}></Col>
                            <Col lg={8}>
                              <div className="d-flex align-items-center">
                                {type !== "File" && (
                                  <Button
                                    outline
                                    color="success"
                                    size="sm"
                                    className="d-flex align-items-center me-4"
                                    onClick={() => handleComment(ele)}
                                  >
                                    <i className="bx bx-message-dots fs-4 me-2"></i>{" "}
                                    Comments
                                  </Button>
                                )}
                                {(ele?.screenshots?.length > 0 ||
                                  ele?.screenShots?.length > 0) && (
                                  <i
                                    className="bx bx-images fs-4 pointer"
                                    onClick={() => handleScreenshots(ele)}
                                  ></i>
                                )}
                                {/* {email === ele?.uplodedBy && (
                                  <i
                                    className="bx bx-edit fs-4 text-success pointer"
                                    onClick={() => handleEdit(ele)}
                                  ></i>
                                )} */}
                                {email === ele?.uplodedBy && (
                                  <i
                                    className="bx bx-trash fs-4 pointer text-danger"
                                    onClick={() => handleDelete(ele)}
                                  ></i>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <div className="text-center p-3">No Records Found</div>
            )}
          </div>
        </div>
        {multiMedia?.length > 0 && (
          <div className="media-fix pb-5">
            <Row className="px-5">
              {multiMedia?.map((item, ids) => (
                <Col md={6} key={ids} style={{ height: "100px" }}>
                  <i
                    className="bx bx-x fs-4 pointer close-btn-pos"
                    onClick={() => handleRemoveMedia(item)}
                  ></i>
                  {type !== "Image" ? (
                    <img src={surveyvids} alt="" className="object-fit-cover" />
                  ) : (
                    <img
                      src={item?.fileLink}
                      alt=""
                      className="object-fit-cover"
                    />
                  )}
                  <div className="fw-500 fs-13">{item?.fileName}</div>
                </Col>
              ))}
            </Row>
          </div>
        )}
        <div className="site-footer">
          <div className="d-flex mt-3 align-items-center justify-content-center">
            {multiMedia?.length > 0 ? (
              <Button
                color="primary"
                className="d-flex align-items-center"
                onClick={handleCompare}
                disabled={multiMedia?.length !== 2}
              >
                <img src={compareimg} width="50%" className="me-2" />{" "}
                <span>Compare</span>
              </Button>
            ) : (
              <Button
                color="primary"
                className="d-flex align-items-center"
                onClick={uploadMedia}
              >
                <i className="bx bx-upload fs-3 me-2"></i>{" "}
                <span>Upload Media</span>
              </Button>
            )}
          </div>
        </div>
        {isLightbox && (
          <Lightbox
            mainSrc={lightboxData}
            imageCaption={lightboxData?.split("_").pop()}
            onCloseRequest={() => setIsLightbox(false)}
          />
        )}
      </div>
    </>
  );
};

export default MediaPanal;
