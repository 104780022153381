import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { getDateOnly } from "../Components/Utils";
import videoPlayer from "../../assets/images/videoplayer.png";

const VideoModal = (props) => {
  const { isOpen, toggleOffcanvas, data, allData } = props;
  console.log(data)

  const handleVideoShow = (e) => {
    props.setIsVideo(false);
    props.setVideoData({
      url: e?.furl,
      info: e,
      videoName: e?.furl?.split("/").pop(),
      count: screenshotNumber(e),
    });
    props.setIsVideo(true);
    toggleOffcanvas()
  };

  const screenshotNumber = (val) => {
    let video = val?.furl?.split("/").pop();
    let fileName = val?.fileName + "_" + video
    let res = allData?.find((e) => {
      if (e.fileType === "survey_screenshot" && e?.fileName === fileName) {
        return e;
      }
    });
    let length = res?.fileLinks?.split("#");
    let len = length?.filter((e) => e !== "");
    return len ? len?.length : 0;
  };
  const handleScreenshotShow = (val) => {
    let video = val?.furl?.split("/").pop();
    let fileName = val?.fileName + "_" + video
    let res = allData?.find((e) => {
      if (e.fileType === "survey_screenshot" && e?.fileName === fileName) {
        return e;
      }
    });
    let result = res?.fileLinks?.split("#");
    result?.pop();
    let result1 = result?.filter((e) => e !== "");

    if (result1?.length > 0) {
      props?.setScreenshot({ res, result: result1 });
      props?.setIsScreenshot(true);
      props?.setIsOffcanvas(false);
    }
  };

  const checkimg = (val) => {
    let video = val?.furl?.split("/").pop();
    let fileName = val?.fileName + "_" + video
    let res = allData?.find((e) => {
      if (e.fileType === "survey_screenshot" && e.fileName === fileName) {
        return e;
      }
    });
    let result = res?.fileLinks?.split("#");
    if (result?.length > 0) {
      return result[0];
    } else {
      return false;
    }
  };

  const fileName = (e) => {
    return e?.furl?.split("/").pop();
  }

  return (
    <React.Fragment>
      {data && (
        <Offcanvas
          isOpen={isOpen}
          scrollable
          backdrop={false}
          toggle={toggleOffcanvas}
          id="offcanvasScrolling"
        >
          <OffcanvasHeader
            toggle={toggleOffcanvas}
            id="offcanvasScrollingLabel"
            className="border-bottom"
          >
            <span className="text-dark fs-16 bold">Videos</span>
          </OffcanvasHeader>
          <OffcanvasBody className="p-0 overflow-hidden p-3">
            {data?.length > 0 ? (
              <>
                {data?.map((e, idx) => {
                  return (
                    <div className="mb-4 d-flex" key={idx}>
                      <div
                        className="img-container pointer"
                        onClick={() => handleVideoShow(e)}
                      >
                        <img
                          src={checkimg(e) ? checkimg(e) : videoPlayer}
                          height={"60px"}
                          width={"100px"}
                        />
                      </div>
                      <div className="video-content">
                        <div className="fs-15">{fileName(e)}</div>
                        <div className="fs-12 text-muted">
                          {getDateOnly(e?.createdAt)}
                        </div>
                        <div className="d-flex">
                          <div
                            className="text-primary fs-13 underline pointer me-5 text-bold"
                            onClick={() => handleScreenshotShow(e)}
                          >
                            View Screenshots ({screenshotNumber(e)})
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <div className="text-muted text-center">Videos not available</div>
            )}
          </OffcanvasBody>
        </Offcanvas>
      )}
    </React.Fragment>
  );
};

export default VideoModal;
