import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { fileTypeArr, mapSources, messages } from "../../commons/constant";
import InputComponent from "../../inputComponent/inputComponent";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import "../../../index.css";
import { Input } from "reactstrap";

const ProjectDetailsModal = (props: any) => {
  let disabled = true;
  const vectorTypes = [
    { vectorType: "Line", value: "line" },
    { vectorType: "Circle", value: "circle" },
    { vectorType: "Fill", value: "fill" },
    { vectorType: "Symbol", value: "symbol" },
  ];
  let layers = [...props?.layers];
  // let allLines = layers?.filter((obj: any) => obj?.vectortype === "line");
  // let allPoints = layers?.filter((obj: any) => obj?.vectortype === "circle");
  // let allPolygons = layers?.filter((obj: any) => obj?.vectortype === "fill");
  // let allSymbols = layers?.filter((obj: any) => obj?.vectortype === "symbol");
  // let selectedLines = [...props?.lines];
  // let selectedPoints = [...props?.points];
  // let selectedPolygons = [...props?.polygon];
  // let selectedSymbols = [...props?.symbol];
  // let unSelectedLines =
  //   selectedLines?.length > 0
  //     ? selectedLines
  //         .map(
  //           (obj) => (allLines = allLines.filter((ele) => ele.layerid !== obj))
  //         )
  //         .slice(-1)[0]
  //     : allLines;
  // let unSelectedPoints =
  //   selectedPoints?.length > 0
  //     ? selectedPoints
  //         .map(
  //           (obj) =>
  //             (allPoints = allPoints.filter((ele) => ele.layerid !== obj))
  //         )
  //         .slice(-1)[0]
  //     : allPoints;
  // let unSelectedPolygons =
  //   selectedPolygons?.length > 0
  //     ? selectedPolygons
  //         .map(
  //           (obj) =>
  //             (allPolygons = allPolygons.filter((ele) => ele.layerid !== obj))
  //         )
  //         .slice(-1)[0]
  //     : allPolygons;
  // let unSelectedSymbols =
  //   selectedSymbols?.length > 0
  //     ? selectedSymbols
  //         .map(
  //           (obj) =>
  //             (allSymbols = allSymbols.filter((ele) => ele.layerid !== obj))
  //         )
  //         .slice(-1)[0]
  //     : allSymbols;

  const renderFooter = () => {
    if (props.header === "Add Source" || props.header === "Edit Source")
      if (
        props.data.datatype === undefined ||
        props.data.datatype === "" ||
        props.data.source_name === undefined ||
        props.data.source_name === "" ||
        props.data.source_link === undefined ||
        props.data.source_link === "" ||
        props.data.priority === undefined ||
        props.data.priority === ""
      )
        disabled = true;
      else disabled = false;
    return (
      <div>
        <Button
          label={props.kind === "Add" ? "Save" : "Update"}
          disabled={disabled}
          className="btn-primary"
          onClick={() => props.handleSave()}
        />
        <Button
          label="Cancel"
          className="btn-secondary"
          onClick={() => props.handleCancel()}
        />
      </div>
    );
  };
  return (
    <Dialog
      draggable={false}
      visible={props.show}
      onHide={() => props.handleCancel()}
      header={props.header}
      footer={renderFooter()}
    >
      <>
        {(props.header === "Add Source" || props.header === "Edit Source") && (
          <div className="row">
            <div className="col-6">
              <label>
                Select Data Type
                <span className="mandatory"> *</span>
              </label>
              <Dropdown
                value={props.data.datatype}
                options={props.dataTypes}
                optionLabel="datatype"
                optionValue="value"
                appendTo="self"
                className="w-100 border ht-50"
                onChange={(e) => props.handleChange(e.target.value, "datatype")}
                placeholder="Select a Data Type"
                filter
                filterBy="datatype"
              />
            </div>
            {props.data.datatype === "raster" && (
              <div className="col-6">
                <label>
                  Select Layer Type
                  <span className="mandatory"> *</span>
                </label>
                <Dropdown
                  value={props.data.layerType}
                  options={[
                    { label: "DSM", value: "DSM" },
                    { label: "DTM", value: "DTM" },
                    { label: "Ortho", value: "Ortho" },
                    { label: "GB", value: "Other" },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  appendTo="self"
                  className="w-100 border ht-50"
                  onChange={(e) =>
                    props.handleChange(e.target.value, "layerType")
                  }
                  placeholder="Select a Data Type"
                  // filter
                  // filterBy="datatype"
                />
              </div>
            )}
            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="Source Name"
                mandatory={true}
                name="source_name"
                placeholder="Enter Source Name"
                value={props.data.source_name}
                required={true}
                maxLength={50}
                type="text"
                onKeyChange={props.handleChange}
              />
            </div>
            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="Source Link"
                mandatory={true}
                name="source_link"
                maxLength={200}
                placeholder="Enter Source Link"
                value={props.data.source_link}
                required={true}
                type="text"
                onKeyChange={props.handleChange}
              />
            </div>

            <div className="col-6">
              <div className="display-flex align-items-center mb-2">
                <span>
                  File Size <span className="mandatory">*</span>
                </span>
              </div>
              <div className="display-flex align-items-center">
                <InputComponent
                  name="geoServerStorage"
                  placeholder="Enter File Size"
                  value={props.data.geoServerStorage}
                  required={true}
                  maxLength={50}
                  type="text"
                  onKeyChange={props.handleChange}
                  style={{ marginRight: "10px" }}
                />
                <Dropdown
                  value={props.data.fileSizeType}
                  options={[
                    { label: "Bytes", value: "BYTES" },
                    { label: "KB", value: "KB" },
                    { label: "MB", value: "MB" },
                    { label: "GB", value: "GB" },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  appendTo="self"
                  className="border"
                  onChange={(e) =>
                    props.handleChange(e.target.value, "fileSizeType")
                  }
                  placeholder="Select File Type"
                />
              </div>
            </div>

            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="Priority"
                mandatory={true}
                name="priority"
                maxLength={200}
                placeholder="Enter Priority"
                value={props.data.priority}
                validator={"PRIORITY"}
                required={true}
                type="number"
                onKeyChange={props.handleChange}
              />
            </div>
            <div className="col-6">
              <InputComponent
                inputLabel={true}
                inputLabelText="Source Display Name"
                name="sourceDisplayName"
                placeholder="Enter Source Display Name"
                value={props.data.sourceDisplayName}
                required={true}
                maxLength={50}
                type="text"
                onKeyChange={props.handleChange}
              />
            </div>
            {props.data.datatype === "raster" && (
              <div className="col-6">
                <InputComponent
                  inputLabel={true}
                  inputLabelText="EPSG"
                  name="epsg"
                  placeholder="Enter Source Display Name"
                  value={props.data.epsg}
                  required={true}
                  maxLength={50}
                  type="text"
                  onKeyChange={props.handleChange}
                />
              </div>
            )}

            <div className="col-6 display-flex">
              <div className="m-t-35 display-flex">
                <Checkbox
                  className="dcolor"
                  inputId="binary"
                  checked={props.data.visibility}
                  onChange={(e) => {
                    props.handleChange1(e.target.checked, "visibility");
                  }}
                />
                <label className="pl-2" htmlFor="binary">
                  Visibility
                </label>
              </div>
            </div>
          </div>
        )}
      </>
    </Dialog>
  );
};

export default ProjectDetailsModal;
