import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import DeleteModal from "../../components/Common/DeleteModal";
import * as api from "../../api/global.api";
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import Loader from "../../components/Common/Loader";
const Screenshots = (props) => {
  const [list, setList] = useState([]);
  const [id, setId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);

  useEffect(() => {
    setList(props?.data?.screenshots || props?.data?.screenShots);
  }, []);

  const handleFullScreen = (idx) => {
    setId(idx);
    setIsView(true);
  };

  const deleteScreenshots = (idx) => {
    setId(idx);
    setIsDelete(true);
  };

  const deleteConfirm = () => {
    setLoading(true);

    if (props?.data?.screenshots) {
      let filterList = list?.filter((e, idx) => idx !== id);
      let obj = {
        fileLink: list[id],
        fileName: props?.data?.fileName,
        id: props?.survey?.id,
        fileType: "survey_screenshot",
      };
      api
        .post("deleteSurveyScreenshot", obj)
        .then((res) => {
          if (res?.status === "Success") {
            toast.success("Screenshot deleted successfully");
            setList(filterList);
            setIsDelete(false);
            setLoading(false);
          } else {
            toast.error(res?.errorMessage);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      let filterList = list?.filter((e, idx) => idx !== id);
      api
        .post("delete/mediaFile", { fileUrl: list[id] })
        .then((res) => {
          if (res?.status === "Success") {
            api
              .put(`update/media/${props?.data?.id}`, { fileUrl: list[id] })
              .then((resp) => {
                if (resp.status === "Success") {
                  toast.success("Screenshot deleted successfully");
                  setList(filterList);
                  setIsDelete(false);
                  setLoading(false);
                } else {
                  setLoading(false);
                  toast.error(resp?.errorMessage);
                }
              })
              .catch((err) => setLoading(false));
          } else {
            toast.error(res?.errorMessage);
          }
        })
        .catch((err) => setLoading(false));
    }
  };

  return (
    <React.Fragment>
      {isDelete && (
        <DeleteModal
          show={isDelete}
          onCloseClick={() => setIsDelete(false)}
          onDeleteClick={deleteConfirm}
        />
      )}

      {isView && (
        <Lightbox
          mainSrc={list[id]}
          enableZoom={true}
          imageTitle={list[id]?.split("/").pop()}
          imageCaption={id + 1}
          nextSrc={list[(id + 1) % list.length]}
          prevSrc={list[(id + list.length - 1) % list.length]}
          onCloseRequest={() => setIsView(false)}
          onMovePrevRequest={() => setId((id + list.length - 1) % list.length)}
          onMoveNextRequest={() => setId((id + 1) % list.length)}
        />
      )}

      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}

      <Modal isOpen={props.isScreenshot} size="xl">
        <ModalHeader className="bg-light">
          <div>Screenshots</div>
          <i
            className="bx bx-x fs-2 pointer"
            onClick={() => {
              props?.setIsScreenshot(false);
              props?.setIsMedia(true);
            }}
          ></i>
        </ModalHeader>
        <ModalBody className="modal-body-scroll">
          {list?.length > 0 ? (
            <Row>
              {list?.map((e, idx) => {
                return (
                  <Col key={idx} md={6} lg={4} className="mb-4">
                    <i
                      className="bx bx-trash fs-4 pointer text-danger d-flex align-items-center"
                      style={{
                        position: "absolute",
                        right: "5px",
                        top: "-5px",
                        height: "30px",
                        width: "30px",
                        background: "#fff",
                        borderRadius: "50%",
                      }}
                      onClick={() => deleteScreenshots(idx)}
                    ></i>
                    <img src={e} className="object-fit-cover" />
                    <i
                      className="bx bx-fullscreen fs-2 pointer"
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "45%",
                      }}
                      onClick={() => handleFullScreen(idx)}
                    ></i>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="text-center p-2">No Record Found</div>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Screenshots;
