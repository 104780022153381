import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";

const SimplePie = ({ dataColors, dataLabels, dataValues }) => {
  var chartPieBasicColors = getChartColorsArray(dataColors);
  const series = dataValues;
  var options = {
    chart: {
      height: 300,
      type: "pie",
    },
    labels: dataLabels,
    legend: {
      show: false,
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
      formatter: function (val, opts) {
        var name = opts.w.globals.series[opts.seriesIndex];
        return name.toFixed(2) + "%";
      },
    },
    colors: chartPieBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="pie"
      height={300}
    />
  );
};

const SimpleDonut = ({ dataColors, dataValues }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = dataValues;
  var options = {
    chart: {
      height: 300,
      type: "donut",
    },
    legend: {
      show: false,
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
      formatter: function (val, opts) {
        var name = opts.w.globals.series[opts.seriesIndex];
        return name.toFixed(2) + "%";
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={300}
    />
  );
};

const UpdateDonut = ({ dataColors }) => {
  var chartDonutupdatingColors = getChartColorsArray(dataColors);
  const series = [44, 55, 13, 33];
  var options = {
    chart: {
      height: 280,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: "bottom",
    },
    colors: chartDonutupdatingColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={280.7}
    />
  );
};

const MonochromePie = ({ dataLabels, dataValues }) => {
  const series = dataValues;
  var options = {
    chart: {
      height: 280,
      type: "pie",
    },
    labels: dataLabels,
    theme: {
      monochrome: {
        enabled: true,
        color: "#405189",
        shadeTo: "light",
        shadeIntensity: 0.6,
      },
    },

    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    title: {
      style: {
        fontWeight: 500,
      },
    },
    dataLabels: {
      formatter: function (val, opts) {
        var name = opts.w.globals.series[opts.seriesIndex];
        return name.toFixed(2) + "%";
      },
      dropShadow: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="pie"
      height={280}
    />
  );
};

const GradientDonut = ({ dataColors }) => {
  var chartPieGradientColors = getChartColorsArray(dataColors);
  const series = [44, 55, 41, 17, 15];
  var options = {
    chart: {
      height: 300,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      position: "bottom",
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    title: {
      text: "Gradient Donut with custom Start-angle",
      style: {
        fontWeight: 500,
      },
    },
    colors: chartPieGradientColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={290.7}
    />
  );
};

const PatternedDonut = ({ dataColors, dataValues, dataLabels }) => {
  var chartPiePatternColors = getChartColorsArray(dataColors);
  const series = dataValues;
  var options = {
    chart: {
      height: 300,
      type: "donut",
      dropShadow: {
        enabled: true,
        color: "#111",
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2,
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            total: {
              showAlways: false,
              show: false,
            },
          },
        },
      },
    },
    labels: dataLabels,
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8,
      },
      formatter: function (val, opts) {
        var name = opts.w.globals.series[opts.seriesIndex];
        return name.toFixed(2) + "%";
      },
    },
    fill: {
      type: "pattern",
      opacity: 1,
      pattern: {
        enabled: true,
        style: [
          "verticalLines",
          "squares",
          "horizontalLines",
          "circles",
          "slantedLines",
        ],
      },
    },
    states: {
      hover: {
        filter: "none",
      },
    },
    theme: {
      palette: "palette2",
    },
    title: {
      // text: "Favourite Movie Type",
      style: {
        fontWeight: 500,
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },

    colors: chartPiePatternColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={280.7}
    />
  );
};

const MultipleRadialbars = ({ dataColors, dataLabels, dataValues }) => {
  var chartPiePatternColors = getChartColorsArray(dataColors);
  const series = dataValues.map((e) => e * 100);
  var options = {
    chart: {
      height: 270,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            show: true,
            fontSize: "16px",
            formatter: function (val) {
              return val / 100 + "%";
            },
          },
          total: {
            show: true,
            label: "Total",
            formatter: function (w) {
              return dataValues?.reduce((a, b) => a + b, 0) + "%";
            },
          },
        },
      },
    },
    labels: dataLabels,
    colors: chartPiePatternColors,
  };
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="radialBar"
      height={270}
    />
  );
};

export {
  SimplePie,
  SimpleDonut,
  UpdateDonut,
  MonochromePie,
  GradientDonut,
  PatternedDonut,
  MultipleRadialbars,
};
