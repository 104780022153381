import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Capitalize, numberRegex, alphabets } from "../../commons/functions";
import * as Yup from "yup";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../api/global.api";
import Loader from "../../CommonComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import { useAppSelector } from "../../../store/hooks";
import { useSelector } from "react-redux";

const CreateSource = (props: any) => {
  const client = useSelector((state: any) => state?.client?.clientDetails);
  const id = useParams().id;

  const [loading, setLoading] = useState<any>(false);
  const [selectedDataType, setSelectedDataType] = useState<any>({ label: "Vector", value: "Vector" });
  const [selectedFileType, setSelectedFileType] = useState<any>(null);
  const [visibility, setVisibility] = useState<any>(true);
  const [dataTypeOption, setDataTypeOptions] = useState<any>([
    // { label: "Raster", value: "Raster" },
    { label: "Vector", value: "Vector" },
  ]);
  const [fileTypeOption, setFileTypeOption] = useState<any>([
    { label: "BYTES", value: "BYTES" },
    { label: "KB", value: "KB" },
    { label: "MB", value: "MB" },
    { label: "GB", value: "GB" },
  ]);
  const formik = useFormik({
    initialValues: {
      dataType: "",
      sourceName: "",
      sourceDisplayName: "",
      sourceLink: "",
      priority: "",
      visibility: 1,
      geoServerStorage: "",
    },
    validationSchema: Yup.object({
      dataType: Yup.string().required("Required"),
      sourceName: Yup.string().required("Required"),
      sourceDisplayName: Yup.string().required("Required"),
      // sourceLink: Yup.string().required("Required"),
      priority: Yup.string()
        .matches(numberRegex, "Please enter valid number")
        .required("Required"),
    }),
    onSubmit: (values: any) => {
      let obj = {};

      if (props?.editData === null) {
        obj = {
          ...values,
          geoServerStorage: values.geoServerStorage + " " + values.fileType,
          clientId: client?.id,
          priority: Number(values.priority),
          project: { connect: { id: Number(id) } },
        };
      } else {
        obj = {
          ...values,
          geoServerStorage: values.geoServerStorage + " " + values.fileType,
          clientId: client?.id,
          priority: Number(values.priority),
          projectId: props.editData.projectId,
          id: props.editData.id,
        };
      }

      props.mode === "Add Source" ? addSourceApi(obj) : updateSourceApi(obj);
    },
  });

  const addSourceApi = (data: any) => {
    delete data.fileType;
    setLoading(true);
    api.post("sources/add", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setLoading(false);
        props?.setOpen(false);
        props?.getSources(1);
        toast.success("Source added successfully");
      } else {
        setLoading(false);
        props?.setOpen(false);
        toast.error(res?.errormessage);
      }
    });
  };

  const updateSourceApi = (data: any) => {
    setLoading(true);
    delete data.fileType;
    api.post("sources/update", data).then((res) => {
      if (res?.status?.toLowerCase() === "success") {
        setLoading(false);
        props?.getSources(1);
        props?.setOpen(false);
        toast.success("Source Updated successfully");
        props.handleEdit(null);
      } else {
        setLoading(false);
        props?.setOpen(false);
        toast.error(res?.errormessage);
        props.handleEdit(null);
      }
    });
  };

  const handleSelectedDataType = (val: any) => {
    setSelectedDataType(val);
    formik.setFieldValue("dataType", val?.label);
  };

  const handleSelectedFileType = (val: any) => {
    setSelectedFileType(val);
    formik.setFieldValue("fileType", val?.label);
  };

  const handleVisibility = (e: any) => {
    setVisibility(e);
    formik.setFieldValue("visibility", e ? 1 : 0);
  };

  useEffect(() => {
    if (props.mode === "Edit Source") {
      console.log(props?.editData);
      const arr = props?.editData?.geoServerStorage.split(" ")
      formik.setFieldValue("dataType", props.editData.dataType);
      formik.setFieldValue("sourceName", props.editData.sourceName);
      formik.setFieldValue(
        "sourceDisplayName",
        props.editData.sourceDisplayName
      );
      formik.setFieldValue("sourceLink", props.editData.sourceLink);
      formik.setFieldValue("priority", props.editData.priority);
      formik.setFieldValue("visibility", props.editData.visibility);
      formik.setFieldValue("geoServerStorage", arr[0]),
      formik.setFieldValue("fileType", arr[1]),
      setVisibility(props.editData.visibility);
      setSelectedDataType({
        label: props.editData.dataType,
        value: props.editData.dataType,
      });
      setSelectedFileType({
        label: arr[1],
        value: arr[1],
      });
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <Loader loader={loading} />
      <Modal isOpen={props?.open}>
        <ModalHeader>
          <span className="mb-2 text-light">{props?.mode}</span>
          <span
            className="cross mb-2 text-light"
            onClick={() => {
              props?.setOpen(false);
              props.handleEdit(null);
            }}
          >
            X
          </span>
        </ModalHeader>
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            formik.handleSubmit();
            return;
          }}
        >
          <ModalBody>
            <Row className="m-3">
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label text-muted">
                    Source Data Type
                  </Label>
                  <span className="mandatory"> *</span>
                  <Select
                    options={dataTypeOption}
                    value={selectedDataType}
                    onChange={(val) => handleSelectedDataType(val)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="moduleName" className="form-label text-muted">
                    Source Name
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="sourceName"
                    name="sourceName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sourceName || ""}
                    invalid={
                      formik.touched.sourceName && formik.errors.sourceName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.sourceName && formik.errors.sourceName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.sourceName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label
                    htmlFor="sourceDisplayName"
                    className="form-label text-muted"
                  >
                    Source Display Name
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="sourceDisplayName"
                    name="sourceDisplayName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sourceDisplayName || ""}
                    invalid={
                      formik.touched.sourceDisplayName &&
                      formik.errors.sourceDisplayName
                        ? true
                        : false
                    }
                  />
                  {formik.touched.sourceDisplayName &&
                  formik.errors.sourceDisplayName ? (
                    <FormFeedback type="invalid">
                      {formik.errors.sourceDisplayName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={6} className="d-flex">
                <div className="mb-3">
                  <Label
                    htmlFor="geoServerStorage"
                    className="form-label text-muted"
                  >
                    File Size
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="geoServerStorage"
                    name="geoServerStorage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.geoServerStorage || ""}
                    invalid={
                      formik.touched.geoServerStorage &&
                      formik.errors.geoServerStorage
                        ? true
                        : false
                    }
                  />

                  {formik.touched.geoServerStorage &&
                  formik.errors.geoServerStorage ? (
                    <FormFeedback type="invalid">
                      {formik.errors.geoServerStorage}
                    </FormFeedback>
                  ) : null}
                </div>
                <Col md={4}>
                  <div className="mb-3 pt-4 mt-2">
                    {/* <Label className="form-label text-muted">File Type</Label>
                    <span className="mandatory"> *</span> */}
                    <Select
                      options={fileTypeOption}
                      value={selectedFileType}
                      onChange={(val) => handleSelectedFileType(val)}
                    />
                  </div>
                </Col>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="sourceLink" className="form-label text-muted">
                    Source Link
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="sourceLink"
                    name="sourceLink"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sourceLink || ""}
                    invalid={
                      formik.touched.sourceLink && formik.errors.sourceLink
                        ? true
                        : false
                    }
                  />
                  {formik.touched.sourceLink && formik.errors.sourceLink ? (
                    <FormFeedback type="invalid">
                      {formik.errors.sourceLink}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label htmlFor="priority" className="form-label text-muted">
                    Priority
                  </Label>
                  <span className="mandatory"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    id="priority"
                    name="priority"
                    value={formik.values.priority || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.priority && formik.errors.priority
                        ? true
                        : false
                    }
                  />
                  {formik.touched.priority && formik.errors.priority ? (
                    <FormFeedback type="invalid">
                      {formik.errors.priority}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={6}>
                <div className="d-flex align-items-center justify-content-start mb-3 mt-3 pt-3">
                  <Input
                    className="form-check-input me-3"
                    type="checkbox"
                    checked={visibility}
                    onChange={(e: any) => handleVisibility(e.target.checked)}
                  />
                  <Label className="form-check-label">Visibility</Label>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ width: "100px" }}
              onClick={() => {
                props.setOpen(false);
                props.handleEdit(null);
              }}
              color="success"
              outline
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" style={{ width: "100px" }}>
              {props.editData === null ? "Save" : "Update"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CreateSource;
