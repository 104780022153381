import React, { useEffect, useState } from "react";
import RightPanal from "./RightPanal";
import LeftPanal from "./LeftPanal";
import TopPanal from "./TopPanal";
import BottomPanal from "./BottomPanal";
import Loader from "../../components/CommonComponent/Loader";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useLocation } from "react-router-dom";
import DeleteModal from "../../components/Common/DeleteModal";
import * as api from "../../api/global.api";
import Draggle from "react-draggable";
import {
  deleteFeatureLayer,
  getDownloadsBySurveyID,
  getDynamicLayers,
  getReportsBySurveyID,
  getSourceid,
  getSurvey,
  getVideos,
} from "../../api/dashboard.api";
import {
  flattenArray,
  getDateOnly,
  makeRasterdata,
  months,
  prepareDynamicLayer,
  sortBy,
} from "../Components/Utils";
import { layersList } from "../../store/layerreducer";
import OpenLayerMap from "./OpenLayerMap";
import { ToastContainer, toast } from "react-toastify";
import SiteUpdateForm from "./SiteUpdateForm";
import AddSiteForm from "./AddSiteForm";
import MediaPanal from "./MediaPanal";
import VideoPlayerNew from "../../components/VideoPlayer/ReactPlayer2";
import Screenshots from "./Screenshots";
import UploadMediaForm from "./UploadMediaForm";
import Comments from "./Comments";
import CompareMedia from "./CompareMedia";
import Legends from "./Legends";

const SurveyMap = (props) => {
  const dispatch = useAppDispatch();
  const location = useLocation()?.state;
  console.log(location)
  const data = useAppSelector((state) => state);
  console.log("data", data)
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [row, setRow] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [updateMap, setUpdateMap] = useState(false);
  const [drawModal, setDrawModal] = useState(false);

  const [allProject, setAllProject] = useState([]);
  const [allSurvey, setAllSurvey] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const [raw, setRaw] = useState([]);
  const [layers, setLayers] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [dynamicData, setDynamicData] = useState([]);
  const [rData, setrData] = useState(null);
  const [reports, setReports] = useState([]);
  const [downloads, setDownloads] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [tool, setTool] = useState("none");
  const [accessory, setAccessory] = useState("none");
  const [isSelect, setIsSelect] = useState(false);
  const [measurement, setMeasurement] = useState("");

  const [closeDynamic, setCloseDynamic] = useState("false");
  const [modal3D, setModal3D] = useState(false);

  const [isSiteForm, setIsSiteForm] = useState(false);
  const [siteForm, setSiteForm] = useState(false);
  const [coord, setCoord] = useState({ long: "", lat: "" });
  const [coordArray, setCoordArray] = useState([]);
  const [updateSite, setUpdateSite] = useState(null);

  //open media sidebar
  const [isMedia, setIsMedia] = useState(false);

  //open video player
  const [isPlayer, setIsPlayer] = useState(false);
  const [mediaData, setMediaData] = useState(null);

  //upload media
  const [isUpload, setIsUpload] = useState(false);
  const [uploadData, setUploadData] = useState(null);

  //screenshots
  const [isScreenshot, setIsScreenshot] = useState(false);
  const [screenshotData, setScreenshotData] = useState(null);
  console.log("screenshotData",screenshotData)

  //comments
  const [isComment, setIsComment] = useState(false);
  const [commentData, setCommentData] = useState(null);

  //compare media
  const [isCompare, setIsCompare] = useState(false);
  const [compareData, setCompareData] = useState(null);

  const onProjectChange = (val) => {
    let project = allProject?.find((ele) => ele?.projectName === val.value);
    setSelectedProject(project);
    sessionStorage.removeItem("surveyToShow");
    sessionStorage.setItem("surveyProject", JSON.stringify(project));
  };

  const onSurveyChange = (val) => {
    let survey = allSurvey?.find((ele) => ele?.surveyName === val.value);
    setSelectedSurvey(survey);
    sessionStorage.setItem("surveyToShow", JSON.stringify(survey));
  };

  useEffect(() => {
    setAllProject(data?.projectsArr);
    let storedProject = JSON.parse(sessionStorage.getItem("surveyProject"));
    setSelectedProject(storedProject);
  }, []);

  useEffect(async () => {
    if (selectedProject !== null) {
      setLoading(true);
      let allSurvey = await getSurvey(selectedProject?.id);
      let resAllSurvey = allSurvey?.response?.map((item) => {
        let date = new Date(item.dateOfSurvey);
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
        const formatedate = date.toISOString();
        return {
          ...item,
          date: months[date.getMonth()] + " (" + date.getFullYear() + ")",
          location: [item.lat, item.long],
          month: months[date.getMonth()],
          year: date.getFullYear(),
          zoom_max: item.zoomMax,
          zoom_min: item.zoomMin,
          surveyname: item.surveyName,
          surveyId: item.id,
          survey_3d_id: item.survey3dId || null,
          potree_link: item.potreeLink || null,
          sortDate: formatedate.split("T")[0],
          startDate: date,
        };
      });
      setAllSurvey(resAllSurvey);
      setSelectedSurvey(resAllSurvey && resAllSurvey[0]);
      let existingSurvey = JSON.parse(sessionStorage.getItem("surveyToShow"));
      if (existingSurvey) {
        setSelectedSurvey(existingSurvey);
      } else {
        setSelectedSurvey(resAllSurvey[0]);
        sessionStorage.setItem("surveyToShow", JSON.stringify(resAllSurvey[0]));
      }

      setLoading(false);
    }
  }, [selectedProject]);

  useEffect(async () => {
    if (selectedSurvey !== null) {
      setLoading(true);
      let sourceData = await getSourceid(selectedSurvey?.id);
      setRaw(sourceData?.response);
      sourceData = await sortBy(sourceData?.response, "priority");
      let rData = makeRasterdata(sourceData);
      setrData(rData[0]);
      let layerDetails = await sortBy(rData[1], "priority");
      setLayers(layerDetails);

      let dynamicData = await getDynamicLayers(selectedSurvey?.id);
      setDynamicData(dynamicData?.response?.rows || []);
      let dynamicLayers = prepareDynamicLayer(dynamicData?.response?.rows);
      let chk = dynamicLayers.filter((e) => e?.visibility === 0).length;
      chk ? setSelectAll(false) : setSelectAll(true);
      setDynamic(dynamicLayers);

      layerDetails = [...layerDetails, ...dynamicLayers];
      dispatch(layersList(layerDetails));

      let reportsData = await getReportsBySurveyID(selectedSurvey?.id);
      setReports(reportsData?.response?.rows || []);

      let downloadData = await getDownloadsBySurveyID(selectedSurvey?.id);
      setDownloads(downloadData?.response?.rows || []);

      get3DSources();

      setUpdateMap(true);
      setLoading(false);
    }
  }, [selectedSurvey]);

  const get3DSources = async () => {
    let response = await api.get(
      `source3d/get?surveyId=${selectedSurvey?.id}&projectId=${selectedProject?.id}`
    );
    if (response?.status === "Success") {
      if (response?.response?.findSource3d?.length > 0) {
        setModal3D(true);
      } else {
        setModal3D(false);
      }
    } else {
      toast.error(response?.errormessage);
      setModal3D(false);
    }
  };

  useEffect(() => {
    if (updateMap) {
      let obj = {
        smap: dynamicData,
        rData: rData,
        surveyData: selectedSurvey,
        projectData: selectedProject,
        layerDetails: data?.layers,
        raw: raw,
      };
      setMapData(obj);
      setUpdateMap(false);
    }
  }, [updateMap]);

  const changeVisibilty = (val) => {
    let res = layers?.map((e) => {
      if (e?.displayname === val?.displayname) {
        return { ...e, visibility: Number(!e?.visibility) };
      } else {
        return e;
      }
    });
    setLayers(res);
    let updatedlayers = data?.layers?.map((e) => {
      if (e?.displayname === val?.displayname) {
        return { ...e, visibility: Number(!e?.visibility) };
      } else {
        return e;
      }
    });
    dispatch(layersList(updatedlayers));
  };

  const addDynamicLayer = async () => {
    let dynamicData = await getDynamicLayers(selectedSurvey?.id);
    setDynamicData(dynamicData?.response?.rows);
    let dynamicLayers = prepareDynamicLayer(dynamicData?.response?.rows);
    let chk = dynamicLayers.filter((e) => e?.visibility === 0).length;
    chk ? setSelectAll(false) : setSelectAll(true);
    setDynamic(dynamicLayers);
    let layerDetails = [...layers, ...dynamicLayers];
    dispatch(layersList(layerDetails));
    setUpdateMap(true);
  };

  const deletelayer = (layer) => {
    setRow(layer);
    setIsDeleteModal(true);
  };

  const onDeleteClick = () => {
    deleteFeatureLayer(row?.displayname).then(async (res) => {
      if (res.status === "Success") {
        toast.success("Feature deleted successfully");
        setRow(null);
        setIsDeleteModal(false);
        let dynamicData = await getDynamicLayers(selectedSurvey?.id);
        setDynamicData(dynamicData?.response?.rows);
        let dynamicLayers = prepareDynamicLayer(dynamicData?.response?.rows);
        let chk = dynamicLayers.filter((e) => e?.visibility === 0).length;
        chk ? setSelectAll(false) : setSelectAll(true);
        setDynamic(dynamicLayers);
        let layerDetails = [...layers, ...dynamicLayers];
        dispatch(layersList(layerDetails));
        setUpdateMap(true);
      }
    });
  };

  const closeDynamics = () => {
    setCloseDynamic(true);
  };

  const getCorrd = (data) => {
    setCoord({ long: data[0], lat: data[1] });
  };

  const getCoordArray = (data) => {
    setCoordArray(data);
  };

  const onVideoClick = (val) => {
    setMediaData({
      url: val?.videolink ? val?.videolink : val?.fileLink,
      info: val,
      videoName: val?.videoName ? val?.videoName : val?.fileName,
      survey: selectedSurvey,
      project:selectedProject
    });
    setIsPlayer(true);
  };

  const onUploadScreenshot = async (obj) => {
    setLoading(true);
    if (obj?.type) {
      api.post("multipleSurveyScreenshotUpload", obj).then(async (res) => {
        if (res?.status?.toLowerCase() === "success") {
          toast.success("Screenshot uploaded successfully");
          setIsPlayer(false);
          setIsMedia(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      api
        .post("upload/mediaScreenshot", obj)
        .then((res) => {
          if (res.status === "Success") {
            toast.success("Screenshot uploaded successfully");
            setLoading(false);
            setIsMedia(true);
            setIsPlayer(false);
          } else {
            toast.error(res?.errormessage);
            setLoading(false);
          }
        })
        .catch((err) => setLoading(false));
    }
  };

  const getMedia = async () => {
    let res = await getVideos(selectedSurvey?.id);
    if (res?.status === "Success") {
      let vids = res?.response?.rows?.filter(
        (row) => row?.fileType === "video"
      );

      let links = vids?.map((vid) => {
        let link = vid?.fileLinks.split("#");
        link.pop();

        let finalArray = link?.map((ele) => {
          let videoName = ele?.split("/").pop();
          let createdAt = vid.createdAt;
          let videolink = ele;
          let fileName = vid?.fileName + "_" + videoName;
          let screenshots = res?.response?.rows?.filter(
            (img) =>
              img.fileType === "survey_screenshot" && img.fileName === fileName
          );
          let imageArray = screenshots?.map((item) => {
            let imgLink = item.fileLinks?.split("#");
            imgLink.pop();
            return imgLink;
          });

          return {
            fileName: videoName,
            updatedAt: createdAt,
            videolink: videolink,
            videoName: fileName,
            screenshots: flattenArray(imageArray) || [],
          };
        });
        return finalArray;
      });
      let arr = flattenArray(links);
      return {
        data: arr,
        dateOpt: vids?.map((e) => ({
          label: getDateOnly(e?.createdAt),
          value: e?.createdAt,
        })),
        locOpt: [],
      };
    }
  };

  const getUploadedMedia = async () => {
    let data = await api.get(
      `get/mediatool?projectId=${selectedProject?.id}&surveyId=${selectedSurvey?.id}`
    );
    if (data?.status === "Success") {
      return data.response;
    } else {
      toast.error(res.errormessage);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {loading && <Loader loader={loading} />}
      {isDeleteModal && (
        <DeleteModal
          show={isDeleteModal}
          onCloseClick={() => setIsDeleteModal(false)}
          onDeleteClick={() => onDeleteClick()}
        />
      )}

      {selectedProject !== null && selectedSurvey !== null && (
        <div className="survey-map">
          {mapData !== null && (
            <OpenLayerMap
              mapData={mapData}
              setAccessory={setAccessory}
              accessory={accessory}
              tool={tool}
              setTool={setTool}
              isSelect={isSelect}
              setMeasurement={setMeasurement}
              addDynamicLayer={addDynamicLayer}
              drawModal={drawModal}
              setDrawModal={setDrawModal}
              setLoading={setLoading}
              closeDynamics={closeDynamics}
              siteForm={siteForm}
              getCorrd={getCorrd}
              isSiteForm={isSiteForm}
              isUpload={isUpload}
              coordArray={coordArray}
            />
          )}

          <RightPanal
            setAccessory={setAccessory}
            accessory={accessory}
            tool={tool}
            setTool={setTool}
            setIsSelect={setIsSelect}
            survey={selectedSurvey}
            setLoading={setLoading}
            allSurvey={allSurvey}
            dynamic={dynamic}
            dynamicData={dynamicData}
            selectedProject={selectedProject}
            selectedSurvey={selectedSurvey}
            rData={rData}
            addDynamicLayer={addDynamicLayer}
            closeDynamic={closeDynamic}
            setCloseDynamic={setCloseDynamic}
            raw={raw}
            modal3D={modal3D}
            setSiteForm={setIsSiteForm}
            setIsMedia={setIsMedia}
          />

          <LeftPanal
            layers={layers}
            setLayers={setLayers}
            reports={reports}
            downloads={downloads}
            setAccessory={setAccessory}
            accessory={accessory}
            tool={tool}
            setTool={setTool}
            project={selectedProject}
            setLoading={setLoading}
            changeVisibilty={changeVisibilty}
            measurement={measurement}
          />

          <TopPanal
            allProject={allProject}
            selectedProject={selectedProject}
            onProjectChange={onProjectChange}
            allSurvey={allSurvey}
            selectedSurvey={selectedSurvey}
            onSurveyChange={onSurveyChange}
          />

          <BottomPanal
            dynamic={dynamic}
            setDynamic={setDynamic}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            setIsDeleteModal={setIsDeleteModal}
            setRow={setRow}
            layers={layers}
            deletelayer={deletelayer}
          />

          <Legends raw={raw} layers={layers} />

          {isSiteForm && (
            <SiteUpdateForm
              onClose={setIsSiteForm}
              addSite={setSiteForm}
              setUpdateSite={setUpdateSite}
              setCoord={setCoord}
              survey={selectedSurvey}
              getCoordArray={getCoordArray}
            />
          )}

          {siteForm && (
            <AddSiteForm
              onClose={setSiteForm}
              coord={coord}
              setIsSiteForm={setIsSiteForm}
              updateSite={updateSite}
              survey={selectedSurvey}
            />
          )}

          {isMedia && (
            <MediaPanal
              isMedia={isMedia}
              setIsMedia={setIsMedia}
              getCoordArray={getCoordArray}
              onVideoClick={onVideoClick}
              survey={selectedSurvey}
              getMedia={getMedia}
              setIsScreenshot={setIsScreenshot}
              setScreenshotData={setScreenshotData}
              setIsUpload={setIsUpload}
              setCoord={setCoord}
              setIsComment={setIsComment}
              setCommentData={setCommentData}
              setCompareData={setCompareData}
              setIsCompare={setIsCompare}
              getUploadedMedia={getUploadedMedia}
              setUploadData={setUploadData}
              getCorrd={getCorrd}
            />
          )}

          {isPlayer && (
            <Draggle>
              <div
                style={{
                  position: "absolute",
                  top: "10%",
                  left: "20%",
                  zIndex: 201,
                }}
              >
                <VideoPlayerNew
                  onClose={() => {
                    setIsPlayer(false);
                    setMediaData(null);
                    setIsMedia(true);
                  }}
                  videoData={mediaData}
                  onUploadScreenshot={onUploadScreenshot}
                />
              </div>
            </Draggle>
          )}

          {isUpload && (
            <UploadMediaForm
              isUpload={isUpload}
              setIsUpload={setIsUpload}
              setIsMedia={setIsMedia}
              coord={coord}
              getCoordArray={getCoordArray}
              survey={selectedSurvey}
              project={selectedProject}
              uploadData={uploadData}
            />
          )}

          {isScreenshot && (
            <Screenshots
              isScreenshot={isScreenshot}
              setIsScreenshot={setIsScreenshot}
              data={screenshotData}
              setIsMedia={setIsMedia}
              survey={selectedSurvey}
            />
          )}

          {isComment && (
            <Comments
              setIsComment={setIsComment}
              setIsMedia={setIsMedia}
              commentData={commentData}
              survey={selectedSurvey}
              project={selectedProject}
            />
          )}

          {isCompare && (
            <CompareMedia
              setIsCompare={setIsCompare}
              isCompare={isCompare}
              compareData={compareData}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default SurveyMap;
