import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Input, Label } from "reactstrap";
import Loader from "../../components/Common/Loader";
import * as api from "../../api/global.api";
import videoImg from "../../assets/svg-new/video.svg";

const UploadMediaForm = (props) => {
  const email = useSelector((state) => state.user.email);
  const user = useSelector((state) => state.user);
  const fullName = user?.firstName + " " + user?.lastName;
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loc, setLoc] = useState("");
  const [comments, setComments] = useState("");
  const [fileLink, setFileLink] = useState();
  const [fileType, setFileType] = useState("image");

  const handleSave = () => {
    let obj = {
      fileType: fileType,
      fileLink: fileLink,
      uplodedBy: email,
      latitude: (props?.coord?.lat).toString(),
      longitude: props?.coord?.long.toString(),
      location: loc,
      projectId: props?.survey?.projectId,
      surveyId: props?.survey?.id,
    };

    setLoading(true);

    if (props?.uploadData) {
      api
        .put(`update/media/${props.uploadData?.id}`, obj)
        .then((res) => {
          if (res?.status === "Success") {
            toast.success("Media updated successfully");
            setLoading(false);
            props?.setIsUpload(false);
            props?.setIsMedia(true);
          } else {
            toast.error(res?.errorMessage);
            setLoading(false);
          }
        })
        .catch((err) => setLoading(false));
    } else {
      if (comments !== "") {
        obj["comments"] = [
          {
            comment: comments,
            createdBy: fullName,
            userLogo: user?.imageUrl,
          },
        ];
      }
      api
        .post("add/mediatool", obj)
        .then((res) => {
          if (res?.status === "Success") {
            toast.success("Media uploaded successfully");
            props?.setIsMedia(true);
            props?.setIsUpload(false);
            setLoading(false);
          } else {
            toast.error(res?.errorMessage);
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error(error);
          setLoading(false);
        });
    }
  };

  const checkDisable = () => {
    if (loc?.trim() === "" || !props?.coord?.lat || !fileLink) {
      return true;
    } else {
      return false;
    }
  };

  const handleAcceptedFiles = (files) => {
    const formData = new FormData();
    if (files?.length !== 1) {
      toast.warning("Please upload one file only");
    } else {
      let ext = files[0]?.name?.split(".")?.pop();
      let size = files[0].size / 1024;
      let actSize = size / 1024;
      formData.append("files", files[0]);
      formData.append("clientId", props?.project?.clientId),
      formData.append("clientName", props?.project?.client.clientName),
      formData.append("projectId", props?.project?.id),
      formData.append("projectName", props?.project?.projectName),
      formData.append("surveyId", props?.survey.surveyId),
      formData.append("surveyName", props?.survey.surveyName),
      formData.append("module", "Survey" )
      if (
        ext.toLowerCase() === "jpg" ||
        ext.toLowerCase() === "png" ||
        ext.toLowerCase() === "jpeg"
      ) {
        setLoading1(true);
        api.post("upload/mediafiles", formData).then((res) => {
          if (res?.status === "Success") {
            setFileLink(res?.urls[0]);
            setFileType("image");
            setLoading1(false);
          } else {
            setLoading1(false);
            toast.error(res?.errorMessage);
          }
        });
      }

      if (ext?.toLowerCase() === "mp4" && actSize > 100) {
        toast.warning("Please upload file less than  100mb");
      }

      if (ext?.toLowerCase() === "mp4" && actSize < 100) {
        setLoading1(true);
        api.post("upload/mediafiles", formData).then((res) => {
          if (res?.status === "Success") {
            setFileLink(res?.urls[0]);
            setFileType("video");
            setLoading1(false);
          } else {
            setLoading1(false);
            toast.error(res?.errorMessage);
          }
        });
      }
    }
  };

  const gotogoogle = () => {
    window.open(
      `https://www.google.com/maps/@${props?.coord?.lat},${props?.coord?.long},15z?entry=ttu`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleClose = () => {
    if (fileLink?.length > 0) {
      props?.setIsMedia(true);
      props?.setIsUpload(false);

      props?.getCoordArray([]);
    } else {
      props?.setIsMedia(true);
      props?.setIsUpload(false);
    }
  };

  const handleRemove = () => {
    api.post("delete/mediaFile", { fileUrl: fileLink }).then((res) => {
      if (res) {
        setFileLink(null);
      }
    });
  };

  useEffect(() => {
    if (props?.uploadData) {
      let val = props?.uploadData;
      setLoc(val.location);
      setFileLink(val?.fileLink);
      setComments(val?.comments[0]?.comment);
    }
  }, []);

  return (
    <div className="media-upload">
      <div className="add-site">
        {loading && (
          <div className="loading">
            <Loader />
          </div>
        )}
        <div className="site-header">
          <div className="fs-16 fw-500">Upload Media</div>
          <i className="bx bx-x fs-2" onClick={handleClose}></i>
        </div>
        <div className="add-site-body">
          {props?.coord?.lat !== "" && (
            <div className="location mb-2" onClick={gotogoogle}>
              {props?.coord?.lat?.toFixed(4)}
              {"\u00B0"} N {props?.coord?.long?.toFixed(4)} {"\u00B0"} E
            </div>
          )}

          <div className="mb-3">
            <Label className="text-muted">
              Location Name <span className="required">*</span>
            </Label>
            <Input
              type="text"
              value={loc}
              onChange={(e) => setLoc(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Label className="text-muted">
              Upload Video/ Image <span className="required">*</span>
            </Label>
            {!fileLink ? (
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
                // accept={fileType}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone dz-clickable form-control p-0">
                    <div className="needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="m-4 d-flex align-items-center justify-content-center">
                        {loading1 ? (
                          <>
                            <Loader />
                            <span className="text-primary">Uploading...</span>
                          </>
                        ) : (
                          <>
                            <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                            <span className="text-muted">Upload Media</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div className="d-flex align-items-center m-3">
                <img
                  src={
                    fileLink?.split(".").pop()?.toLowerCase() === "mp4"
                      ? videoImg
                      : fileLink
                  }
                  className="me-3"
                  height="60px"
                  width="60px"
                />
                <div>{fileLink?.split("_").pop()}</div>
                <div className="bx-end">
                  <i
                    className="bx bx-x fs-2 pointer"
                    onClick={handleRemove}
                  ></i>
                </div>
              </div>
            )}
          </div>

          <div className="mb-3">
            <Label className="text-muted">Comments</Label>
            <textarea
              className="form-control"
              rows="4"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              disabled={props?.uploadData}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end p-2 mb-3">
          <Button
            className="me-3 w-sm"
            color="success"
            outline
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="success"
            className="me-3 w-sm"
            onClick={handleSave}
            disabled={checkDisable()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadMediaForm;
