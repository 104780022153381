import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import ClientDataTable from "../../tables/ClientDataTable";
import * as api from "../../../api/global.api";
import Pagination from "../../commons/pagination";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../CommonComponent/Loader";
import SearchOption from "../../Common/SearchOption";
import { useDispatch } from "react-redux";
import { client } from "../../../store/clientReducer";
import DeleteModal from "../../Common/DeleteModal";

const ClientManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [isTable, setIsTable] = useState<any>(true);
  const [pageNumber, setPageNumber] = useState<any>(0);
  const [totalCount, setTotalCount] = useState<any>();
  const [search, setSearch] = useState<any>("");
  const [isActive, setIsActive] = useState<any>(false);
  const [deleteClientId, setDeleteClientId] = useState<any>();
  const [isDelete, setIsDelete] = useState<Boolean>(false);

  const getAllClients = (val: any) => {
    setLoading(true);
    let url = "";
    isTable
      ? (url = `clients/get?${val}&limit=10`)
      : (url = `clients/get?${val}&limit=12`);
    if (val === "page=0&limit=12" || val === "page=0&limit=10")
      url = `clients/get?${val}`;
    api
      .get(url)
      .then((res) => {
        setTableData(res.response.rows);
        console.log(res.response.rows)
        setTotalCount(res?.response?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleAddClient = () => {
    sessionStorage.removeItem("editClient");
    navigate("/client");
  };

  const handleEdit = (ele: any) => {
    sessionStorage.setItem("editClient", JSON.stringify(ele));
    navigate("/client");
  };

  const handleActive = (e: any, ele: any) => {
    setLoading(true);
    api
      .post("client/update", { id: ele.id, isActive: e.target.checked })
      .then((res) => {
        if (res?.status.toLowerCase() === "success") {
          getAllClients(`page=${pageNumber}`);
          res?.response?.isActive === true
            ? toast.success("Client activated successfully")
            : toast.success("Client deactivated successfully");
        } else {
          setLoading(false);
          toast.error(res?.errormessage);
        }
      });
  };

  const handleSearch = (val: any) => {
    setSearch(val);
    if (val === "") getAllClients(`page=0`);
  };

  const handlePageChange = (val: any) => {
    setPageNumber(val - 1);
    getAllClients(`page=${val - 1}`);
  };

  const deleteModalClose = () => {
    setDeleteClientId(null);
    setIsDelete(false);
  };

  const onDeleteClick = (row:{id:string}) => {
    setDeleteClientId(row.id);
    setIsDelete(true);
  };


  const handleDeleteClick = () => {
    setLoading(true);
    let url = "deleteclient/" + deleteClientId;
    api
      .deleteApi(url)
      .then((res) => {
        if (res.status === "Success") {
          getAllClients(`page=${pageNumber}&limit=10`);
          setIsDelete(false);
          toast.success("Client deleted successfully");
        } else {
          toast.error(res.errormessage);
          setLoading(false);
          setIsDelete(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
        setIsDelete(false);
      });
  };

  useEffect(() => {
    getAllClients(`page=0`);
  }, []);

  useEffect(() => {
    let delayDebounce = setTimeout(() => {
      if (search !== "") getAllClients(`search=${search}`);
    }, 1200);
    return () => clearTimeout(delayDebounce);
  }, [search]);

  const onClickProject = (ele: any) => {
    sessionStorage.setItem(
      "prevRoute",
      JSON.stringify({
        name: ele.clientName,
        link: "/clients",
      })
    );
    dispatch(client(ele));
    navigate(`/delivers/${ele.id}`, { state: ele });
  };
  return (
    <div className="px-3 mt-3 ptable">
      <Loader loader={loading} />
      <ToastContainer />

      <Card className="card-border-none">
        <CardHeader className="header-card">
          <div className="d-flex justify-content-between align-items-center admin-card-head">
            <div>All Clients</div>
            <div className="d-flex align-items-center">
              <i
                className="ri-layout-4-line fs-3 me-2"
                onClick={() => {
                  setIsTable(!isTable);
                  isTable
                    ? getAllClients("page=0&limit=12")
                    : getAllClients("page=0&limit=10");
                }}
              ></i>
              <Button
                color="success"
                onClick={() => {
                  handleAddClient();
                }}
              >
                <span className="me-2">+</span>
                <span className="admin-card-head">Add Client</span>
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {!isTable && !loading && (
            <Row>
              {tableData?.map((ele: any, key: any) => {
                return (
                  <Col xxl={4} md={6} key={key}>
                    <Card className="p-2">
                      <div className="d-flex justify-content-end align-items-center">
                        <i
                          className="bx bx-edit fs-20 me-3 pointer"
                          style={{ color: "green" }}
                          onClick={() => handleEdit(ele)}
                        ></i>
                        <div className="flex-shrik-0 form-check form-switch form-switch-right form-switch-md pt-1 me-1">
                          <Input
                            checked={ele.isActive}
                            className="form-check-input code-switcher "
                            type="checkbox"
                            onChange={(e) => handleActive(e, ele)}
                          />
                        </div>
                      </div>

                      <CardBody>
                        <div>
                          <div className="text-center">
                            <div className="mb-2">
                              <img
                                src={ele?.clientLogo || ""}
                                className="rounded img-fluid w-25 "
                                alt=""
                              />
                            </div>
                            <h6 className="fs-14 text-primary">
                              {ele.clientName}
                            </h6>
                            <h6 className="fs-14 text-black-50">{ele.email}</h6>
                            <span className="fs-13 text-muted ">
                              {ele.contactNumber}
                            </span>
                            <div className="fs-13 text-muted ">
                              {ele?.state}, {ele?.country}
                            </div>
                          </div>
                        </div>
                      </CardBody>
                      <Row className="my-2 ">
                        <Col lg={6} className="d-flex justify-content-center ">
                          <span className=" text-dark ">
                            <h6>
                              <i className="ri-layout-grid-fill me-2"> </i> No
                              of Module ({ele?.clientModules?.length})
                            </h6>
                          </span>
                        </Col>
                        <Col
                          lg={6}
                          className="d-flex justify-content-center pointer"
                        >
                          <span
                            onClick={() => {
                              onClickProject(ele);
                            }}
                          >
                            <h6 className="text-success">
                              <i className="ri-add-line align-bottom me-1"></i>
                              Add Project
                            </h6>
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}

          {isTable && (
            <Row>
              <SearchOption handleSearch={handleSearch} />
              <ClientDataTable
                data={tableData}
                handleEdit={handleEdit}
                handleActive={handleActive}
                handleProject={onClickProject}
                handleDelete={onDeleteClick}
              />
            </Row>
          )}

          {!isTable && tableData.length === 0 && !loading && (
            <div className="nodata">No data found</div>
          )}
        </CardBody>
        {totalCount > 10 && isTable && (
          <CardFooter className="footer-card card-border-none">
            <Pagination
              handlePageChange={handlePageChange}
              itemsPerPage={10}
              count={totalCount}
            />
          </CardFooter>
        )}
        {totalCount > 12 && !isTable && (
          <CardFooter className="footer-card card-border-none">
            <Pagination
              handlePageChange={handlePageChange}
              itemsPerPage={12}
              count={totalCount}
            />
          </CardFooter>
        )}
      </Card>

      <DeleteModal
        show={isDelete}
        onDeleteClick={handleDeleteClick}
        onCloseClick={deleteModalClose}
      />
    </div>
  );
};

export default ClientManagement;
