import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fromLonLat, toLonLat, transform } from "ol/proj";
import { Tile as TileLayer, Vector as VectorLayer, Image } from "ol/layer.js";
import { OSM, XYZ, Vector as VectorSource, ImageWMS } from "ol/source";
import { Rotate, defaults } from "ol/control";
import { Draw, Modify, Select, Snap } from "ol/interaction.js";
import { LineString, Point, Polygon } from "ol/geom";
import { unByKey } from "ol/Observable.js";
import { GeoJSON } from "ol/format";
import { click } from "ol/events/condition";
import { Chart } from "primereact/chart";
import { Feature } from "ol";

import {
  createLayers,
  formatArea,
  formatLength,
  formatLengthInMeters,
  vectorData,
} from "../Components/Utils";
import {
  buffer,
  getCoords,
  lineString,
  point,
  polygon,
  transformTranslate,
} from "@turf/turf";
import View from "ol/View";
import Map from "ol/Map";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Circle from "ol/style/Circle";
import Overlay from "ol/Overlay";
import ImageLayer from "ol/layer/Image";
import DrawModal from "./DrawModal";
import IdentifierPopup from "./IdentifierPopup";
import ExportModal from "./ExportModal";
import * as api from "../../api/global.api";
import rotateImage from "../../assets/svg-new/rotatetoolImg.svg";
import BufferModal from "./BufferModal";
import { addDrawData } from "../../api/map.api";
import { ToastContainer, toast } from "react-toastify";
import VolumetricModal from "./VolumetricModal";
import { Icon } from "ol/style";
import locss from "../../assets/svg-new/locs.png";
const OpenLayerMap = (props) => {
  let layers = useAppSelector((state) => state?.layers);
  const mapElement = useRef();
  const mapRef = useRef();
  const markerLayerRef = useRef(null);

  const [map, setMap] = useState();
  const [satelliteViewMap, setSatelliteViewMap] = useState(null);
  const [layerInfo, setLayerInfo] = useState(null);
  const [layerState, setLayerState] = useState(null);
  const [sourceState, setSourceState] = useState(null);

  const [vectorDrawData, setVectorDrawData] = useState(null);

  //draw
  const [toolState, setToolState] = useState();
  const [drawData, setDrawData] = useState(null);
  const [drawModal, setDrawModal] = useState(false);
  const [editDraw, setEditDraw] = useState(null);

  //identifier
  const [identifierData, setIdentifierData] = useState([]);
  const [OverlayData, setOVerlayData] = useState();
  const [popover, setPopover] = useState(false);

  //export states
  const [isOpen, setIsOpen] = useState(false);
  const [exportLayer, setExportLayer] = useState(null);
  const [filteredLayer, setFilteredLayer] = useState(null);

  //profile view
  const [BufData, setBufData] = useState([]);
  const [profileModal, setProfileModal] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [profileLength, setProfileLength] = useState();
  const [pathDistance, setPathDistance] = useState([]);
  const [profileResponse, setProfileResponse] = useState();
  const [profileCoords, setProfileCoords] = useState();
  const [showGraph, setShowGraph] = useState(false);
  const [basicData, setBasicData] = useState({
    labels: [],
    datasets: [],
  });

  //buffer state
  // const [tool, setTool] = useState("none");
  const [select, Unselect] = useState();
  const [bufferModal, setBufferModal] = useState(false);
  const [formBufferData, setFormBufferData] = useState();
  const [activeBuffer, setActiveBuffer] = useState(null);
  const [bufferData, setBufferData] = useState({});
  const [buffData, setBuffData] = useState(null);
  //volumetric
  const [vol, setVol] = useState(false);
  const [volData, setVolData] = useState(null);

  useEffect(() => {
    createMapMethod(props?.mapData);
  }, [props?.mapData]);

  const createMapMethod = (mapData) => {
    if (mapRef?.current !== null) {
      setMap(null);
      let element = document?.getElementsByClassName("ol-viewport");
      while (element?.length > 0) {
        element[0].parentNode?.removeChild(element[0]);
      }
    }

    const washingtonLonLat = [
      mapData?.surveyData?.lat,
      mapData?.surveyData?.long,
    ];

    // const washingtonLonLat = [74.27443757451996, 17.18583401330151];

    const washingtonWebMercator = fromLonLat(washingtonLonLat);


    let baseMap = new TileLayer({
      source: new OSM(),
    });

    // const wmsSource = new ImageWMS({
    //   url: "http://geoserver.drogodrones.com:8080/geoserver/Tembhu/wms",
    //   params: { LAYERS: "Tembhu:dtm" },
    //   ratio: 1,
    //   serverType: "geoserver",
    // });

    const initialMap = new Map({
      target: mapElement?.current,
      layers: [
        baseMap,
        satelliteLayer(true),
        // new ImageLayer({
        //   // extent: [420512, 1910722, 421096, 1911368],
        //   source: wmsSource,
        // }),
      ],
      view: new View({
        center: washingtonWebMercator,
        zoom: mapData?.surveyData?.zoom || 14.5,
      }),
      controls: defaults({
        attributionOptions: {
          collapsible: true,
        },
      }),
    });
    initialMap.set("attribution", "");
    let LayersWithSources = createLayers(mapData?.rData);
    setSourceState(LayersWithSources[1]);

    let layersArray = LayersWithSources[0];
    layersArray?.map((layer) => initialMap?.addLayer(layer));

    let vectorLayerArray;
    if (mapData?.smap?.length > 0) {
      vectorLayerArray = vectorData(mapData.smap);
    }
    vectorLayerArray?.map((layer) => initialMap?.addLayer(layer));

    let existingVectorLayerArray = [];
    if (mapData?.rData?.length > 0) {
      mapData?.rData?.map((layer) =>
        layer?.source?.type === "vector"
          ? existingVectorLayerArray.push(layer.source)
          : ""
      );
    }
    setLayerInfo(existingVectorLayerArray);

    if (mapData?.smap?.length > 0) {
      setLayerState([...layersArray, ...vectorLayerArray]);
    } else {
      setLayerState(layersArray);
    }
    setMap(initialMap);
  };

  //getcoordinates
  useEffect(() => {
    if (map) {
      const source = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: source,
        style: new Style({
          image: new Icon({
            src: locss,
            scale: 1,
          }),
        }),
      });
      map.addLayer(vectorLayer);

      let markerFeature = null;

      const handleMapClick = (event) => {
        const coordinate = event.coordinate;
        const finalCoord = toLonLat(coordinate);

        if (markerFeature) {
          markerFeature.getGeometry().setCoordinates(coordinate);
        } else {
          markerFeature = new Feature(new Point(coordinate));
          source.addFeature(markerFeature);
        }

        props.getCorrd(finalCoord);
      };

      if (props?.siteForm || props?.isUpload) {
        map.on("click", handleMapClick);
      } else {
        map.un("click", handleMapClick);
        if (markerFeature) {
          source.removeFeature(markerFeature);
          markerFeature = null;
          map.un("click", handleMapClick);
        }
      }
      return () => {
        map.un("click", handleMapClick);
        if (markerFeature) {
          source.removeFeature(markerFeature);
        }
      };
    }
  }, [props.siteForm, props?.isUpload]);

  //show coordinates
  useEffect(() => {
    let markerFeature;
    const source = new VectorSource();

    const updateMarkers = () => {
      const vectorLayer = new VectorLayer({
        source: source,
        style: new Style({
          image: new Icon({
            src: locss,
            scale: 1,
          }),
        }),
      });

      if (markerLayerRef.current) {
        map.removeLayer(markerLayerRef.current);
      }

      map.addLayer(vectorLayer);
      markerLayerRef.current = vectorLayer;

      if (props?.coordArray?.length > 0) {
        props.coordArray.forEach((coord) => {
          let co = fromLonLat(coord);
          markerFeature = new Feature(new Point(co));
          source.addFeature(markerFeature);
        });
      } else {
        // Clear the source to remove all markers
        source.clear();
      }
    };

    if (map) {
      updateMarkers();
    }
  }, [props?.coordArray]);

  //satellitelayer
  const satelliteLayer = (satellite) => {
    if (satellite) {
      const key = "dnSalYNT9GcjMQFNzx4G";
      const attributions =
        '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
        '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
      const aerialLayer = new TileLayer({
        source: new XYZ({
          attributions: attributions,
          url:
            "https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=" +
            key,
          maxZoom: 20,
        }),
        visible: false,
      });
      setSatelliteViewMap(aerialLayer);
      return aerialLayer;
    } else {
      const baseLayer = new TileLayer({
        source: new OSM(),
      });
      return baseLayer;
    }
  };

  //Change visibility
  useEffect(() => {
    if (mapRef.current !== null && layers?.length > 0) {
      layerState?.map((item, idx) => {
        if (layers[idx]?.visibility === 1) {
          item.setVisible(true);
        } else {
          item.setVisible(false);
        }
      });
    }
  }, [layers]);

  //Change Map style
  useEffect(() => {
    if (
      satelliteViewMap &&
      props?.accessory === "Satellite" &&
      mapRef?.current !== null
    ) {
      satelliteViewMap.setVisible(!props.satelliteView);
    }

    if (
      props?.accessory === "Draw" &&
      mapRef?.current !== null &&
      props?.isSelect
    ) {
      let arr = [];
      const selectInteraction = new Select({
        condition: click,
      });
      const setEdit = (data) => {
        let mapObj = props?.mapData?.smap?.find((e) => e?.FeatureName === data);
        setEditDraw({
          FeatureName: data,
          colorPicker: mapObj?.Color,
          Description: mapObj?.Description,
          updateId: mapObj?.id,
          surveyId: props?.mapData?.surveyData?.id,
        });
      };

      const geoData = (geometry) => {
        let geo = JSON.parse(new GeoJSON().writeGeometry(geometry));
        let output = formatArea(geometry);
        let area = output.split("<sup>")[0];
        let farea = area.replace("m", "m²");
        const drawPerimeter = formatLength(geometry, {
          projection: map.getView().getProjection(),
          lengthUnit: "meters", // You can change this unit if needed
          spherical: true,
        });
        setEditDraw((prev) => ({
          ...prev,
          Coordinates: geo,
          area: farea,
          perimeter: drawPerimeter,
        }));
      };

      const modifyInteraction = new Modify({
        features: selectInteraction.getFeatures(),
      });

      map.addInteraction(selectInteraction);
      map.addInteraction(modifyInteraction);

      selectInteraction.on("select", (event) => {
        const selectedFeatures = event.target.getFeatures();
        selectedFeatures.forEach((feature) => {
          if (feature.values_.name) setEdit(feature.values_.name);
        });
        if (selectedFeatures.getLength() > 0) {
          // Features are selected
          // Perform your logic here
        } else {
        }
        if (event.type === "select") {
          let d = event.mapBrowserEvent.pixel_;
          if (arr.length > 0) {
            arr.map((ele) => {
              if (ele[0] == d[0] && ele[1] == d[1]) {
                setDrawModal(true);
              }
            });
          }
          arr.push(event.mapBrowserEvent.pixel_);
        }
      });
      modifyInteraction.on("modifyend", (event) => {
        const modifiedFeatures = event.features.getArray();
        modifiedFeatures.forEach((feature) => {
          const modifiedGeometry = feature.getGeometry();
          geoData(modifiedGeometry);
        });
      });

      modifyInteraction.on("propertychange", (event) => {
        const selectedFeatures = event.target.getFeatures();
        selectedFeatures.forEach((feature) => {});
      });
      return () => {
        map.removeInteraction(selectInteraction);
        map.removeInteraction(modifyInteraction);
      };
    }

    if (
      props?.accessory === "Identifier" &&
      mapRef?.current !== null &&
      layerState &&
      sourceState
    ) {
      const handleIdentifier = (evt) => {
        const clickedFeatures = [];
        map.forEachFeatureAtPixel(
          evt.pixel,
          (feature, layer) => {
            if (layer instanceof ImageLayer) {
              // The clicked feature belongs to the GeoServer layer (ImageWMS)
              const id = feature.get("id"); // Replace 'id' with your actual identifier property
              clickedFeatures.push({ feature, layerType: "GeoServer", id });
            } else if (layer instanceof VectorLayer) {
              // The clicked feature belongs to the drawn vector layer
              const id = feature.get("id"); // Replace 'id' with your actual identifier property
              clickedFeatures.push({ feature, layerType: "DrawnVector", id });
            }
          },
          {
            layerFilter: (layer) => {
              // Include only ImageLayer and VectorLayer instances
              return (
                layer instanceof ImageLayer || layer instanceof VectorLayer
              );
            },
          }
        );
        const allLayers = map.getLayers();
        const testlayers = allLayers
          .getArray()
          .filter((layer) => layer.getVisible());

        // try {
        const visibleLayers = layerState.filter(
          (layer) => layer.getVisible() && layer instanceof Image
        );
        visibleLayers.sort((a, b) => {
          return a.getZIndex() - b.getZIndex();
        });
        let sourceArray = [];
        visibleLayers.map((layer) => {
          if (
            layer.getSource().getParams()?.FORMAT &&
            layer.getSource().getParams()?.FORMAT.includes("image") &&
            !layer.getSource().getParams()?.LAYERS.includes("Ortho")
          ) {
            sourceArray.push(layer.getSource());
          }
        });
        if (sourceArray.length == 0) {
          visibleLayers.map((layer) => {
            sourceArray.push(layer.getSource());
          });
        }
        let latestArray = sourceState.filter(
          (Source) =>
            Source?.params_.LAYERS.trim() ==
            sourceArray[sourceArray.length - 1]?.params_.LAYERS.trim()
        );

        evt.stopPropagation();
        deleteElevation(".elev-tooltip");
        if (clickedFeatures.length === 0) {
          let coordinates = evt.coordinate_;
          let view = map.getView();
          const viewResolution = map?.getView()?.getResolution();
          let layerParams = latestArray[0]?.getParams()?.LAYERS || "";
          let layerName = layerParams?.split(":")[1]; // Assuming the layer name is the second part after the colon

          //   setLayerDisplayName(layerName);
          const url = latestArray[0]?.getFeatureInfoUrl(
            evt.coordinate,
            viewResolution,
            view.getProjection(),
            { INFO_FORMAT: "application/json" }
          );
          let identifierData = {};
          if (url) {
            fetch(url, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
              },
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.features && data.features[0]) {
                  identifierData.id = data.features[0]?.id;
                  identifierData.properties = data.features[0]?.properties;
                }
                identifierData.coordinates = [
                  evt.coordinate[0].toFixed(4),
                  evt.coordinate[1].toFixed(4),
                  layerParams.split(":")[1],
                ];
                const overlayElement = document.getElementById("popup");
                const overlay = new Overlay({
                  element: overlayElement,
                  positioning: "bottom-center",
                  stopEvent: false,
                  offset: [0, -10],
                });
                map.addOverlay(overlay);
                overlay.setPosition(evt.coordinate);
                setIdentifierData(identifierData);
                setOVerlayData(overlay);
                setPopover(true);
              })
              .catch((error) => console.log(error));
          }
        } else if (clickedFeatures.length > 0) {
          const properties = clickedFeatures.map(
            ({ feature, layerType, id }) => {
              return {
                layerType,
                id,
                properties: feature.getProperties(),
                geometry: feature.getGeometry(),
              };
            }
          );
          let geom = properties[0].geometry;
          let bufferedProperties = properties[0].properties;
          if (geom instanceof Polygon) {
            if (bufferedProperties.isBufferedLayer === false) {
              identifierData.properties = {
                Area: formatArea(geom).split(" ")[0] + " sq.kms",
              };
            } else {
              identifierData.properties = {
                Area: formatArea(geom).split(" ")[0] + " sq.kms",
                Buffered_Offset: bufferedProperties.bufferDistance + "mtrs",
              };
            }
          }
          if (geom instanceof LineString) {
            if (bufferedProperties.isBufferedLayer === false) {
              identifierData.properties = { Length: formatLength(geom) };
            } else {
              identifierData.properties = {
                Length: formatLength(geom),
                Buffered_Offset: bufferedProperties.bufferDistance + "mts",
              };
            }
          }
          if (geom instanceof Point) {
            if (bufferedProperties.isBufferedLayer === false) {
              identifierData.properties = {};
            } else {
              identifierData.properties = {
                Buffered_Offset: bufferedProperties.bufferDistance + "mts",
              };
            }
          }
          let drawnLayerName = properties[0].properties.name;
          identifierData.coordinates = [
            evt.coordinate[0].toFixed(4),
            evt.coordinate[1].toFixed(4),
            drawnLayerName,
          ];
          const overlayElement = document.getElementById("popup");
          const overlay = new Overlay({
            element: overlayElement,
            positioning: "bottom-center",
            stopEvent: false,
            offset: [0, -10],
          });
          map.addOverlay(overlay);
          overlay.setPosition(evt.coordinate);
          setOVerlayData(overlay);
          setIdentifierData(identifierData);
          setPopover(true);
        }
      };
      map.on("singleclick", handleIdentifier);
      return () => {
        map.un("singleclick", handleIdentifier);
      };
    }

    if (props?.accessory === "Export" && mapRef?.current !== null) {
      const drawnVectorLayers = layerState?.filter(
        (layer) => layer instanceof VectorLayer
      );
      const combinedLayers = [...layerInfo, ...drawnVectorLayers];
      setExportLayer(combinedLayers);
      const filteredVectorLayers = layers?.filter(
        (ele) => ele?.vector === true && ele.visibility === 1
      );
      const filteredCombinedLayers = [...layerInfo, ...filteredVectorLayers];
      setFilteredLayer(filteredCombinedLayers);
      setIsOpen(true);
    }

    if (props?.accessory === "Rotate" && mapRef?.current !== null) {
      const rotationLabel = document.createElement("div");
      rotationLabel.classList.add("custom-rotate-controls");
      const rotationImage = document.createElement("img");
      rotationImage.src = rotateImage;
      rotationLabel.appendChild(rotationImage);
      document.getElementsByClassName("ol-compass")[0].innerHTML = "";
      let rotateControl = new Rotate({
        autoHide: false, // Keep the control visible at all times
        duration: 250, // Animation duration in milliseconds
        label: rotationLabel, // Icon label (can be customized)
        tipLabel: "Reset map", // Tooltip label
        resetNorth: () => {
          // Reset the map rotation to its original position
          map.getView().animate({
            rotation: 0,
            duration: 250,
          });
        },
      });

      // Add the rotation control to the map
      map.addControl(rotateControl);

      return () => {
        map.removeControl(rotateControl);
      };
    }

    if (
      props.accessory === "Buffer" &&
      mapRef.current !== null &&
      props?.isSelect
    ) {
      const selectInteraction = new Select({
        condition: click,
      });
      map.addInteraction(selectInteraction);
      selectInteraction.on("select", (event) => {
        const selectedFeatures = event.target.getFeatures();
        selectedFeatures.forEach((feature) => {
          let geometry = feature?.getGeometry();
          let output = formatArea(geometry);
          let area = output.split("<sup>")[0];
          let farea = area.replace("m", "m²");
          const drawPerimeter = formatLength(geometry, {
            projection: map.getView().getProjection(),
            lengthUnit: "meters", // You can change this unit if needed
            spherical: true,
          });
          setBuffData({ area: farea, perimeter: drawPerimeter });
          Unselect(feature.getGeometry().getType());
          setBufData(feature.getGeometry().getCoordinates());
        });
        setBufferModal(true);
      });
      return () => {
        // Remove the select interaction and event listener when the component unmounts or when `isSelectEnabled` changes
        map.removeInteraction(selectInteraction);
      };
    } else {
      Unselect("None");
    }
  }, [props?.accessory, props?.isSelect]);

  const deleteElevation = (data) => {
    const boxes = document.querySelectorAll(data);
    if (boxes.length > 0) {
      boxes.forEach((box) => {
        box.remove();
      });
    }
  };

  //when tools changes
  useEffect(() => {
    if (props?.tool != "none") {
      const boxes = document.querySelectorAll(".elev-tooltip");
      boxes?.forEach((box) => {
        box?.remove();
      });
    }

    if (toolState) {
      map?.removeInteraction(toolState);
    }

    if (props?.tool !== "none" && mapRef.current !== null) {
      const source = new VectorSource();
      const vector = new VectorLayer({
        source: source,
        style: [
          new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "#FF0000",
              width: 2,
              lineDash: [5, 5],
            }),
            image: new Circle({
              radius: 7,
              fill: new Fill({
                color: "#ffcc33",
              }),
            }),
          }),
        ],
      });
      map?.addLayer(vector);
      addInteractions(map, source);
    }
  }, [props?.tool]);

  //this function adds drawn data to map with measurements and modify
  function addInteractions(map, source) {
    let measureTooltip, sketch, listener, varea;

    let element = document.getElementsByClassName("ol-viewport");
    let measureTooltipElement = document.createElement("div");

    while (element.length > 1) {
      element[0].parentNode?.removeChild(element[0]);
    }

    let draw = new Draw({
      source: source,
      type: props?.tool,
    });

    setToolState(draw);
    map?.addInteraction(draw);

    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
      stopEvent: false,
      insertFirst: false,
    });
    map?.addOverlay(measureTooltip);

    draw.on("drawstart", (event) => {
      sketch = event.feature;
      let tooltipCord = event.coordinate;
      listener = sketch.getGeometry().on("change", function (evt) {
        source.clear();
        const geom = evt.target;
        let output;
        measureTooltipElement.id = "measure";
        if (geom instanceof Polygon) {
          output = formatArea(geom);
          tooltipCord = geom.getInteriorPoint().getCoordinates();
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCord);
          props?.accessory === "Measure"
            ? (measureTooltipElement.style.display = "block")
            : (measureTooltipElement.style.display = "none");
          let area = output.split("<sup>")[0];
          let farea = area.replace("m", "m²");
          varea = farea;
        } else if (geom instanceof LineString) {
          setProfileModal(false);
          let profileLineLength = formatLengthInMeters(geom);
          let splitLineOutput = parseFloat(
            profileLineLength.substring(0, profileLineLength.indexOf(" "))
          );
          setProfileLength(splitLineOutput);
          output = formatLength(geom);
          tooltipCord = geom.getLastCoordinate();
          measureTooltipElement.innerHTML = output;
          props?.setMeasurement(output);
          measureTooltip.setPosition(tooltipCord);
          props?.accessory === "Measure" && props?.tool !== "none"
            ? (measureTooltipElement.style.display = "block")
            : (measureTooltipElement.style.display = "none");
        }
      });
    });

    draw.on("drawend", function (evt) {
      measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      measureTooltip.setOffset([0, -7]);
      sketch = null;
      const feature = evt.feature;
      const geometry = feature.getGeometry();
      unByKey(listener);

      const drawPerimeter = formatLength(geometry, {
        projection: map.getView().getProjection(),
        lengthUnit: "meters", // You can change this unit if needed
        spherical: true,
      });

      if (props?.accessory === "Draw") {
        const geoJsonFormat = new GeoJSON();
        const geoJsonData = geoJsonFormat.writeGeometry(geometry);
        setDrawData({
          geojsonData: geoJsonData,
          surveyid: props?.mapData?.surveyData?.id,
          area: varea,
          perimeter: drawPerimeter,
        });
        setDrawModal(true);
      } else if (props?.accessory === "Buffer") {
        setBufData(geometry.getCoordinates());
        setBuffData({ area: varea, perimeter: drawPerimeter });
        setBufferModal(true);
      } else if (props?.accessory === "Profile View") {
        setBufData(geometry.getCoordinates());
        setIsProfile(true);
      } else if (props?.accessory === "Volumetric") {
        const geoJsonFormat = new GeoJSON();
        let epsg = geoJsonFormat?.dataProjection?.code_;
        const geoJsonData = geoJsonFormat.writeGeometry(geometry);
        let coord = JSON.parse(geoJsonData);
        let convertedPolygonCoordinates = coord.coordinates.map(function (
          ringCoords
        ) {
          return ringCoords.map(function (coord) {
            return transform(coord, "EPSG:3857", "EPSG:4326");
          });
        });
        setVolData({
          geojsonData: coord,
          coordinates: convertedPolygonCoordinates,
          surveyid: props?.mapData?.surveyData?.id,
          geometry: geometry,
          epsg: epsg,
          layers: props?.mapData?.rData,
          perimeter: drawPerimeter,
          area: varea,
          projectName: props?.mapData?.projectData?.projectName?.replaceAll(
            " ",
            "-"
          ),
          surveyName: props?.mapData?.surveyData?.surveyName?.replaceAll(
            " ",
            "-"
          ),
        });
        setVol(true);
      }
    });
  }

  //draw
  const closeDrawModal = () => {
    props?.setAccessory("none");
    props?.setTool("none");
    setDrawModal(false);
    let layers = map.getLayers().getArray();
    if (layers?.length > 0 && !props?.isSelect) {
      var lastLayer = layers[layers?.length - 1];
      map.removeLayer(lastLayer);
    } else {
      props.addDynamicLayer();
    }
  };

  const handleAddDraw = (val) => {
    setVectorDrawData(val);
    props?.setAccessory("none");
    props?.setTool("none");
  };

  useEffect(() => {
    if (vectorDrawData && mapRef?.current !== null && layerState) {
      closeDrawModal();
      props.addDynamicLayer();
    }
  }, [vectorDrawData]);

  //export
  const closeModal = () => {
    setIsOpen(false);
    props.setAccessory("none");
    setExportLayer(null);
    setFilteredLayer(null);
  };

  //profile view
  useEffect(() => {
    if (sourceState && layerState && isProfile) {
      let globalMercatorCoords = [];
      BufData.map((val) => {
        globalMercatorCoords.push(transform(val, "EPSG:3857", "EPSG:4326"));
      });
      const globalMercatorCoordsObj = {
        coordinates: globalMercatorCoords,
      };
      let view = map.getView();
      const viewResolution = map.getView().getResolution();

      const visibleLayers = layerState?.filter(
        (layer) => layer.getVisible() && layer instanceof Image
      );
      visibleLayers.sort((a, b) => {
        return a.getZIndex() - b.getZIndex();
      });

      let sourceArray = [];
      visibleLayers.map((layer) => {
        if (
          layer.getSource().getParams()?.FORMAT &&
          layer.getSource().getParams()?.FORMAT.includes("image") &&
          !layer.getSource().getParams()?.LAYERS.includes("Ortho")
        ) {
          sourceArray.push(layer.getSource());
        }
      });
      if (sourceArray.length == 0) {
        visibleLayers.map((layer) => {
          sourceArray.push(layer.getSource());
        });
      }

      let latestArray = sourceState.filter(
        (Source) =>
          Source?.params_.LAYERS.trim() ==
          sourceArray[sourceArray.length - 1]?.params_.LAYERS.trim()
      );

      let obj = {
        view: view,
        viewResolution: viewResolution,
        projection: view.getProjection(),
        source: latestArray[0],
      };

      //Todo Condition to check the feature type is LineString, as it should allow only line string
      ProfileViewCalculator(globalMercatorCoordsObj, obj);
      setIsProfile(false);
    }
  }, [isProfile]);

  const ProfileViewCalculator = async (dataObj, profileService) => {
    props?.setLoading(true);
    const answer = document.getElementById("calculated-area");
    let data = dataObj;
    let coordinates = [];
    data.coordinates.map((obj) => {
      // [obj[0], obj[1]] = [obj[1], obj[0]];
      coordinates.push(obj);
    });

    if (data.coordinates.length > 0) {
      let obj = {
        coordinates: coordinates,
        projectID: props?.mapData?.projectData?.id?.toString(),
        surveyID: props?.mapData?.surveyData?.id?.toString(),
        isSlope: false,
        elevationData: profileService,
        lineLength: profileLength,
      };

      await api
        .post("slope", obj)
        .then((resp) => {
          if (resp.pathprofile) {
            setPathDistance(resp.pathprofile.distance);
            setProfileResponse(resp.pathprofile.elevationData);
            setProfileCoords(resp.pathprofile.coordinates);

            setBasicData((prevBasicData) => ({
              ...prevBasicData,
              labels: resp.pathprofile.distance,
              datasets: [
                {
                  label: "Elevation",
                  data: resp.pathprofile.elevationData, // Use elevation data
                  borderColor: "blue",
                  fill: true,
                  pointRadius: 0.3,
                },
              ],
            }));
          }
        })

        .catch((err) => {
          setBasicData({
            ...basicData,
            labels: [],
            datasets: [
              {
                label: "Elevations",
                data: [],
                fill: true,
                tension: 0.4,
                borderColor: "#42A5F5",
              },
            ],
          });
        });
    } else {
      //   // answer.innerHTML = "";
      //   // if (type !== "draw.delete") alert("Click the map to draw a polygon.");
    }
    setShowGraph(true);
    setProfileModal(true);
    props?.setLoading(false);
  };

  let elevationData = profileResponse;
  const distanceData = pathDistance;
  const coordinatesData = profileCoords;
  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const dataIndex = context.dataIndex;
              if (
                elevationData[dataIndex] !== undefined &&
                distanceData[dataIndex] !== undefined &&
                coordinatesData[dataIndex] !== undefined
              ) {
                const elevationLabel = `Elevation: ${elevationData[dataIndex]}m`;
                const distanceLabel = `Distance: ${distanceData[dataIndex]}m`;
                const coordinatesLabel = `Coordinates: ${coordinatesData[dataIndex][0]}, ${coordinatesData[dataIndex][1]}`;
                return [elevationLabel, distanceLabel, coordinatesLabel];
              }
              return "";
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
          display: true,
          title: {
            display: true,
            text: "Distance(in meters)",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },

          display: true,
          title: {
            display: true,
            text: "Elevation(in meters)",
          },
        },
      },
    };
    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  const closeprofile = () => {
    setShowGraph(false);
    setIsProfile(false);
    let layers = map.getLayers().getArray();
    if (layers?.length > 0 && !props?.isSelect) {
      var lastLayer = layers[layers?.length - 1];
      map.removeLayer(lastLayer);
    }
  };

  //buffer
  const closeBuffer = () => {
    setBufferModal(false);
    let layers = map.getLayers().getArray();
    if (layers?.length > 0 && !props?.isSelect) {
      var lastLayer = layers[layers?.length - 1];
      map.removeLayer(lastLayer);
    } else {
      props.addDynamicLayer();
    }
  };

  const handleBuffer = async (data) => {
    setFormBufferData((prev) => ({ ...prev, data }));
    setActiveBuffer(data);
  };

  useEffect(() => {
    if (select == "Polygon" || props?.tool == "Polygon") {
      PolygonbufferCalculator(activeBuffer);
    }
    if (select == "LineString" || props?.tool == "LineString") {
      LinebufferCalculator(activeBuffer);
    }
    if (select == "Point" || props?.tool == "Point") {
      PointbufferCalculator(activeBuffer);
    }
  }, [activeBuffer]);

  const PolygonbufferCalculator = (data) => {
    const bufferDistance = Number(data?.offsetValue); // Buffer distance in kilometers
    let convertedCoord = BufData.map(function (coords) {
      return coords.map(function (coord) {
        return transform(coord, "EPSG:3857", "EPSG:4326");
      });
    });
    /*Omit this line and use polygon method */
    //var turfPolygon = bboxPolygon(extent);
    var turfPolygon = polygon(convertedCoord);

    var bufferedPolygon = buffer(turfPolygon, bufferDistance, {
      units: "meters",
    });

    // Convert the projection from EPSG:4326 to EPSG:3857
    var convertedCoordinates = [];

    // Extract the coordinates of the buffered polygon
    var coordinates = getCoords(bufferedPolygon);

    // Convert the projection from EPSG:4326 to EPSG:3857 for each coordinate
    var convertedCoordinates = coordinates.map(function (coords) {
      return coords.map(function (coord) {
        return transform(coord, "EPSG:4326", "EPSG:3857");
      });
    });

    var olPolygon = new Polygon(convertedCoordinates);

    var feature = new Feature(olPolygon);
    let featureGeometry = feature.getGeometry();
    let gejsonFormat = new GeoJSON();
    let geoData = gejsonFormat.writeGeometry(featureGeometry);
    setBufferData({
      geoData: geoData,
      surveyid: props?.mapData?.surveyData?.id,
    });
    var bufferSource = new VectorSource({
      features: [feature],
    });

    var bufferLayer = new VectorLayer({
      source: bufferSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 0, 0, 0.3)",
        }),
        stroke: new Stroke({
          color: "red",
          width: 2,
        }),
      }),
    });

    if (map) map.addLayer(bufferLayer);
  };

  const LinebufferCalculator = (data) => {
    let bufferDistance = Number(data?.offsetValue);
    if (data?.direction == "Left") {
      bufferDistance = -bufferDistance;
    }

    let convertedCoord = BufData?.map(function (coords) {
      return transform(coords, "EPSG:3857", "EPSG:4326");
    });
    const [startLon, startLat] = fromLonLat(convertedCoord[0]);
    const [endLon, endLat] = fromLonLat(convertedCoord[1]);

    const isVertical =
      Math.abs(startLat - endLat) > Math.abs(startLon - endLon);
    let angle = 0;
    if (isVertical) {
      angle = 90;
    }
    var turfLine = lineString(convertedCoord);

    if (!turfLine) {
      return;
    }
    var bufferedLine = transformTranslate(turfLine, bufferDistance, angle, {
      units: "meters",
    });
    var convertedCoordinates = [];
    var coordinates = getCoords(bufferedLine);
    var convertedCoordinates = coordinates.map(function (coords) {
      return transform(coords, "EPSG:4326", "EPSG:3857");
    });
    var olPolygon = new LineString(convertedCoordinates);
    var feature = new Feature(olPolygon);
    let featureGeometry = feature.getGeometry();
    let gejsonFormat = new GeoJSON();
    let geoData = gejsonFormat.writeGeometry(featureGeometry);
    setBufferData({
      geoData: geoData,
      surveyid: props?.mapData?.surveyData?.id,
    });

    var bufferSource = new VectorSource({
      features: [feature],
    });

    var bufferLayer = new VectorLayer({
      source: bufferSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 0, 0, 0.3)",
        }),
        stroke: new Stroke({
          color: "red",
          width: 10,
        }),
      }),
    });

    if (map) map.addLayer(bufferLayer);
  };

  const PointbufferCalculator = (data) => {
    const bufferDistance = Number(data?.offsetValue); // Buffer distance in kilometers
    let convertedCoord = transform(BufData, "EPSG:3857", "EPSG:4326");
    var turfPoint = point(convertedCoord);
    if (!turfPoint) {
      return;
    }
    var bufferedPoint = buffer(turfPoint, bufferDistance, {
      units: "meters",
      steps: 64,
    });

    // Convert the projection from EPSG:4326 to EPSG:3857
    var convertedCoordinates = [];

    // Extract the coordinates of the buffered polygon
    var coordinates = getCoords(bufferedPoint);

    // Convert the projection from EPSG:4326 to EPSG:3857 for each coordinate
    var convertedCoordinates = coordinates.map(function (coords) {
      return coords.map(function (coord) {
        return transform(coord, "EPSG:4326", "EPSG:3857");
      });
    });
    var olPolygon = new Polygon(convertedCoordinates);
    var feature = new Feature(olPolygon);
    let featureGeometry = feature.getGeometry();
    let gejsonFormat = new GeoJSON();
    let geoData = gejsonFormat.writeGeometry(featureGeometry);
    setBufferData({
      geoData: geoData,
      surveyid: props?.mapData?.surveyData?.id,
    });
    var bufferSource = new VectorSource({
      features: [feature],
    });

    var bufferLayer = new VectorLayer({
      source: bufferSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 0, 0, 0.3)",
        }),
        stroke: new Stroke({
          color: "red",
          width: 2,
        }),
      }),
    });

    if (map) map.addLayer(bufferLayer);
  };

  useEffect(() => {
    if (formBufferData && bufferData) {
      let body = {
        FeatureName: formBufferData.data.FeatureName,
        Color: formBufferData.data.colorPicker,
        Description: formBufferData.data.Description,
        bufferDistance: parseInt(formBufferData.data.offsetValue),
        Coordinates: JSON.parse(bufferData.geoData),
        surveyId: bufferData.surveyid,
        area: buffData?.area || "",
        perimeter: buffData?.perimeter || "",
      };
      const bufferApi = async () => {
        let response = await addDrawData(body);
        if (response?.status?.toLowerCase() == "success") {
          toast.success("Feature Added successfully");
          let data = vectorData([response?.response]);
          data[1] = response?.response;
          setVectorDrawData(data);
          props.setAccessory("none");
          props?.setTool("none");
        }
      };
      bufferApi();
    }
  }, [bufferData]);

  const closeVol = () => {
    setVol(false);
    let layers = map.getLayers().getArray();
    if (layers?.length > 0 && !props?.isSelect) {
      var lastLayer = layers[layers?.length - 1];
      map.removeLayer(lastLayer);
      props.setTool("none");
    } else {
      props.addDynamicLayer();
    }
  };

  const handleVolumetric = (val) => {
    // console.log(val);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div ref={mapElement} className="map-container"></div>
      {drawModal && (
        <DrawModal
          show={drawModal}
          closeModal={closeDrawModal}
          modalheader="Drawing Tool"
          drawData={drawData}
          handleAddDraw={handleAddDraw}
          editDraw={editDraw}
        />
      )}

      {popover && identifierData && (
        <IdentifierPopup
          OverlayData={OverlayData}
          identifierData={identifierData}
          setPopover={setPopover}
        />
      )}

      {props.accessory === "Export" && (
        <ExportModal
          show={isOpen}
          closeModal={closeModal}
          exportLayer={exportLayer}
          filteredLayer={filteredLayer}
          modalheader="Export Tool"
        />
      )}

      {props?.accessory === "Profile View" && showGraph && (
        <div className={"calculation-box" + (showGraph ? " w-600" : "")}>
          {showGraph && profileModal ? (
            <div id="profileview-class">
              <div className="d-flex justify-content-end">
                <i
                  className="pi pi-times pointer me-3"
                  onClick={() => {
                    closeprofile();
                  }}
                />
              </div>
              <div className="graphadjust">
                <Chart type="line" data={basicData} options={basicOptions} />
              </div>
            </div>
          ) : (
            <div id="profileview-class">
              <p>Use drawing tools to measure distances and areas</p>
              <div id="calculated-area"></div>
            </div>
          )}
        </div>
      )}
      {props.accessory === "Rotate" && (
        <div>
          <div className="rotate-tooltip">
            Please use <b>ALT + SHIFT + DRAG</b> to rotate the map
          </div>
          <span className="pop-v"></span>
        </div>
      )}

      {bufferModal && (
        <BufferModal
          show={bufferModal}
          toggle={() => {
            closeBuffer();
          }}
          modalheader="Buffer Tool"
          handleBuffer={handleBuffer}
          data={select}
          tool={props?.tool}
        />
      )}

      {vol && (
        <VolumetricModal
          show={vol}
          closeModal={closeVol}
          modalheader="Dynamic (Volumetric Measurement)"
          handleVolumetric={handleVolumetric}
          data={volData}
          setVol={setVol}
          setLoading={props?.setLoading}
          handleAddDraw={handleAddDraw}
          setTool={props?.setTool}
          setAccessory={props?.setAccessory}
          closeDynamics={props?.closeDynamics}
          raw={props?.mapData?.raw}
        />
      )}
    </React.Fragment>
  );
};

export default OpenLayerMap;
