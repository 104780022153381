import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import BreadCrumb from "../../../Pages/Components/NewBreadCrumb";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import filexls from "../../../assets/images/xlicon.png";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import * as api from "../../../api/global.api";
import Loader from "../../Common/Loader";
import { useSelector } from "react-redux";
import axios from "axios";

const UploadExcelFile = () => {
  const loc = useLocation()?.state;
  const details = useSelector((state) => state.client.clientDetails);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [sheet, setSheet] = useState("");
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAcceptedFiles = (files) => {
    if (files?.length > 1 || files[0]?.name?.split(".")?.pop() !== "xlsx") {
      toast.error("Please upload only excel file");
    } else {
      setFile(files[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const row = jsonData[0];
        setSheet(row[" Execution Status"]);
        let arr = jsonData?.slice(2);
        setData(arr);
      };

      reader.readAsBinaryString(files[0]);
    }
  };

  let clms = [
    {
      name: "Description",
      selector: (row) => {
        return (
          <div style={{ fontWeight: row["__EMPTY"] ? "" : "600" }}>
            {row[" Execution Status"] || row["Execution Status"]}
          </div>
        );
      },
      width: "400px",
    },
    {
      name: "Is Mandatory?",
      selector: (row) => {
        return <>{row["__EMPTY"]}</>;
      },
      width: "150px",
    },
    {
      name: "UOM",
      selector: (row) => {
        return <>{row["__EMPTY_1"]}</>;
      },
      width: "100px",
    },
    {
      name: "Weightage",
      selector: (row) => {
        return (
          <div style={{ fontWeight: row["__EMPTY"] ? "" : "600" }}>
            <>
              {row["__EMPTY_2"] !== undefined && (
                <>{(row["__EMPTY_2"] * 100).toFixed(2)} %</>
              )}
            </>
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Total Qty",
      selector: (row) => {
        return (
          <>{row["__EMPTY_3"] !== undefined && Math.round(row["__EMPTY_3"])}</>
        );
      },
      width: "150px",
    },
    {
      name: "Completed",
      selector: (row) => {
        return <>{row["__EMPTY_4"]}</>;
      },
      width: "150px",
    },
    {
      name: "Balance",
      selector: (row) => {
        return (
          <>{row["__EMPTY_5"] !== undefined && Math.round(row["__EMPTY_5"])}</>
        );
      },
    },
    {
      name: "Start Date",
      selector: (row) => {
        return <>{row["__EMPTY_6"]}</>;
      },
      width: "150px",
    },
    {
      name: "End Date/ Target Date",
      selector: (row) => {
        return <>{row["__EMPTY_7"]}</>;
        __EMPTY;
      },
      width: "250px",
    },
    {
      name: "Overall %",
      selector: (row) => {
        return (
          <div style={{ fontWeight: row["__EMPTY"] ? "" : "600" }}>
            {row["__EMPTY_8"] !== undefined ? (
              <>{(row["__EMPTY_8"] * 100).toFixed(2)} % </>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      name: "Completion %",
      selector: (row) => {
        return (
          <div style={{ fontWeight: row["__EMPTY"] ? "" : "600" }}>
            {row["__EMPTY_9"] !== undefined ? (
              <>{(row["__EMPTY_9"] * 100).toFixed(2)} %</>
            ) : (
              <></>
            )}
          </div>
        );
      },
      width: "200px",
    },
  ];

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("projectName", loc?.projectName);
    formData.append("projectId", loc?.id);
    formData.append("clientId", loc?.clientId);
    formData.append("clientName", details?.clientName);
    formData.append("module", "Survey");
    api
      .post("uploadProject", formData)
      .then((res) => {
        if (res?.status === "Success") {
          toast.success(res?.message);
          navigate(`/delivers/${loc?.clientId}`, { state: loc });
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   if (!!loc?.formUrl) {
  //     setFile({ name: loc?.formUrl?.split("/").pop() });
  //     api.post("pathbase64", { filePath: loc?.formUrl }).then((res) => {
  //       if (res?.status === "Success") {
  //         let resfile = res.response?.split(",").pop()
  //         const binaryString = window.atob(resfile);
  //         const binaryLen = binaryString.length;
  //         const bytes = new Uint8Array(binaryLen);
  //         for (let i = 0; i < binaryLen; i++) {
  //           bytes[i] = binaryString.charCodeAt(i);
  //         }
  //         const blob = new Blob([bytes], {
  //           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //         });
  //         const url = URL.createObjectURL(blob);
  //         setDownloadUrl(url);
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //           const workbook = XLSX.read(e.target.result, { type: "binary" });
  //           const sheetName = workbook.SheetNames[0];
  //           const worksheet = workbook.Sheets[sheetName];
  //           const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //           setSheet(data[1][0]);
  //           let arr = data.slice(3);
  //           let newarr = arr?.map((ele) => {
  //             return {
  //               " Execution Status": ele[0],
  //               __EMPTY: ele[1],
  //               __EMPTY_1: ele[2],
  //               __EMPTY_2: ele[3],
  //               __EMPTY_3: ele[4],
  //               __EMPTY_4: ele[5],
  //               __EMPTY_5: ele[6],
  //               __EMPTY_6: ele[7],
  //               __EMPTY_7: ele[8],
  //               __EMPTY_8: ele[9],
  //               __EMPTY_9: ele[10],
  //             };
  //           });
  //           setData(newarr);
  //         };
  //         reader.readAsBinaryString(blob);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (!!loc?.formUrl) {
      // Set the file name
      setFile({ name: loc?.formUrl.split("/").pop() });

      // Fetch the file directly from the URL
      axios
        .get(loc.formUrl, { responseType: "arraybuffer" })
        .then((response) => {
          // Create a Blob from the response data
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          setDownloadUrl(url);

          // Read the Blob as binary string
          const reader = new FileReader();
          reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Process the data
            setSheet(data[1][0]);
            const arr = data.slice(3);
            const newarr = arr.map((ele) => ({
              "Execution Status": ele[0],
              __EMPTY: ele[1],
              __EMPTY_1: ele[2],
              __EMPTY_2: ele[3],
              __EMPTY_3: ele[4],
              __EMPTY_4: ele[5],
              __EMPTY_5: ele[6],
              __EMPTY_6: ele[7],
              __EMPTY_7: ele[8],
              __EMPTY_8: ele[9],
              __EMPTY_9: ele[10],
            }));
            setData(newarr);
          };

          // Read the Blob
          reader.readAsBinaryString(blob);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    }
  }, [loc]);

  console.log(data)
  const handleDownload = () => {
    let fileName = loc?.formUrl?.split("/").pop();
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-4 uploadexcel">
      <ToastContainer />
      {loading && (
        <div className="loading">
          <Loader />
        </div>
      )}
      <BreadCrumb
        title={loc?.projectName}
        pageTitle="Projects"
        pageLink={`/delivers/${loc?.clientId}`}
      />
      <Card className="br-0 p-2" style={{ height: "79vh", overflowY: "auto" }}>
        <CardHeader className="bg-none fw-500 fs-16">Upload File</CardHeader>
        <CardBody className="p-3">
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleAcceptedFiles(acceptedFiles);
            }}
            // accept={fileType}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="mx-5">
                Upload
                <div
                  className="dropzone dz-clickable form-control p-0"
                  style={{ background: "#F7FCFF", height: "100px" }}
                >
                  {file === null ? (
                    <div className="needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        className="m-2 "
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="text-muted ri-upload-cloud-2-fill me-3 fs-2" />
                        <span className="text-muted">
                          Drag & Drop or Upload file
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="p-4">
                      <div className="pt-2  d-flex">
                        <img src={filexls} alt="" className="me-3" />
                        <div>{file?.name} </div>
                        {!loc?.formUrl && (
                          <div
                            className="cancelxlx"
                            onClick={() => {
                              setFile(null);
                              setData([]);
                            }}
                          >
                            <i className=" bx bx-x fs-2"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </CardBody>
        {data?.length > 0 && (
          <>
            <div className="text-center fw-500 bg-gray">Execution Status</div>
            <div className="text-center fw-500 bg-gray">{sheet}</div>
            <DataTable
              columns={clms}
              data={data}
              fixedHeader
              fixedHeaderScrollHeight="500px"
            />
          </>
        )}
        <CardFooter>
          <div className="d-flex justify-content-end">
            <Button
              color="success"
              className="me-3"
              outline
              onClick={() =>
                navigate(`/delivers/${loc?.clientId}`, { state: loc })
              }
            >
              Cancel
            </Button>
            {!loc?.formUrl && (
              <Button color="success" onClick={handleSubmit}>
                Submit
              </Button>
            )}
            {loc?.formUrl && (
              <Button color="success" onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default UploadExcelFile;
