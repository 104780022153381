export const Projects = {
  UsersList: [
    {
      header: "User ID",
      field: "userid",
      fieldType: "view",
      routerLink: "/projects/users/view",
      maxWidth: "250px",
      width: "250px",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by User ID",
    },
    {
      header: "First Name",
      field: "firstName",
      width: "250px",
      fieldType: "tooltip",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by First Name",
    },
    {
      header: "Role Name",
      field: "roleName",
      fieldType: "tooltip",
      width: "250px",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Role Name",
    },
    {
      header: "Designation",
      field: "designation",
      width: "250px",
      fieldType: "tooltip",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Designation",
    },
    {
      header: "Email ID",
      field: "emailid",
      width: "250px",
      fieldType: "tooltip",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Email ID",
    },
    {
      header: "Skill Set",
      field: "skillSet",
      width: "250px",
      fieldType: "tooltip",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Skill Set",
    },
    {
      header: "Contact Number",
      field: "contactNumber",
      width: "250px",
      filter: true,
      fieldType: "tooltip",
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Contact Number",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      width: "250px",
      filter: true,
      sortable: true,
      edit: true,
      delete: true,
      routerLink: "/projects/users/edit",
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Actions",
    },
  ],
  RolesList: [
    {
      header: "Role ID",
      field: "roleid",
      fieldType: "view",
      routerLink: "/projects/proles/view",
      width: "250px",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by User ID",
    },
    {
      header: "Role Name",
      field: "roleName",
      width: "250px",
      fieldType: "tooltip",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Role Name",
    },
    {
      header: "Privileges",
      field: "privileges",
      routerLink: "popup",
      fieldType: "popup",
      width: "250px",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Privileges",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      width: "250px",
      filter: true,
      sortable: true,
      edit: true,
      delete: true,
      routerLink: "/projects/proles/edit",
    },
  ],
  ProjectsList: [
    {
      header: "Project ID",
      field: "projectid",
      fieldType: "view",
      routerLink: "popup",
      maxWidth: "90px",
      width: "90px",
    },
    {
      header: "Project Name",
      field: "projectname",
      width: "250px",
      fieldType: "tooltip",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Project Name",
    },
    {
      header: "Description",
      field: "description",
      maxWidth: "180px",
      width: "180px",
      fieldType: "tooltip",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Description",
    },
    {
      header: "Location",
      field: "location",
      fieldType: "tooltip",
      maxWidth: "175px",
      width: "175px",
      filter: true,
      sortable: true,
      filterElement: "search",
      filterMatchMode: "contains",
      filterPlaceholder: "Search by Location",
    },
    {
      header: "Total Survey",
      field: "totalSurvey",
      maxWidth: "110px",
      width: "110px",
      fieldType: "tooltip",
    },
    {
      header: "Latest Survey",
      field: "latestSurvey",
      maxWidth: "125px",
      width: "125px",
      fieldType: "tooltip",
    },
    {
      header: "Category",
      field: "category",
      maxWidth: "125px",
      width: "125px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      maxWidth: "140px",
      width: "140px",
    },
  ],
  BaseMap: [
    {
      header: "Map Id",
      field: "mapid",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Map Type",
      field: "mapSource",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Map Style",
      field: "selectMap",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Select Map",
      field: "defaultMap",
      fieldType: "switch",
      maxWidth: "250px",
      width: "250px",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  newsource: [
    {
      header: "Source Id",
      field: "id",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Data Type",
      field: "dataType",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Source Name",
      field: "sourceName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Source Display Name",
      field: "sourceDisplayName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Source Link",
      field: "sourceLink",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      coridorDetails: true,
      routerLink: "popup",
    },
  ],
  Sources: [
    {
      header: "Source Id",
      field: "id",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Survey Id",
      fieldType: "tooltip",
      field: "surveyId",
      maxWidth: "150px",
      width: "150px",
    },
    {
      header: "Data Type",
      field: "dataType",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Source Name",
      field: "sourceName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Source Display Name",
      field: "sourceDisplayName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "File Size",
      field: "geoServerStorage",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Source Link",
      field: "sourceLink",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Properties: [
    {
      header: "Source Name",
      field: "selectName",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Attribute Name",
      field: "attributeName",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Display Name",
      field: "displayName",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Priority",
      field: "priority",
      width: "125px",
      maxWidth: "125px",
      fieldType: "tooltip",
    },
    {
      header: "Visibility",
      field: "visibility",
      fieldType: "switch",
      maxWidth: "100px",
      width: "100px",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Layers: [
    {
      header: "Layer Id",
      field: "layerid",
      maxWidth: "120px",
      width: "120px",
      fieldType: "tooltip",
    },
    {
      header: "Source Id",
      field: "sourceid",
      fieldType: "tooltip",
      maxWidth: "150px",
      width: "150px",
    },
    {
      header: "Vector Type",
      field: "vectortype",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Layer Name",
      field: "layername",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Display Name",
      field: "displayname",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Visibility",
      field: "visibility",
      fieldType: "switch",
      maxWidth: "150px",
      width: "150px",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Point: [
    {
      header: "Point Layer Id",
      field: "pointlayerid",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Layer Id",
      field: "layerid",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Point Color",
      field: "color",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Radius",
      field: "radius",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Line: [
    {
      header: "Line String Id",
      field: "linestringlayerid",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Layer Id",
      field: "layerid",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Line Width",
      field: "linewidth",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Line Color",
      field: "lincolor",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Attribute",
      field: "attribute",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Polygon: [
    {
      header: "Polygon Id",
      field: "polygonid",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Layer Id",
      field: "layerid",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Line Width",
      field: "linewidth",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Line Color",
      field: "linecolor",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Fill Color",
      field: "fillcolor",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Fill Color Opacity",
      field: "fillopacity",
      fieldType: "tooltip",
      maxWidth: "150px",
      width: "150px",
    },
    {
      header: "Area Attribute",
      field: "areaattribute",
      fieldType: "view",
      routerLink: "popup",
      width: "150px",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Symbol: [
    {
      header: "Symbol Id",
      field: "symbolid",
      maxWidth: "250px",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Layer Id",
      field: "layerid",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Color",
      field: "color",
      maxWidth: "250px",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Size",
      field: "size",
      maxWidth: "250px",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Videos: [
    {
      header: "Video Name",
      field: "fileName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Status",
      field: "fileStatus",
      width: "250px",
      fieldType: "tooltip",
    },
    // {
    //   header: "Actions",
    //   field: "actions",
    //   fieldType: "actions",
    //   maxWidth: "125px",
    //   width: "125px",
    //   edit: false,
    //   delete: true,
    //   routerLink: "popup",
    // },
  ],
  Reports: [
    {
      header: "Source",
      field: "source",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "File Name",
      field: "fileName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "File Type",
      field: "fileType",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Location",
      field: "location",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Downloads: [
    {
      header: "Source",
      field: "source",
      fieldType: "tooltip",
      maxWidth: "200px",
      width: "200px",
    },
    {
      header: "File Name",
      field: "fileName",
      fieldType: "tooltip",
      maxWidth: "250px",
      width: "250px",
    },
    {
      header: "File Type",
      field: "fileType",
      fieldType: "tooltip",
      maxWidth: "200px",
      width: "200px",
    },
    {
      header: "Location",
      field: "location",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
      //   filter: true,
      //   sortable: true,
      //   filterElement: "search",
      //   filterMatchMode: "contains",
      //   filterPlaceholder: "Search by Location",
    },
    {
      header: "Download Link",
      field: "source",
      width: "250px",
      fieldType: "tooltip",
      //   filter: true,
      //   sortable: true,
      //   filterElement: "search",
      //   filterMatchMode: "contains",
      //   filterPlaceholder: "Search by link",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  Markers: [
    {
      header: "ID",
      field: "markerid",
      maxWidth: "90px",
      width: "90px",
      fieldType: "tooltip",
    },
    {
      header: "Name",
      field: "name",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Latitude",
      field: "lat",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Longitude",
      field: "long",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Attribute 1",
      field: "attribute",
      fieldType: "tooltip",
      maxWidth: "200px",
      width: "200px",
    },
    {
      header: "Custom Info",
      fieldType: "tooltip",
      field: "custominfo",
      maxWidth: "200px",
      width: "200px",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      routerLink: "popup",
    },
  ],
  SurveysList: [
    {
      header: "Survey ID",
      field: "id",
      maxWidth: "120px",
      width: "120px",
      fieldType: "tooltip",
    },
    {
      header: "Survey Name",
      field: "surveyName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Latitude",
      field: "latitude",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Longitude",
      field: "longitude",
      maxWidth: "200px",
      width: "200px",
      fieldType: "tooltip",
    },
    {
      header: "Date of Survey",
      field: "dateOfSurvey",
      fieldType: "tooltip",
      maxWidth: "200px",
      width: "200px",
    },
    {
      header: "% Completion",
      field: "completion",
      fieldType: "tooltip",
      maxWidth: "150px",
      width: "150px",
    },
    {
      header: "Actions",
      field: "actions",
      maxWidth: "125px",
      width: "125px",
    },
  ],
  PilotList: [
    {
      header: "Location",
      field: "location",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Division",
      field: "division",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "KML FIle",
      field: "kmlfile",
      width: "250px",
      fieldType: "view",
      routerLink: "popup",
    },
    {
      header: "Schedule Start Date",
      field: "scheduleStartDate",
      width: "100px",
      maxWIdth: "100px",
      fieldType: "tooltip",
    },
    {
      header: "Schedule End Date",
      field: "scheduleEndDate",
      width: "100px",
      maxWIdth: "100px",
      fieldType: "tooltip",
    },
    {
      header: "Assign Pilot",
      field: "assignPilot",
      width: "250px",
      options: "pilots",
      optionLabel: "name_role",
      optionValue: "name_role",
      fieldType: "dropdown",
    },
    {
      header: "Status",
      field: "status",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Comments",
      field: "comment",
      width: "250px",
      fieldType: "tooltip",
    },
  ],
  AssignPilotView: [
    {
      header: "No. of Images",
      field: "numberOfimage",
      width: "80px",
      fieldType: "tooltip",
    },
    {
      header: "Uploaded Date",
      field: "uploadDate",
      width: "100px",
      fieldType: "date",
    },
    {
      header: "Uploaded File",
      field: "kmlfile",
      width: "100px",
      fieldType: "download",
    },
  ],
  REPORTS: [
    {
      header: "Deliverable Name",
      field: "deliverableName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "File Name",
      field: "fileName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Action",
      field: "action",
      width: "100px",
    },
  ],
  DOWNLOADS: [
    {
      header: "Deliverable Name",
      field: "deliverableName",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "File Type",
      field: "fileType",
      maxWidth: "250px",
      fieldType: "tooltip",
    },
    {
      header: "File Name",
      field: "fileName",
      maxWidth: "250px",
      fieldType: "tooltip",
    },
    {
      header: "File Status",
      field: "status",
      maxWidth: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "action",
      width: "250px",
    },
  ],
  Coridors: [
    {
      header: "Corridor Name",
      field: "coridorName",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Location",
      fieldType: "tooltip",
      field: "surveyId",
      maxWidth: "250px",
      width: "250px",
    },
    {
      header: "No.of Towers",
      field: "no.ofTowers",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "SME",
      field: "sme",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Created Date",
      field: "createdDate",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Status",
      field: "status",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      towers: true,
      routerLink: "popup",
    },
  ],
  Towers: [
    {
      header: "Line Name",
      field: "lineName",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },

    {
      header: "Tower No",
      field: "towerNo",
      maxWidth: "150px",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Location",
      fieldType: "tooltip",
      field: "surveyId",
      maxWidth: "250px",
      width: "250px",
    },

    {
      header: "Status",
      field: "status",
      width: "150px",
      fieldType: "tooltip",
    },
    {
      header: "Uploaded By",
      field: "uploadedBy",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Date of Inspection",
      field: "dateOfInspection",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Report Generated On",
      field: "reportGeneratedOn",
      width: "250px",
      fieldType: "tooltip",
    },
    {
      header: "Actions",
      field: "actions",
      fieldType: "actions",
      maxWidth: "125px",
      width: "125px",
      edit: true,
      delete: true,
      towers: true,
      routerLink: "popup",
    },
  ],
};
