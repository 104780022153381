import { useAppSelector } from "../../store/hooks";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Valid } from "../../store/reducer";
import { useNavigate } from "react-router-dom";

const SelectRoles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleOptions, setModuleOptions] = useState([]);

  const userRole = user?.roles?.filter(item => {
    if(item?.roleCode !== "SME" && item?.roleCode !== "PILOT"){
      return item
    }
  })
  
  const roleOptions = userRole.map((item) => {
    return { label: item?.roleName, value: item?.roleName };
  });

  const onSelcetRole = (e) => {
    setSelectedRole(e.value);
    setSelectedModule(null);
    let mod = user?.roles?.filter((item) => item?.roleName === e.value);
    let modules = mod[0]?.privilliges?.map((item) => {
      return { label: item?.subModule, value: item?.subModule };
    });
    setModuleOptions(modules);
  };

  const handleSubmit = () => {
    let mod = user?.roles?.filter((item) => item?.roleName === selectedRole);
    let priv = mod[0]?.privilliges?.filter(
      (item) => item?.subModule === selectedModule
    );
    let userObj = {
      ...user,
      selectedRole: selectedRole,
      selectedModule: selectedModule,
      privilliges: priv[0],
    };
    dispatch(Valid(userObj));
    if (selectedRole === "Admin") {
      navigate("/admin-dashboard");
    } else if (selectedRole === "Client Admin" ||selectedRole === "Client User") {
      navigate("/client-dashboard");
    } else if (selectedRole === "Sme") {
      navigate("/sme-dashboard");
    } else if (selectedRole === "Pilot") {
      navigate("/pilot-dashboard");
    }
  };

  const checkDisable = () => {
    if (selectedRole === "Admin") {
      return false;
    } else if ((selectedRole !== null) & (selectedModule !== null)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="selectroles">
        <Card title="Select Role">
          <div className="mb-3">
            <Dropdown
              value={selectedRole}
              onChange={(e) => onSelcetRole(e)}
              options={roleOptions}
              placeholder="Select a Role"
              className="w-full"
            />
          </div>
          {selectedRole !== null && selectedRole !== "Admin" && (
            <div className="mb-3">
              <Dropdown
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.value)}
                options={moduleOptions}
                placeholder="Select a Module"
                className="w-full"
              />
            </div>
          )}
          <div className="d-flex justify-content-end mb-3">
            <Button
              label="Submit"
              onClick={handleSubmit}
              disabled={checkDisable()}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default SelectRoles;
